@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700");
@import url("https://fonts.googleapis.com/css?family=Nunito:400,600,700");
@import url("./../icons/material-design-iconic-font/css/materialdesignicons.min.css");
@import url("./../icons/themify-icons/css/themify-icons.css");
@import url("./../icons/flaticon/flaticon.css");
@import url("./../icons/flaticon_1/flaticon_1.css");
@import url(./../vendor/metismenu/css/metisMenu.min.css);


a{
	text-decoration:none !important;
	cursor:pointer;
	color:#f20487;
}


button{
	cursor:pointer;
}

button,
a:hover,a:focus,a:visited{
	text-decoration:none;
	outline:none !important;
  border: none !important;
}

.dark-color {
  color: #252627 !important;
}

.dash-background {
  background:rgba(255, 201, 228, 0.3)
  ;
}

.strong-wight {
  font-weight: 600 !important;
}

.advanced-set {
  color: #353638 !important;
}

.bg-info-prim{
  background: #b67bee !important;
}


.bg-info-light{
  background: #ce2b7c !important;
}

.bg-primary-b{
  background: #d0d24b !important;
}


.bg-warning-light{
  background: #fa7fc2 !important;
}

.advanced-set .form-check-label {
  font-size: 14px !important;
}



.list-g-border .list-group-item {
  border: 1px solid var(--border) !important;
}

.list-group-item {
  position: relative;
  display: block;
  padding: .75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;

}



.nav-header {
  height: var(--dz-header-height);
  width: var(--dz-sidebar-width);
  display: inline-block;
  text-align: left;
  position: absolute;
  top: 0;
  background-color: #ce2b7c;
  transition: all .2s ease;
  z-index: 7;
}

.nav-link-styling {
  background-color: var(--rgba-primary-1) !important;
  border-color: var(--rgba-primary-1) !important;
  color: var(--primary) !important;
  border-radius: 10px !important;
  padding: 12px !important;
  border-bottom: none !important;



}



.loader-containerz {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}





.tryal-gradient-events {
  background: linear-gradient(to right, #8d14ff, #b67bee);
  position: relative;
}

.bg-teal{
  background: #1eafdc !important  ;
}

.veri-text p{
  color: #fff !important;
}


.analy-text p{
  color: #ffffff !important;
}


.nav-link-styling.active {
  background-color: var(--primary) !important;
  color: #ffffff !important;

  
}

.light-dark-btn.btn-primary {
  background-color: rgba(75, 30, 206, 0.1);
  border-color: #8d14ff;
  color: #8d14ff !important;
}

.light-dark-btn.btn-primary:hover {
  color: #ffffff !important;
  background-color: #8d14ff !important;
}


.nav-link {
  font-size: 14px !important;
}

.custom-tab-1 .nav-link i {
  font-size: 10px !important;
}


.tryal-gradient:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  /* FF3.6-15 */
  background: #8862fc;

  /* Chrome10-25,Safari5.1-6 */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#85ffffff', GradientType=0);
  /* IE6-9 */
  border-radius: 0.625rem;
}








.gradient_one {
  background-image: linear-gradient(to right, rgba(186, 1, 181, 0.85) 0%, rgba(103, 25, 255, 0.85) 100%);
}

.gradient-1 {
  background: #f0a907;
  background: -moz-linear-gradient(top, #f0a907 0%, #f53c79 100%);
  background: -webkit-linear-gradient(top, #f0a907 0%, #f53c79 100%);
  background: linear-gradient(to bottom, #f0a907 0%, #f53c79 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f0a907', endColorstr='#f53c79', GradientType=0);
}

.gradient-2 {
  background: #4dedf5;
  background: -moz-linear-gradient(top, #4dedf5 0%, #480ceb 100%);
  background: -webkit-linear-gradient(top, #4dedf5 0%, #480ceb 100%);
  background: linear-gradient(to bottom, #4dedf5 0%, #480ceb 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4dedf5', endColorstr='#480ceb', GradientType=0);
}

.gradient-3 {
  background: #51f5ae;
  background: -moz-linear-gradient(top, #51f5ae 0%, #3fbcda 100%);
  background: -webkit-linear-gradient(top, #51f5ae 0%, #3fbcda 100%);
  background: linear-gradient(to bottom, #51f5ae 0%, #3fbcda 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#51f5ae', endColorstr='#3fbcda', GradientType=0);
}

.gradient-4 {
  background: #f25521;
  background: -moz-linear-gradient(left, #f25521 0%, #f9c70a 100%);
  background: -webkit-linear-gradient(left, #f25521 0%, #f9c70a 100%);
  background: linear-gradient(to right, #f25521 0%, #f9c70a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f25521', endColorstr='#f9c70a', GradientType=1);
}

.gradient-5 {
  background: #f53c79;
  background: -moz-linear-gradient(left, #f53c79 0%, #f0a907 100%);
  background: -webkit-linear-gradient(left, #f53c79 0%, #f0a907 100%);
  background: linear-gradient(to right, #f53c79 0%, #f0a907 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f53c79', endColorstr='#f0a907', GradientType=1);
}

.gradient-6 {
  background: #36b9d8;
  background: -moz-linear-gradient(left, #36b9d8 0%, #4bffa2 100%);
  background: -webkit-linear-gradient(left, #36b9d8 0%, #4bffa2 100%);
  background: linear-gradient(to right, #36b9d8 0%, #4bffa2 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#36b9d8', endColorstr='#4bffa2', GradientType=1);
}

.gradient-7 {
  background: #4400eb;
  background: -moz-linear-gradient(left, #4400eb 0%, #44e7f5 100%);
  background: -webkit-linear-gradient(left, #4400eb 0%, #44e7f5 100%);
  background: linear-gradient(to right, #4400eb 0%, #44e7f5 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4400eb', endColorstr='#44e7f5', GradientType=1);
}

.gradient-8 {
  background: #F7B00F;
  background: -moz-linear-gradient(top, #F7B00F 0%, #F25521 100%);
  background: -webkit-linear-gradient(top, #F7B00F 0%, #F25521 100%);
  background: linear-gradient(to bottom, #F7B00F 0%, #F25521 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F7B00F', endColorstr='#F25521', GradientType=1);
}

.gradient-9 {
  background: #f31e7a !important;
  background: -moz-linear-gradient(left, #f31e7a 0%, #fd712c 100%);
  background: -webkit-linear-gradient(left, #f31e7a 0%, #fd712c 100%);
  background: linear-gradient(to right, #f31e7a 0%, #fd712c 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f31e7a', endColorstr='#fd712c', GradientType=1);
}

.gradient-10 {
  background: #f25521 !important;
  background: -moz-linear-gradient(left, #f25521 0%, #f9c70a 100%);
  background: -webkit-linear-gradient(left, #f25521 0%, #f9c70a 100%);
  background: linear-gradient(to top, #f25521 0%, #f9c70a 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f25521', endColorstr='#f9c70a', GradientType=1);
}

.gradient-11 {
  background: #3398fb;
  background: -moz-linear-gradient(left, #3398fb 0%, #8553ee 100%);
  background: -webkit-linear-gradient(left, #3398fb 0%, #8553ee 100%);
  background: linear-gradient(to right, #3398fb 0%, #8553ee 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3398fb', endColorstr='#8553ee', GradientType=1);
}

.gradient-12 {
  background: #36e1b4;
  background: -moz-linear-gradient(left, #36e1b4 0%, #11cae7 100%);
  background: -webkit-linear-gradient(left, #36e1b4 0%, #11cae7 100%);
  background: linear-gradient(to right, #36e1b4 0%, #11cae7 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#36e1b4', endColorstr='#11cae7', GradientType=1);
}

.gradient-13 {
  background: #ffbf31;
  background: -moz-linear-gradient(left, #ffbf31 0%, #ff890e 100%);
  background: -webkit-linear-gradient(left, #ffbf31 0%, #ff890e 100%);
  background: linear-gradient(to right, #ffbf31 0%, #ff890e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffbf31', endColorstr='#ff890e', GradientType=1);
}

.gradient-14 {
  background: #23bdb8;
  background: -moz-linear-gradient(-45deg, #23bdb8 0%, #43e794 100%);
  background: -webkit-linear-gradient(-45deg, #23bdb8 0%, #43e794 100%);
  background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#23bdb8', endColorstr='#43e794', GradientType=1);
}

.gradient-15 {
  background: #9a56ff;
  background: -moz-linear-gradient(-45deg, #9a56ff 0%, #e36cd9 100%);
  background: -webkit-linear-gradient(-45deg, #9a56ff 0%, #e36cd9 100%);
  background: linear-gradient(135deg, #9a56ff 0%, #e36cd9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#9a56ff', endColorstr='#e36cd9', GradientType=1);
}

.gradient-16 {
  background: #f48665;
  background: -moz-linear-gradient(-45deg, #f48665 0%, #fda23f 100%);
  background: -webkit-linear-gradient(-45deg, #f48665 0%, #fda23f 100%);
  background: linear-gradient(135deg, #f48665 0%, #fda23f 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f48665', endColorstr='#fda23f', GradientType=1);
}

.gradient-17 {
  background: #e36cd9;
  background: -moz-linear-gradient(-45deg, #e36cd9 0%, #fe60ae 100%);
  background: -webkit-linear-gradient(-45deg, #e36cd9 0%, #fe60ae 100%);
  background: linear-gradient(135deg, #e36cd9 0%, #fe60ae 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e36cd9', endColorstr='#fe60ae', GradientType=1);
}

.gradient-18 {
  background: #a15cff;
  background: -moz-linear-gradient(left, #a15cff 0%, #ce82fd 100%);
  background: -webkit-linear-gradient(left, #a15cff 0%, #ce82fd 100%);
  background: linear-gradient(to right, #a15cff 0%, #ce82fd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a15cff', endColorstr='#ce82fd', GradientType=1);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.35rem + 1.2vw);
}

@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.25rem;
  }
}

h2, .h2 {
  font-size: calc(1.3125rem + 0.75vw);
}

@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.875rem;
  }
}

h3, .h3 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.5rem;
  }
}

h4, .h4 {
  font-size: 1.125rem;
}

h5, .h5 {
  font-size: 1rem;
}

h6, .h6 {
  font-size: 0.938rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}

a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}

a>code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend+* {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}



.lead {
  font-size: 1.09375rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

.react-datepicker__header {
  background-color: var(--rgba-primary-2) !important;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  background-color: #8d14ff !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #8d14ff !important;
}

.css-13cymwt-control {
  background: #fff !important;
  border: 0.0625rem solid var(--border) !important;
  padding: 0.3125rem 1.25rem !important;
  height: 2.9rem !important;
  border-radius: 0.625rem !important;
}


.css-1jqq78o-placeholder {
  font-size: 0.875rem !important;
}

.css-t3ipsp-control {
  border-color: transparent !important;
}

.css-13cymwt-control:hover {
  border-color: transparent !important;
}

.css-1dimb5e-singleValue {

  font-size: 0.875rem !important;
}

.css-10wo9uf-option {
  font-size: 0.875rem !important;
}

.rbc-today {
  background-color: #4a1ece23 !important;
}

.css-d7l1ni-option {
  background-color: #4a1ece23 !important;
}

.css-12a83d4-MultiValueRemove:hover {
  color: #fff !important;
  background-color: #ce2b7c !important;
}

.css-12a83d4-MultiValueRemove {
  background-color: #4a1ece !important;
}

.css-wsp0cs-MultiValueGeneric {
  color: #8d14ff !important;
  background-color: #4a1ece23 !important;
}

.css-tr4s17-option {
  background-color: #ce2b7c !important;
}

.css-13cymwt-control:focus {
  border-color: red !important;
}

/* 
.css-13cymwt-control:hover{
  border-color: red !important;
} */


.css-qbdosj-Input::placeholder {
  font-size: 14px !important;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}



.react-datepicker__input-container input {
  padding-right: 100px;

  padding-top: 5px !important;

}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem;
}

.blockquote> :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1440) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px;
  }
}

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1440;
}

.row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
}

.row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto>* {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1>* {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2>* {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3>* {
  flex: 0 0 auto;
  width: 33.33333%;
}

.row-cols-4>* {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5>* {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6>* {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3>* {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-sm-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6>* {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.66667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333%;
  }

  .offset-sm-5 {
    margin-left: 41.66667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333%;
  }

  .offset-sm-8 {
    margin-left: 66.66667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333%;
  }

  .offset-sm-11 {
    margin-left: 91.66667%;
  }

  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3>* {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-md-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6>* {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.66667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333%;
  }

  .offset-md-5 {
    margin-left: 41.66667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333%;
  }

  .offset-md-8 {
    margin-left: 66.66667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333%;
  }

  .offset-md-11 {
    margin-left: 91.66667%;
  }

  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3>* {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-lg-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6>* {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.66667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333%;
  }

  .offset-lg-5 {
    margin-left: 41.66667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333%;
  }

  .offset-lg-8 {
    margin-left: 66.66667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333%;
  }

  .offset-lg-11 {
    margin-left: 91.66667%;
  }

  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3>* {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-xl-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6>* {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.66667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333%;
  }

  .offset-xl-5 {
    margin-left: 41.66667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333%;
  }

  .offset-xl-8 {
    margin-left: 66.66667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333%;
  }

  .offset-xl-11 {
    margin-left: 91.66667%;
  }

  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1440) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3>* {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-xxl-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6>* {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66667%;
  }

  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-body-color);

  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}

.table> :not(caption)>*>* {
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}

.table>tbody {
  vertical-align: inherit;
}

.table>thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm> :not(caption)>*>* {
  padding: 0.25rem 0.25rem;
}

.table-bordered> :not(caption)>* {
  border-width: var(--bs-border-width) 0;
}

.table-bordered> :not(caption)>*>* {
  border-width: 0 var(--bs-border-width);
}

.table-borderless> :not(caption)>*>* {
  border-bottom-width: 0;
}

.table-borderless> :not(:first-child) {
  border-top-width: 0;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns> :not(caption)>tr> :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover>tbody>tr:hover>* {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #e7e2f2;
  --bs-table-border-color: #d0cbda;
  --bs-table-striped-bg: #dbd7e6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d0cbda;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d6d1e0;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #ffedf7;
  --bs-table-border-color: #e6d5de;
  --bs-table-striped-bg: #f2e1eb;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6d5de;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecdbe4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #cef2d8;
  --bs-table-border-color: #b9dac2;
  --bs-table-striped-bg: #c4e6cd;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b9dac2;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe0c8;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #f7ddf3;
  --bs-table-border-color: #dec7db;
  --bs-table-striped-bg: #ebd2e7;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dec7db;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e4cce1;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff2cc;
  --bs-table-border-color: #e6dab8;
  --bs-table-striped-bg: #f2e6c2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dab8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece0bd;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #fed5dd;
  --bs-table-border-color: #e5c0c7;
  --bs-table-striped-bg: #f1cad2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e5c0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ebc5cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #c8c8c8;
  --bs-table-border-color: #b4b4b4;
  --bs-table-striped-bg: #bebebe;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b4b4b4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #b9b9b9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #312a2a;
  --bs-table-border-color: #463f3f;
  --bs-table-striped-bg: #3b3535;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #463f3f;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #403a3a;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1439.98) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.form-label-radio {
  margin-bottom: 12px;
  color: var(--text-dark);
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 1.09375rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.76563rem;
}

@media (min-width: 1200px) {
  .col-form-label-sm {
    font-size: 0.76562rem;
  }
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid #e6e6e6;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #c4b6e0;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(242, 4, 135, 0.25);
}

.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}

.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}

.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}

.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}

.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}

.form-control-plaintext:focus {
  outline: 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  border-radius: var(--bs-border-radius-sm);
}

@media (min-width: 1200px) {
  .form-control-sm {
    font-size: 0.76562rem;
  }
}

.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  border-radius: var(--bs-border-radius-lg);
}

.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
}

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}

.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}

.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}

.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid #e6e6e6;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: #c4b6e0;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(242, 4, 135, 0.25);
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}

.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.76563rem;
  border-radius: var(--bs-border-radius-sm);
}

@media (min-width: 1200px) {
  .form-select-sm {
    font-size: 0.76562rem;
  }
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.09375rem;
  border-radius: var(--bs-border-radius-lg);
}

[data-bs-theme="dark"] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}

.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  print-color-adjust: exact;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #c4b6e0;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(242, 4, 135, 0.25);
}

.form-check-input:checked {
  background-color: #886CC0;
  border-color: #886CC0;
}

.form-check-input:checked[type="checkbox"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #886CC0;
  border-color: #886CC0;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input[disabled]~.form-check-label, .form-check-input:disabled~.form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23c4b6e0'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}

.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check[disabled]+.btn, .btn-check:disabled+.btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme="dark"] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(242, 4, 135, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(242, 4, 135, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #886CC0;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #dbd3ec;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #886CC0;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #dbd3ec;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}

.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext,
.form-floating>.form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}

.form-floating>label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating>label {
    transition: none;
  }
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext {
  padding: 1rem 0.75rem;
}

.form-floating>.form-control::placeholder,
.form-floating>.form-control-plaintext::placeholder {
  color: transparent;
}

.form-floating>.form-control:focus, .form-floating>.form-control:not(:placeholder-shown),
.form-floating>.form-control-plaintext:focus,
.form-floating>.form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-control:-webkit-autofill,
.form-floating>.form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-control-plaintext~label,
.form-floating>.form-select~label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating>.form-control:focus~label::after,
.form-floating>.form-control:not(:placeholder-shown)~label::after,
.form-floating>.form-control-plaintext~label::after,
.form-floating>.form-select~label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
}

.form-floating>.form-control:-webkit-autofill~label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating>.form-control-plaintext~label {
  border-width: var(--bs-border-width) 0;
}

.form-floating> :disabled~label,
.form-floating>.form-control:disabled~label {
  color: #6c757d;
}

.form-floating> :disabled~label::after,
.form-floating>.form-control:disabled~label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group>.form-control,
.input-group>.form-select,
.input-group>.form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group>.form-control:focus,
.input-group>.form-select:focus,
.input-group>.form-floating:focus-within {
  z-index: 5;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid #e6e6e6;
  border-radius: var(--bs-border-radius);
}

.input-group-lg>.form-control,
.input-group-lg>.form-select,
.input-group-lg>.input-group-text,
.input-group-lg>.btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  border-radius: var(--bs-border-radius-lg);
}

.input-group-sm>.form-control,
.input-group-sm>.form-select,
.input-group-sm>.input-group-text,
.input-group-sm>.btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  border-radius: var(--bs-border-radius-sm);
}

@media (min-width: 1200px) {

  .input-group-sm>.form-control,
  .input-group-sm>.form-select,
  .input-group-sm>.input-group-text,
  .input-group-sm>.btn {
    font-size: 0.76562rem;
  }
}

.input-group-lg>.form-select,
.input-group-sm>.form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control,
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation> :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation>.dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation>.form-floating:nth-last-child(n + 3)>.form-control,
.input-group.has-validation>.form-floating:nth-last-child(n + 3)>.form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group>.form-floating:not(:first-child)>.form-control,
.input-group>.form-floating:not(:first-child)>.form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76563rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

@media (min-width: 1200px) {
  .valid-tooltip {
    font-size: 0.76562rem;
  }
}

.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip,
.is-valid~.valid-feedback,
.is-valid~.valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2309BD3C' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2309BD3C' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-check-input:valid~.form-check-label, .form-check-input.is-valid~.form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input~.valid-feedback {
  margin-left: .5em;
}

.was-validated .input-group>.form-control:not(:focus):valid, .input-group>.form-control:not(:focus).is-valid, .was-validated .input-group>.form-select:not(:focus):valid,
.input-group>.form-select:not(:focus).is-valid, .was-validated .input-group>.form-floating:not(:focus-within):valid,
.input-group>.form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76563rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

@media (min-width: 1200px) {
  .invalid-tooltip {
    font-size: 0.76562rem;
  }
}

.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip,
.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23FC2E53'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FC2E53' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23FC2E53'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FC2E53' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-check-input:invalid~.form-check-label, .form-check-input.is-invalid~.form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input~.invalid-feedback {
  margin-left: .5em;
}

.was-validated .input-group>.form-control:not(:focus):invalid, .input-group>.form-control:not(:focus).is-invalid, .was-validated .input-group>.form-select:not(:focus):invalid,
.input-group>.form-select:not(:focus).is-invalid, .was-validated .input-group>.form-floating:not(:focus-within):invalid,
.input-group>.form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.btn-check+.btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn-check:focus-visible+.btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn-check:checked+.btn,
:not(.btn-check)+.btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.btn-check:checked+.btn:focus-visible,
:not(.btn-check)+.btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn:disabled, .btn.disabled,
fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #000;
  --bs-btn-bg: #886CC0;
  --bs-btn-border-color: #886CC0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #9a82c9;
  --bs-btn-hover-border-color: #947bc6;
  --bs-btn-focus-shadow-rgb: 116, 92, 163;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #a089cd;
  --bs-btn-active-border-color: #947bc6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #886CC0;
  --bs-btn-disabled-border-color: #886CC0;
}

.btn-secondary {
  --bs-btn-color: #000;
  --bs-btn-bg: #8d14ff;
  --bs-btn-border-color: #FFA7D7;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffb4dd;
  --bs-btn-hover-border-color: #ffb0db;
  --bs-btn-focus-shadow-rgb: 217, 142, 183;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffb9df;
  --bs-btn-active-border-color: #ffb0db;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #FFA7D7;
  --bs-btn-disabled-border-color: #FFA7D7;
}

.btn-success {
  --bs-btn-color: #000;
  --bs-btn-bg: #09BD3C;
  --bs-btn-border-color: #09BD3C;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #2ec759;
  --bs-btn-hover-border-color: #22c450;
  --bs-btn-focus-shadow-rgb: 8, 161, 51;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3aca63;
  --bs-btn-active-border-color: #22c450;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #09BD3C;
  --bs-btn-disabled-border-color: #09BD3C;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #D653C1;
  --bs-btn-border-color: #D653C1;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #dc6dca;
  --bs-btn-hover-border-color: #da64c7;
  --bs-btn-focus-shadow-rgb: 182, 71, 164;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #de75cd;
  --bs-btn-active-border-color: #da64c7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #D653C1;
  --bs-btn-disabled-border-color: #D653C1;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #FFBF00;
  --bs-btn-border-color: #FFBF00;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc926;
  --bs-btn-hover-border-color: #ffc51a;
  --bs-btn-focus-shadow-rgb: 217, 162, 0;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcc33;
  --bs-btn-active-border-color: #ffc51a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #FFBF00;
  --bs-btn-disabled-border-color: #FFBF00;
}

.btn-danger {
  --bs-btn-color: #000;
  --bs-btn-bg: #FC2E53;
  --bs-btn-border-color: #FC2E53;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fc4d6d;
  --bs-btn-hover-border-color: #fc4364;
  --bs-btn-focus-shadow-rgb: 214, 39, 71;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fd5875;
  --bs-btn-active-border-color: #fc4364;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #FC2E53;
  --bs-btn-disabled-border-color: #FC2E53;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #c8c8c8;
  --bs-btn-border-color: #c8c8c8;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #aaaaaa;
  --bs-btn-hover-border-color: #a0a0a0;
  --bs-btn-focus-shadow-rgb: 170, 170, 170;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #a0a0a0;
  --bs-btn-active-border-color: #969696;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #c8c8c8;
  --bs-btn-disabled-border-color: #c8c8c8;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #312a2a;
  --bs-btn-border-color: #312a2a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #504a4a;
  --bs-btn-hover-border-color: #463f3f;
  --bs-btn-focus-shadow-rgb: 80, 74, 74;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #5a5555;
  --bs-btn-active-border-color: #463f3f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #312a2a;
  --bs-btn-disabled-border-color: #312a2a;
}

.btn-outline-primary {
  --bs-btn-color: #886CC0;
  --bs-btn-border-color: #886CC0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #886CC0;
  --bs-btn-hover-border-color: #886CC0;
  --bs-btn-focus-shadow-rgb: 136, 108, 192;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #886CC0;
  --bs-btn-active-border-color: #886CC0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #886CC0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #886CC0;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #FFA7D7;
  --bs-btn-border-color: #FFA7D7;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #FFA7D7;
  --bs-btn-hover-border-color: #FFA7D7;
  --bs-btn-focus-shadow-rgb: 255, 167, 215;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #FFA7D7;
  --bs-btn-active-border-color: #FFA7D7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFA7D7;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FFA7D7;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #09BD3C;
  --bs-btn-border-color: #09BD3C;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #09BD3C;
  --bs-btn-hover-border-color: #09BD3C;
  --bs-btn-focus-shadow-rgb: 9, 189, 60;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #09BD3C;
  --bs-btn-active-border-color: #09BD3C;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #09BD3C;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #09BD3C;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #D653C1;
  --bs-btn-border-color: #D653C1;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #D653C1;
  --bs-btn-hover-border-color: #D653C1;
  --bs-btn-focus-shadow-rgb: 214, 83, 193;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #D653C1;
  --bs-btn-active-border-color: #D653C1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #D653C1;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #D653C1;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #FFBF00;
  --bs-btn-border-color: #FFBF00;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #FFBF00;
  --bs-btn-hover-border-color: #FFBF00;
  --bs-btn-focus-shadow-rgb: 255, 191, 0;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #FFBF00;
  --bs-btn-active-border-color: #FFBF00;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFBF00;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FFBF00;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #FC2E53;
  --bs-btn-border-color: #FC2E53;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #FC2E53;
  --bs-btn-hover-border-color: #FC2E53;
  --bs-btn-focus-shadow-rgb: 252, 46, 83;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #FC2E53;
  --bs-btn-active-border-color: #FC2E53;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FC2E53;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FC2E53;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #c8c8c8;
  --bs-btn-border-color: #c8c8c8;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #c8c8c8;
  --bs-btn-hover-border-color: #c8c8c8;
  --bs-btn-focus-shadow-rgb: 200, 200, 200;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c8c8c8;
  --bs-btn-active-border-color: #c8c8c8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #c8c8c8;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #c8c8c8;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #312a2a;
  --bs-btn-border-color: #312a2a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #312a2a;
  --bs-btn-hover-border-color: #312a2a;
  --bs-btn-focus-shadow-rgb: 49, 42, 42;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #312a2a;
  --bs-btn-active-border-color: #312a2a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #312a2a;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #312a2a;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 116, 92, 163;
  text-decoration: underline;
}

.btn-link:focus-visible {
  color: var(--bs-btn-color);
}

.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg>.btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.09375rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-sm, .btn-group-sm>.btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.76563rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

@media (min-width: 1200px) {
  .btn-sm, .btn-group-sm>.btn {
    --bs-btn-font-size: 0.76562rem;
  }
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 0.875rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #886CC0;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);

  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1440) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropstart .dropdown-toggle::after {
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}

.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.76563rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

@media (min-width: 1200px) {
  .dropdown-header {
    font-size: 0.76562rem;
  }
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #886CC0;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group>.btn,
.btn-group-vertical>.btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group>.btn-check:checked+.btn,
.btn-group>.btn-check:focus+.btn,
.btn-group>.btn:hover,
.btn-group>.btn:focus,
.btn-group>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn-check:checked+.btn,
.btn-group-vertical>.btn-check:focus+.btn,
.btn-group-vertical>.btn:hover,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--bs-border-radius);
}

.btn-group> :not(.btn-check:first-child)+.btn,
.btn-group>.btn-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1);
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn.dropdown-toggle-split:first-child,
.btn-group>.btn-group:not(:last-child)>.btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group>.btn:nth-child(n + 3),
.btn-group> :not(.btn-check)+.btn,
.btn-group>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm+.dropdown-toggle-split, .btn-group-sm>.btn+.dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg+.dropdown-toggle-split, .btn-group-lg>.btn+.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
  width: 100%;
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn~.btn,
.btn-group-vertical>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(242, 4, 135, 0.25);
}

.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #886CC0;
}

.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}

.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}

.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}

.nav-underline .nav-link.active,
.nav-underline .show>.nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill>.nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified>.nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content>.tab-pane {
  display: none;
}

.tab-content>.active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.33594rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.09375rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.09375rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28157, 161, 165, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}

.navbar>.container,
.navbar>.container-fluid, .navbar>.container-sm, .navbar>.container-md, .navbar>.container-lg, .navbar>.container-xl, .navbar>.container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}

.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1440) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}

.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.card>hr {
  margin-right: 0;
  margin-left: 0;
}

.card>.list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card>.list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card>.list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link+.card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-headerz {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);

}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group>.card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }

  .card-group>.card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group>.card+.card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group>.card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-top,
  .card-group>.card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-bottom,
  .card-group>.card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-top,
  .card-group>.card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-bottom,
  .card-group>.card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%239da1a5'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23362b4d'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #c4b6e0;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(242, 4, 135, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 0.875rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}
.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

.breadcrumb-item+.breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/")
    /* rtl: var(--bs-breadcrumb-divider, "/") */
  ;
}

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(242, 4, 135, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #886CC0;
  --bs-pagination-active-border-color: #886CC0;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}

.page-link.active,
.active>.page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

.page-link.disabled,
.disabled>.page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.09375rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.76563rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

@media (min-width: 1200px) {
  .pagination-sm {
    --bs-pagination-font-size: 0.76562rem;
  }
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.65625rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #886CC0;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked>.progress {
  overflow: visible;
}

.progress-stacked>.progress>.progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #886CC0;
  --bs-list-group-active-border-color: #886CC0;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}

.list-group-numbered>.list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}

.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}

.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.list-group-item+.list-group-item {
  border-top-width: 0;
}

.list-group-item+.list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal>.list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}

.list-group-horizontal>.list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.list-group-horizontal>.list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1440) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush>.list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}

.list-group-flush>.list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(242, 4, 135, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.625rem;
  opacity: var(--bs-btn-close-opacity);
}

.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}

.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}

.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 30px;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container> :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}

.toast-header .btn-close {
  margin-right: calc(-.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}

.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}

.modal-footer>* {
  margin: calc(var(--bs-modal-footer-gap) * .5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }

  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }

  .modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1439.98) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.76563rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}

@media (min-width: 1200px) {
  .tooltip {
    --bs-tooltip-font-size: 0.76562rem;
  }
}

.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}

.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}

.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}

.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}

.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}

.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.76563rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 0.875rem;
  --bs-popover-header-color: #000;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}

@media (min-width: 1200px) {
  .popover {
    --bs-popover-font-size: 0.76562rem;
  }
}

.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}

.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top>.popover-arrow, .bs-popover-auto[data-popper-placement^="top"]>.popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}

.bs-popover-top>.popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::before, .bs-popover-top>.popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.bs-popover-top>.popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}

.bs-popover-top>.popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end>.popover-arrow, .bs-popover-auto[data-popper-placement^="right"]>.popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-end>.popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::before, .bs-popover-end>.popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.bs-popover-end>.popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}

.bs-popover-end>.popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom>.popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}

.bs-popover-bottom>.popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::before, .bs-popover-bottom>.popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.bs-popover-bottom>.popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}

.bs-popover-bottom>.popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start>.popover-arrow, .bs-popover-auto[data-popper-placement^="left"]>.popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-start>.popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::before, .bs-popover-start>.popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.bs-popover-start>.popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}

.bs-popover-start>.popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg)
      /* rtl:ignore */
    ;
  }
}

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {

  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

.offcanvas-sm, .offcanvas-md, .offcanvas-lg, .offcanvas-xl, .offcanvas-xxl, .offcanvas {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}

@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-sm .offcanvas-header {
    display: none;
  }

  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-md .offcanvas-header {
    display: none;
  }

  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}

@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-lg .offcanvas-header {
    display: none;
  }

  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-xl .offcanvas-header {
    display: none;
  }

  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1439.98) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 1439.98) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1439.98) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}

@media (min-width: 1440) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-xxl .offcanvas-header {
    display: none;
  }

  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}

.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}

.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}

.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}

.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}

.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}

.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
  margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}

.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: .6em;
}

.placeholder-sm {
  min-height: .8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}

.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #000 !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #000 !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #000 !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-primary:hover, .link-primary:focus {
  color: RGBA(160, 137, 205, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(160, 137, 205, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary:hover, .link-secondary:focus {
  color: RGBA(255, 185, 223, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 185, 223, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-success:hover, .link-success:focus {
  color: RGBA(58, 202, 99, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(58, 202, 99, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-info:hover, .link-info:focus {
  color: RGBA(222, 117, 205, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(222, 117, 205, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 204, 51, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 204, 51, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger:hover, .link-danger:focus {
  color: RGBA(253, 88, 117, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(253, 88, 117, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-light:hover, .link-light:focus {
  color: RGBA(211, 211, 211, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(211, 211, 211, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark:hover, .link-dark:focus {
  color: RGBA(39, 34, 34, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(39, 34, 34, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}

.icon-link>.bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}

@media (prefers-reduced-motion: reduce) {
  .icon-link>.bi {
    transition: none;
  }
}

.icon-link-hover:hover>.bi, .icon-link-hover:focus-visible>.bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}

.ratio>* {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 1440) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.35rem + 1.2vw) !important;
}

.fs-2 {
  font-size: calc(1.3125rem + 0.75vw) !important;
}

.fs-3 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-4 {
  font-size: 1.125rem !important;
}

.fs-5 {
  font-size: 1rem !important;
}

.fs-6 {
  font-size: 0.938rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .object-fit-sm-contain {
    object-fit: contain !important;
  }

  .object-fit-sm-cover {
    object-fit: cover !important;
  }

  .object-fit-sm-fill {
    object-fit: fill !important;
  }

  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }

  .object-fit-sm-none {
    object-fit: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-inline-grid {
    display: inline-grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .row-gap-sm-0 {
    row-gap: 0 !important;
  }

  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }

  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }

  .column-gap-sm-0 {
    column-gap: 0 !important;
  }

  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }

  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .object-fit-md-contain {
    object-fit: contain !important;
  }

  .object-fit-md-cover {
    object-fit: cover !important;
  }

  .object-fit-md-fill {
    object-fit: fill !important;
  }

  .object-fit-md-scale {
    object-fit: scale-down !important;
  }

  .object-fit-md-none {
    object-fit: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-inline-grid {
    display: inline-grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .row-gap-md-0 {
    row-gap: 0 !important;
  }

  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-md-3 {
    row-gap: 1rem !important;
  }

  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-md-5 {
    row-gap: 3rem !important;
  }

  .column-gap-md-0 {
    column-gap: 0 !important;
  }

  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-md-3 {
    column-gap: 1rem !important;
  }

  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-md-5 {
    column-gap: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .object-fit-lg-contain {
    object-fit: contain !important;
  }

  .object-fit-lg-cover {
    object-fit: cover !important;
  }

  .object-fit-lg-fill {
    object-fit: fill !important;
  }

  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }

  .object-fit-lg-none {
    object-fit: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-inline-grid {
    display: inline-grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .row-gap-lg-0 {
    row-gap: 0 !important;
  }

  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }

  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }

  .column-gap-lg-0 {
    column-gap: 0 !important;
  }

  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }

  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .object-fit-xl-contain {
    object-fit: contain !important;
  }

  .object-fit-xl-cover {
    object-fit: cover !important;
  }

  .object-fit-xl-fill {
    object-fit: fill !important;
  }

  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }

  .object-fit-xl-none {
    object-fit: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-inline-grid {
    display: inline-grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .row-gap-xl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }

  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }

  .column-gap-xl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }

  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1440) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .object-fit-xxl-contain {
    object-fit: contain !important;
  }

  .object-fit-xxl-cover {
    object-fit: cover !important;
  }

  .object-fit-xxl-fill {
    object-fit: fill !important;
  }

  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }

  .object-fit-xxl-none {
    object-fit: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-inline-grid {
    display: inline-grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }

  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }

  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }

  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.25rem !important;
  }

  .fs-2 {
    font-size: 1.875rem !important;
  }

  .fs-3 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-inline-grid {
    display: inline-grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

.c-pointer {
  cursor: pointer;
}

* {
  outline: none;
  padding: 0;
}

*::after {
  margin: 0;
  padding: 0;
}

*::before {
  margin: 0;
  padding: 0;
}

::selection {
  color: #fff;
  background: var(--primary);
}

textarea.form-control {
  min-height: auto !important;
  height: auto !important;
}

textarea.form-control.form-control {
  padding: 10px;
}

.dropzone .dz-message {
  margin: 5em 0;
}

@media only screen and (max-width: 100rem) {
  :root {
    font-size: 14px;
  }
}

body {
  overflow-x: hidden;
  height: 100%;
  position: relative;
  max-width: 100%;
  font-size: 0.875rem;
  color: var(--text);
  background-color: var(--body-bg);
}

@media only screen and (max-width: 61.9375rem) {
  body {
    font-size: 0.875rem;
  }
}

p {
  line-height: 1.8;
}

.box-shadow-none {
  box-shadow: none !important;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.light-logo {
  display: none;
}


#main-wrapper {
  opacity: 0;
  transition: all 0.25s ease-in;
  overflow: hidden;
  position: relative;
}

#main-wrapper.show {
  opacity: 1;
}

.rounded-lg {
  border-radius: 0.625rem;
}

.dlab-scroll {
  position: relative;
  overflow-y: scroll;
}

body>* {
  scrollbar-width: thin;
  scrollbar-color: rgba(111, 133, 147, 0.1) #fff0;
}

::-webkit-scrollbar {
  width: 5px;
  opacity: 0;
}

::-webkit-scrollbar-thumb {
  background: rgba(111, 133, 147, 0.1);
}

div, span, ol, ul {
  scrollbar-width: thin;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a {
  color: #9da1a5;
  text-decoration: none;
}

a:hover, a:focus, a.active {
  text-decoration: none;
}

.btn-link:hover, .btn-link:focus, .btn-link.active {
  text-decoration: none;
}

.bg-primary {
  background-color: var(--primary) !important;
}

.text-primary {
  color: var(--primary) !important;
}

.fs-12 {
  font-size: 0.75rem !important;
  line-height: 1.5;
}

.fs-13 {
  font-size: 0.8125rem !important;
  line-height: 1.5;
}

.fs-14 {
  font-size: 12px !important;
  line-height: 1.6;
}

.fs-15 {
  font-size: 0.875rem !important;
  line-height: 1.5;
}

.fs-16 {
  font-size: 1rem !important;
  line-height: 1.6;
}

@media only screen and (max-width: 35.9375rem) {
  .fs-16 {
    font-size: 0.875rem !important;
  }
}

.fs-18 {
  font-size: 1.125rem !important;
  line-height: 1.5;
}

@media only screen and (max-width: 35.9375rem) {
  .fs-18 {
    font-size: 1rem !important;
  }
}

.fs-20 {
  font-size: 1.25rem !important;
  line-height: 1.5;
}

.fs-22 {
  font-size: 1.375rem !important;
  line-height: 1.5;
}

.fs-24 {
  font-size: 1.5rem !important;
  line-height: 1.4;
}

.fs-26 {
  font-size: 1.625rem !important;
  line-height: 1.4;
}

.fs-28 {
  font-size: 1.75rem !important;
  line-height: 1.4;
}

@media only screen and (max-width: 35.9375rem) {
  .fs-28 {
    font-size: 1.5rem !important;
  }
}

.fs-30 {
  font-size: 1.875rem !important;
  line-height: 1.4;
}

.fs-32 {
  font-size: 2rem !important;
  line-height: 1.25;
}

.fs-34 {
  font-size: 2.125rem !important;
  line-height: 1.25;
}

.fs-35 {
  font-size: 2.1875rem !important;
  line-height: 1.25;
}

.fs-36 {
  font-size: 2.25rem !important;
  line-height: 1.25;
}

.fs-38 {
  font-size: 2.375rem !important;
  line-height: 1.25;
}

.fs-46 {
  font-size: 2.875rem !important;
  line-height: 1.25;
}

.fs-48 {
  font-size: 3rem !important;
  line-height: 1.25;
}

.font-w100 {
  font-weight: 100;
}

.font-w200 {
  font-weight: 200;
}

.font-w300 {
  font-weight: 300;
}

.font-w400 {
  font-weight: 400;
}

.font-w500 {
  font-weight: 500;
}

.font-w600 {
  font-weight: 600;
}

.font-w700 {
  font-weight: 700;
}

.font-w800 {
  font-weight: 800;
}

.font-w900 {
  font-weight: 900;
}

h1, .h1, .h1, h2, .h2, .h2, h3, .h3, .h3, h4, .h4, .h4, h5, .h5, .h5, h6, .h6, .h6 {
  line-height: 1.5;
  color: var(--text-dark);
  font-weight: 600;
}

.w-space-no {
  white-space: nowrap;
}

.content-body .container {
  margin-top: 2.5rem;
}

.content-body .container-fluid, .content-body .container-sm, .content-body .container-md, .content-body .container-lg, .content-body .container-xl, .content-body .container-xxl {
  padding-top: 1.875rem;
  padding-right: 1.875rem;
  padding-left: 1.875rem;
}

@media only screen and (max-width: 47.9375rem) {
  .content-body .container-fluid, .content-body .container-sm, .content-body .container-md, .content-body .container-lg, .content-body .container-xl, .content-body .container-xxl {
    padding-top: 1.25rem;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .content-body .container-fluid, .content-body .container-sm, .content-body .container-md, .content-body .container-lg, .content-body .container-xl, .content-body .container-xxl {
    padding-top: 0.9375rem;
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

/*sp*/
.row.sp4,
.sp4 {
  margin-left: -0.125rem;
  margin-right: -0.125rem;
}

.row.sp4 [class*="col-"],
.sp4 [class*="col-"] {
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}

.op1 {
  opacity: 0.1;
}

.op2 {
  opacity: 0.2;
}

.op3 {
  opacity: 0.3;
}

.op4 {
  opacity: 0.4;
}

.op5 {
  opacity: 0.5;
}

.op6 {
  opacity: 0.6;
}

.op7 {
  opacity: 0.7;
}

.op8 {
  opacity: 0.8;
}

.op9 {
  opacity: 0.9;
}

.content-heading {
  font-size: 1rem;
  margin-bottom: 1.875rem;
  margin-top: 3.125rem;
  border-bottom: 0.0625rem solid #e6e6e6;
  padding-bottom: 0.625rem;
}

[direction="rtl"] .content-heading {
  text-align: right;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
}

.vh-100 {
  height: 100vh !important;
}

.support-ticket {
  position: fixed;
  bottom: 1.875rem;
  right: 0.9375rem;
  z-index: 999999;
}

.support-ticket-btn {
  width: 6.25rem;
  background: #7CB442;
  animation: 0.7s ease-in-out 0s infinite alternate none running crescendo;
  border-radius: 3.125rem;
  color: #fff;
  font-size: 0.5rem;
  font-size: 1rem;
  padding: 0.3125rem 0.625rem 0.4375rem;
  text-align: center;
  display: inline-block;
  box-shadow: rgba(124, 180, 66, 0.7) 0rem 0.5rem 2.1875rem 0rem;
}

.support-ticket-btn:hover, .support-ticket-btn:focus {
  color: #fff;
}

.text-blue {
  color: #5e72e4;
}

.text-indigo {
  color: #6610f2;
}

.text-purple {
  color: #6f42c1;
}

.text-pink {
  color: #e83e8c;
}

.text-red {
  color: #EE3232;
}

.text-orange {
  color: #ff9900;
}

.text-yellow {
  color: #FFFA6F;
}

.text-green {
  color: #297F00;
}

.text-teal {
  color: #20c997;
}

.text-cyan {
  color: #3065D0;
}

.bg-blue {
  background: #496ecc !important;
}

.bg-orange {
  background: #ed8030 !important;
}

.bg-green {
  background: #299e4a !important;
}

.bg-purpel {
  background: #9517c1 !important;
}

.bg-dark-blue {
  background: #251e71 !important;
}

.bg-black {
  background: #000;
}

.text-black {
  color: #000 !important;
}

.dlab-scroll {
  position: relative;
}

.scale1 {
  transform: scale(1.1);
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  display: inline-block;
}

.scale2 {
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  display: inline-block;
}

.scale3 {
  transform: scale(1.3);
  -moz-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  display: inline-block;
}

.scale4 {
  transform: scale(1.4);
  -moz-transform: scale(1.4);
  -webkit-transform: scale(1.4);
  -ms-transform: scale(1.4);
  -o-transform: scale(1.4);
  display: inline-block;
}

.scale5 {
  transform: scale(1.5);
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
  display: inline-block;
}

.scale-2 {
  transform: scale(2);
  -moz-transform: scale(2);
  -webkit-transform: scale(2);
  -ms-transform: scale(2);
  -o-transform: scale(2);
  display: inline-block;
}

@-webkit-keyframes crescendo {
  from {
    -webkit-transform: translateY(5px) scale(0.8);
    -ms-transform: translateY(5px) scale(0.8);
    transform: translateY(5px) scale(0.8);
  }

  to {
    -webkit-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1);
  }
}

.height10 {
  height: 0.625rem;
}

.height20 {
  height: 1.25rem;
}

.height30 {
  height: 1.875rem;
}

.height40 {
  height: 2.5rem;
}

.height50 {
  height: 3.125rem;
}

.height60 {
  height: 3.75rem;
}

.height70 {
  height: 4.375rem;
}

.height80 {
  height: 5rem;
}

.height90 {
  height: 5.625rem;
}

.height100 {
  height: 6.25rem;
}

.height110 {
  height: 6.875rem;
}

.height120 {
  height: 7.5rem;
}

.height130 {
  height: 8.125rem;
}

.height140 {
  height: 8.75rem;
}

.height150 {
  height: 9.375rem;
}

.height160 {
  height: 10rem;
}

.height170 {
  height: 10.625rem;
}

.height180 {
  height: 11.25rem;
}

.height190 {
  height: 11.875rem;
}

.height200 {
  height: 12.5rem;
}

.height210 {
  height: 13.125rem;
}

.height220 {
  height: 13.75rem;
}

.height230 {
  height: 14.375rem;
}

.height240 {
  height: 15rem;
}

.height250 {
  height: 15.625rem;
}

.height260 {
  height: 16.25rem;
}

.height270 {
  height: 16.875rem;
}

.height280 {
  height: 17.5rem;
}

.height290 {
  height: 18.125rem;
}

.height300 {
  height: 18.75rem;
}

.height310 {
  height: 19.375rem;
}

.height320 {
  height: 20rem;
}

.height330 {
  height: 20.625rem;
}

.height340 {
  height: 21.25rem;
}

.height350 {
  height: 21.875rem;
}

.height360 {
  height: 22.5rem;
}

.height370 {
  height: 23.125rem;
}

.height380 {
  height: 23.75rem;
}

.height390 {
  height: 24.375rem;
}

.height400 {
  height: 25rem;
}

.height415 {
  height: 25.9375rem;
}

.height500 {
  height: 31.25rem;
}

.height550 {
  height: 34.375rem;
}

.height600 {
  height: 37.5rem;
}

.height630 {
  height: 39.375rem;
}

.height720 {
  height: 45rem;
}

.height750 {
  height: 46.875rem;
}

.height800 {
  height: 50rem;
}

.width10 {
  width: 0.625rem;
}

.width20 {
  width: 1.25rem;
}

.width30 {
  width: 1.875rem;
}

.width40 {
  width: 2.5rem;
}

.width50 {
  width: 3.125rem;
}

.width60 {
  width: 3.75rem;
}

.width70 {
  width: 4.375rem;
}

.width80 {
  width: 5rem;
}

.width90 {
  width: 5.625rem;
}

.width100 {
  width: 6.25rem;
}

.width110 {
  width: 6.875rem;
}

.width120 {
  width: 7.5rem;
}

.width130 {
  width: 8.125rem;
}

.width140 {
  width: 8.75rem;
}

.width150 {
  width: 9.375rem;
}

.width160 {
  width: 10rem;
}

.width170 {
  width: 10.625rem;
}

.width180 {
  width: 11.25rem;
}

.width190 {
  width: 11.875rem;
}

.width200 {
  width: 12.5rem;
}

.width210 {
  width: 13.125rem;
}

.width220 {
  width: 13.75rem;
}

.width230 {
  width: 14.375rem;
}

.width240 {
  width: 15rem;
}

.width250 {
  width: 15.625rem;
}

.width260 {
  width: 16.25rem;
}

.width270 {
  width: 16.875rem;
}

.width280 {
  width: 17.5rem;
}

.width290 {
  width: 18.125rem;
}

.width300 {
  width: 18.75rem;
}

.width310 {
  width: 19.375rem;
}

.width320 {
  width: 20rem;
}

.width330 {
  width: 20.625rem;
}

.width340 {
  width: 21.25rem;
}

.width350 {
  width: 21.875rem;
}

.width360 {
  width: 22.5rem;
}

.width370 {
  width: 23.125rem;
}

.width380 {
  width: 23.75rem;
}

.width390 {
  width: 24.375rem;
}

.width400 {
  width: 25rem;
}

.rounded {
  border-radius: 0.625rem !important;
}

label {
  margin-bottom: 0.5rem;
}

@keyframes gXGDoR {
  from {
    -webkit-transform: translateY(5px) scale(0.8);
    -ms-transform: translateY(5px) scale(0.8);
    transform: translateY(5px) scale(0.8);
  }

  to {
    -webkit-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1);
  }
}

@keyframes crescendo {
  from {
    -webkit-transform: translateY(5px) scale(0.8);
    -ms-transform: translateY(5px) scale(0.8);
    transform: translateY(5px) scale(0.8);
  }

  to {
    -webkit-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1);
  }
}

@keyframes gXGDoR {
  from {
    -webkit-transform: translateY(5px) scale(0.8);
    -ms-transform: translateY(5px) scale(0.8);
    transform: translateY(5px) scale(0.8);
  }

  to {
    -webkit-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1);
  }
}

.required {
  position: relative;
}

.required:after {
  content: "\f621";
  position: absolute;
  font-size: 6px;
  font-weight: 900;
  color: #FC2E53;
  font-family: "Font Awesome 6 Free";
  padding-left: 3px;
}



.dlabnav-scroll {
  scrollbar-width: none;
}

.text-black {
  color: var(--text-dark) !important;
}

.text-gray {
  color: var(--text-gray) !important;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
}

/* Editable */
#preloader {
  background-color: #fff;
  padding: 0;
  margin: 0;
  height: 100%;
  position: fixed;
  z-index: 99999;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.brand-logo img{
  width: 120px !important;
}

#preloader .lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

#preloader .lds-ripple div {
  position: absolute;
  border: 4px solid var(--primary);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

#preloader .lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}


.footer {
  padding-left: var(--dz-sidebar-width);
  background-color: var(--card);
  font-size: 0.875rem;
}

.footer .copyright {
  padding: 0.9375rem;
}

.footer .copyright p {
  text-align: center;
  margin: 0;
}

.footer .copyright a {
  color: var(--primary);
}



.nav-header .logo-abbr {
  max-width: 2.9375rem;
}

@media only screen and (max-width: 87.5rem) {
  .nav-header .logo-abbr {
    max-width: 2.8125rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .nav-header .logo-abbr {
    width: 2.1875rem;
    height: 2.1875rem;
  }
}

.nav-header .logo-compact {
  display: none;
}

.nav-header .brand-logo {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.125rem;
  color: #fff;
  text-decoration: none;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-weight: 700;
}

@media only screen and (max-width: 87.5rem) {
  .nav-header .brand-logo {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .nav-header .brand-logo {
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
  }
}

.nav-header .brand-title {
  margin-left: 0.9375rem;
  max-width: 8.75rem;
}

.nav-header .brand-title img {
  width: 100%;
}

@media only screen and (max-width: 47.9375rem) {
  .nav-header .brand-title {
    display: none;
  }
}

.nav-header .rect-primary-rect {
  fill: var(--primary);
}

@media only screen and (max-width: 47.9375rem) {
  .nav-header {
    width: 5rem;
    background-color: transparent;
  }
}

.nav-control {
  cursor: pointer;
  position: absolute;
  right: -4rem;
  text-align: center;
  top: 55%;
  transform: translateY(-50%);
  z-index: 9999;
  font-size: 1.4rem;
  padding: 0.125rem 0.5rem 0;
  border-radius: 0.125rem;
}

@media only screen and (max-width: 87.5rem) {
  .nav-control {
    right: -3.444rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .nav-control {
    right: -3rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .nav-control {
    right: -3rem;
  }
}

.hamburger {
  display: inline-block;
  left: 0rem;
  position: relative;
  top: 0rem;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  width: 1.625rem;
  z-index: 999;
}

.hamburger .line {
  background: var(--text-gray);
  display: block;
  height: 2px;
  border-radius: 0.1875rem;
  margin-top: 0.375rem;
  margin-bottom: 0.375rem;
  margin-right: auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.hamburger .line:nth-child(1) {
  width: 1.625rem;
}

.hamburger .line:nth-child(2) {
  width: 1.625rem;
}

.hamburger .line:nth-child(3) {
  width: 0.9375rem;
}

.hamburger:hover {
  cursor: pointer;
}

.hamburger:hover .line {
  width: 1.625rem;
}

.hamburger.is-active .line:nth-child(1),
.hamburger.is-active .line:nth-child(3) {
  width: 0.625rem;
  height: 0.125rem;
}

.hamburger.is-active .line:nth-child(2) {
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
  width: 1.375rem;
  height: 0.125rem;
}

.hamburger.is-active .line:nth-child(1) {
  -webkit-transform: translateY(4px) translateX(12px) rotate(45deg);
  transform: translateY(4px) translateX(12px) rotate(45deg);
}

.hamburger.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-4px) translateX(12px) rotate(-45deg);
  transform: translateY(-4px) translateX(12px) rotate(-45deg);
}

[data-header-position="fixed"] .nav-header,
[data-sidebar-position="fixed"][data-layout="vertical"] .nav-header,
[data-sidebar-position="fixed"][data-header-position="fixed"] .nav-header {
  position: fixed;
}

[data-sidebar-style="compact"][data-layout="vertical"] .nav-header .brand-title {
  display: none;
}

[data-sidebar-style="compact"][data-layout="vertical"] .nav-header .logo-compact {
  max-width: 4.6875rem;
}

[data-sidebar-style="compact"][data-layout="vertical"] .nav-header .brand-logo {
  justify-content: center;
}

[data-sidebar-style="modern"][data-layout="vertical"] .nav-header .brand-title {
  display: none;
}

[data-sidebar-style="modern"][data-layout="vertical"] .nav-header .brand-logo {
  justify-content: center;
}

[data-sidebar-style="modern"][data-layout="vertical"] .nav-header .logo-compact {
  display: none;
}

.header {
  height: var(--dz-header-height);
  z-index: 1;
  position: var(--dz-header-pos);
  padding: 0rem;
  background: var(--headerbg);
  z-index: 3;
  top: 0;
  width: 100%;
  padding-left: var(--dz-sidebar-width);
  border-bottom: 1px solid var(--border);
  transition: all .2s ease;
}

.header .header-content {
  position: relative;
  height: 100%;
  padding-left: 5rem;
  padding-right: 1.875rem;
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 74.9375rem) {
  .header .header-content {
    padding-left: 4rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .header .header-content {
    padding-left: 3.75rem;
    padding-right: 0.938rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .header .header-content {
    padding-right: 1.638rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .header .header-content {
    padding-right: 1.238rem;
    padding-left: 2.35rem;
  }
}

.header .header-content .nav-control {
  right: auto;
  left: 1rem;
}

.header .navbar {
  padding: 0;
  height: 100%;
  width: 100%;
}

.header .navbar .navbar-collapse {
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 47.9375rem) {
  .header {
    padding-left: 5rem;
  }
}

[data-header-position="fixed"] {
  --dz-header-pos: fixed;
}

[data-header-position="fixed"] .content-body {
  padding-top: var(--dz-header-height);
}

.header-left {
  height: 100%;
  display: flex;
  align-items: center;
}

.header-left .breadcrumb {
  margin-bottom: 0;
}

.header-left .agenda a {
  border-radius: 1.75rem;
}

.header-left .agenda a i {
  margin-right: 0.75rem;
}

@media only screen and (max-width: 87.5rem) {
  .header-left .agenda a i {
    margin-right: 0rem;
  }
}

@media only screen and (max-width: 93.75rem) {
  .header-left .agenda a {
    font-size: 0;
    border-radius: 0.75rem;
    padding: 0.8125rem 0.9375rem;
  }

  .header-left .agenda a i {
    font-size: 1.25rem;
    margin-right: 0;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .header-left .agenda {
    display: none;
  }
}

.header-left .dashboard_bar {
  font-size: var(--dz-head-title-size);
  font-weight: 700;
  color: var(--text-dark);
}

@media only screen and (max-width: 47.9375rem) {
  .header-left .dashboard_bar {
    display: none;
  }
}

.header-left .dashboard_bar.sub-bar {
  font-size: 1.875rem;
}

[data-sidebar-style="compact"] .header-left {
  margin-left: 0;
}

.header-right {
  height: 100%;
}

.header-right .nav-item {
  height: 100%;
  display: flex;
  align-items: center;
}

.header-right .nav-item .nav-link {
  color: var(--text-gray);
  font-size: 1.25rem;
}

@media only screen and (max-width: 87.5rem) {
  .header-right .nav-item .nav-link {
    font-size: 1.125rem;
  }
}

.header-right .right-sidebar {
  margin-right: -1.875rem;
}

.header-right .right-sidebar a {
  height: 5rem;
  width: 5rem;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  border-left: 0.0625rem solid #c8c8c8;
}

.header-right>li:not(:first-child) {
  padding-left: 1rem;
}

@media only screen and (max-width: 74.9375rem) {
  .header-right>li:not(:first-child) {
    padding-left: 1rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .header-right>li:not(:first-child) {
    padding-left: 0.4rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .header-right .notification_dropdown {
    position: static;
  }
}

.header-right .notification_dropdown .nav-link {
  position: relative;
  color: var(--primary);
  border-radius: 0.625rem;
  padding: 0.9375rem;
  line-height: 1;
}

.header-right .notification_dropdown .nav-link svg {
  width: 1.25rem;
  height: 1.25rem;
}

@media only screen and (max-width: 87.5rem) {
  .header-right .notification_dropdown .nav-link {
    padding: 0.625rem;
  }
}

.header-right .notification_dropdown .nav-link i {
  font-size: 1.25rem;
}

@media only screen and (max-width: 87.5rem) {
  .header-right .notification_dropdown .nav-link i {
    font-size: 1.125rem;
  }
}

@media only screen and (max-width: 87.5rem) {
  .header-right .notification_dropdown .nav-link svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .header-right .notification_dropdown .nav-link svg {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.header-right .notification_dropdown .nav-link .badge {
  position: absolute;
  font-size: 0.675rem;
  border-radius: 1.875rem !important;
  font-weight: normal;
  line-height: 1.25rem;
  text-align: center;
  right: 5px;
  top: 7px;
  height: 1.2rem;
  width: 1.2rem;
  line-height: 1rem;
  text-align: center;
  padding: 0.1rem;
}

@media only screen and (max-width: 100rem) {
  .header-right .notification_dropdown .nav-link .badge {
    top: 5px;
    right: 5px;
  }
}

@media only screen and (max-width: 87.5rem) {
  .header-right .notification_dropdown .nav-link .badge {
    top: 3px;
    right: 0px;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .header-right .notification_dropdown .nav-link .badge {
    height: 1.125rem;
    width: 1.125rem;
    padding: 0;
    line-height: 0.9375rem;
    right: 0rem;
    top: -0.125rem;
  }
}

.header-right .notification_dropdown .dropdown-item:focus a, .header-right .notification_dropdown .dropdown-item:active a {
  color: #fff;
}

.header-right .notification_dropdown .dropdown-item a {
  color: #312a2a;
}

.header-right .notification_dropdown .dropdown-item a:hover {
  text-decoration: none;
}

.header-right .dropdown-menu {
  border-width: 0;
  box-shadow: 0 0 2.3125rem rgba(8, 21, 66, 0.05);
}


.header-right .search-area {
  width: 19.75rem;
  border-radius: 50%;
}

.header-right .search-area .form-control {
  height: 3.0rem;
  border-right: 0;
  border-color: #EEEEEE;
  border-top-left-radius: 3.125rem;
  border-bottom-left-radius: 3.125rem;
}

.header-right .search-area .form-control:hover, .header-right .search-area .form-control:focus {
  border-color: #EEEEEE;
}

.header-right .search-area .input-group-text {
  height: 3rem;
  border-radius: 3.125rem;
  background: #fff;
  padding: 0 1.25rem;
  border-color: #e9e2f8;
}

.header-right .search-area .input-group-text i {
  font-size: 1.2rem;
}

@media only screen and (max-width: 100rem) {
  .header-right .search-area {
    width: 15.625rem;
  }
}

@media only screen and (max-width: 74.9375rem) {
  .header-right .search-area {
    display: none;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .header-right .invoices-btn {
    display: none;
  }
}

.dz-theme-mode #icon-light {
  display: none;
}

.dz-theme-mode.active #icon-light {
  display: inline-block;
}

.dz-theme-mode.active #icon-dark {
  display: none;
}

.notification_dropdown .dropdown-menu-end {
  min-width: 19.375rem;
  padding: 0rem 0 1rem;
  top: 100%;
}

.notification_dropdown .dropdown-menu-end .notification_title {
  background: var(--primary);
  color: #fff;
  padding: 0.625rem 1.25rem;
}

.notification_dropdown .dropdown-menu-end .notification_title h5, .notification_dropdown .dropdown-menu-end .notification_title .h5 {
  color: #fff;
  margin-bottom: 0.1875rem;
}

.notification_dropdown .dropdown-menu-end .media {
  width: 2.8125rem;
  height: 2.8125rem;
  font-size: 1.125rem;
}


.notification_dropdown .dropdown-menu-end .media>span {
  width: 2.1875rem;
  height: 2.1875rem;
  border-radius: 3.125rem;
  display: inline-block;
  padding: 0.4375rem 0.5625rem;
  margin-right: 0.625rem;
}

[direction="rtl"].notification_dropdown .dropdown-menu-end .media>span {
  margin-right: 0;
  margin-left: 0.625rem;
}

.notification_dropdown .dropdown-menu-end .media>span.success {
  background: #d7fde2;
  color: #09BD3C;
}

.notification_dropdown .dropdown-menu-end .media>span.primary {
  background: var(--rgba-primary-1);
  color: var(--primary);
}

.notification_dropdown .dropdown-menu-end .media>span.danger {
  background: #ffedf0;
  color: #FC2E53;
}

.notification_dropdown .dropdown-menu-end .media .notify-time {
  width: 100%;
  margin-right: 0;
  color: #828690;
}

.notification_dropdown .dropdown-menu-end .media p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 12.5rem;
  margin-bottom: 0;
  margin-top: 0.3125rem;
}

@media only screen and (max-width: 35.9375rem) {
  .notification_dropdown .dropdown-menu-end .media p {
    max-width: 6.25rem;
  }
}

.notification_dropdown .dropdown-menu-end .all-notification {
  display: block;
  padding: 0.9375rem 1.875rem 0;
  text-align: center;
  border-top: 0.0625rem solid var(--border);
  color: var(--primary);
}

.notification_dropdown .dropdown-menu-end .all-notification i {
  margin-left: 0.625rem;
}

.header-profile>a.nav-link {
  padding: 0;
  display: flex;
  align-items: center;
}

.header-profile>a.nav-link i {
  font-weight: 700;
}

.header-profile>a.nav-link .header-info {
  padding-left: 0rem;
  text-align: left;
}

@media only screen and (max-width: 87.5rem) {
  .header-profile>a.nav-link .header-info {
    padding-left: 0.625rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .header-profile>a.nav-link .header-info {
    display: none;
  }
}

.header-profile>a.nav-link .header-info span {
  font-size: 1rem;
  color: #000;
  display: block;
  font-weight: 600;
}

.header-profile>a.nav-link .header-info strong {
  color: #312a2a;
}

.header-profile>a.nav-link .header-info small, .header-profile>a.nav-link .header-info .small {
  display: block;
  font-size: 0.8125rem;
  color: #89879f;
  font-weight: 400;
  line-height: 1.2;
}

@media only screen and (max-width: 87.5rem) {
  .header-profile>a.nav-link {
    margin-left: 0rem;
    padding-left: 0rem;
  }

  .header-profile>a.nav-link .header-info span {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .header-profile>a.nav-link {
    margin-left: 0rem;
    padding-left: 0rem;
    border-left: 0;
  }
}

.header-profile .dropdown-menu {
  padding: 0.9375rem 0;
  min-width: 12.5rem;
}

.header-profile .dropdown-menu a:hover, .header-profile .dropdown-menu a:focus, .header-profile .dropdown-menu a.active {
  color: #886CC0;
}

.header-profile img {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 0.625rem;
}

@media only screen and (max-width: 87.5rem) {
  .header-profile img {
    width: 2.4375rem;
    height: 2.4375rem;
  }
}

.header-profile .dropdown-toggle i {
  font-size: 1.25rem;
}

@media only screen and (max-width: 35.9375rem) {
  .header-profile .dropdown-toggle span {
    display: none;
  }
}

.header-profile .profile_title {
  background: #886CC0;
  color: #fff;
  padding: 0.625rem 1.25rem;
}

.header-profile .profile_title h5, .header-profile .profile_title .h5 {
  color: #fff;
  margin-bottom: 0.1875rem;
}

.header-profile .dropdown-item {
  padding: 0.5rem 1.5rem;
}

[data-container="boxed"] .search-area {
  display: none !important;
}

.nav-label {
  margin: 0.625rem 1.875rem 0;
  padding: 1.5625rem 0 0.625rem;
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 0.05rem;
  border-top: 0.0625rem solid #eaeaea;
  color: #999999;
}



.nav-label.first {
  border: 0rem;
  margin-top: 0rem;
}

.header-profile2 .nav-link {
  padding: 0.5rem 1.6rem;
}

.header-profile2 .sidebar-info small, .header-profile2 .sidebar-info .small {
  font-size: 0.875rem;
}

.nav-badge {
  position: absolute;
  right: 2.8125rem;
  top: 0.625rem;
}

.content-body {
  margin-left: var(--dz-sidebar-width);
  z-index: 0;
  transition: all .2s ease;
}

.bell img {
  -webkit-animation: ring 8s .7s ease-in-out infinite;
  -webkit-transform-origin: 50% 0.25rem;
  -moz-animation: ring 8s .7s ease-in-out infinite;
  -moz-transform-origin: 50% 0.25rem;
  animation: ring 8s .7s ease-in-out infinite;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }

  1% {
    -webkit-transform: rotateZ(30deg);
  }

  3% {
    -webkit-transform: rotateZ(-28deg);
  }

  5% {
    -webkit-transform: rotateZ(34deg);
  }

  7% {
    -webkit-transform: rotateZ(-32deg);
  }

  9% {
    -webkit-transform: rotateZ(30deg);
  }

  11% {
    -webkit-transform: rotateZ(-28deg);
  }

  13% {
    -webkit-transform: rotateZ(26deg);
  }

  15% {
    -webkit-transform: rotateZ(-24deg);
  }

  17% {
    -webkit-transform: rotateZ(22deg);
  }

  19% {
    -webkit-transform: rotateZ(-20deg);
  }

  21% {
    -webkit-transform: rotateZ(18deg);
  }

  23% {
    -webkit-transform: rotateZ(-16deg);
  }

  25% {
    -webkit-transform: rotateZ(14deg);
  }

  27% {
    -webkit-transform: rotateZ(-12deg);
  }

  29% {
    -webkit-transform: rotateZ(10deg);
  }

  31% {
    -webkit-transform: rotateZ(-8deg);
  }

  33% {
    -webkit-transform: rotateZ(6deg);
  }

  35% {
    -webkit-transform: rotateZ(-4deg);
  }

  37% {
    -webkit-transform: rotateZ(2deg);
  }

  39% {
    -webkit-transform: rotateZ(-1deg);
  }

  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }

  100% {
    -webkit-transform: rotateZ(0);
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }

  1% {
    -moz-transform: rotate(30deg);
  }

  3% {
    -moz-transform: rotate(-28deg);
  }

  5% {
    -moz-transform: rotate(34deg);
  }

  7% {
    -moz-transform: rotate(-32deg);
  }

  9% {
    -moz-transform: rotate(30deg);
  }

  11% {
    -moz-transform: rotate(-28deg);
  }

  13% {
    -moz-transform: rotate(26deg);
  }

  15% {
    -moz-transform: rotate(-24deg);
  }

  17% {
    -moz-transform: rotate(22deg);
  }

  19% {
    -moz-transform: rotate(-20deg);
  }

  21% {
    -moz-transform: rotate(18deg);
  }

  23% {
    -moz-transform: rotate(-16deg);
  }

  25% {
    -moz-transform: rotate(14deg);
  }

  27% {
    -moz-transform: rotate(-12deg);
  }

  29% {
    -moz-transform: rotate(10deg);
  }

  31% {
    -moz-transform: rotate(-8deg);
  }

  33% {
    -moz-transform: rotate(6deg);
  }

  35% {
    -moz-transform: rotate(-4deg);
  }

  37% {
    -moz-transform: rotate(2deg);
  }

  39% {
    -moz-transform: rotate(-1deg);
  }

  41% {
    -moz-transform: rotate(1deg);
  }

  43% {
    -moz-transform: rotate(0);
  }

  100% {
    -moz-transform: rotate(0);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }

  1% {
    transform: rotate(30deg);
  }

  3% {
    transform: rotate(-28deg);
  }

  5% {
    transform: rotate(34deg);
  }

  7% {
    transform: rotate(-32deg);
  }

  9% {
    transform: rotate(30deg);
  }

  11% {
    transform: rotate(-28deg);
  }

  13% {
    transform: rotate(26deg);
  }

  15% {
    transform: rotate(-24deg);
  }

  17% {
    transform: rotate(22deg);
  }

  19% {
    transform: rotate(-20deg);
  }

  21% {
    transform: rotate(18deg);
  }

  23% {
    transform: rotate(-16deg);
  }

  25% {
    transform: rotate(14deg);
  }

  27% {
    transform: rotate(-12deg);
  }

  29% {
    transform: rotate(10deg);
  }

  31% {
    transform: rotate(-8deg);
  }

  33% {
    transform: rotate(6deg);
  }

  35% {
    transform: rotate(-4deg);
  }

  37% {
    transform: rotate(2deg);
  }

  39% {
    transform: rotate(-1deg);
  }

  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}

.dlabnav {
  width: var(--dz-sidebar-width);
  padding-bottom: 0;
  height: calc(100% - var(--dz-header-height));
  position: var(--dz-sidebar-pos);
  top: var(--dz-header-height);
  padding-top: 0;
  z-index: 6;
  background-color: var(--sidebar-bg);
  border-right: 1px solid var(--border);
  transition: all .2s ease;
  box-shadow: 0rem 0.9375rem 1.875rem 0rem rgba(0, 0, 0, 0.02);
}


.dlabnav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.dlabnav .metismenu {
  display: flex;
  flex-direction: var(--dz-side-menu-direction);
  padding-top: 0.9375rem;
}

.dlabnav .metismenu.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
}

.dlabnav .metismenu>li {
  display: flex;
  flex-direction: column;
  margin: 5px 0;
  padding-right: 5px;
}

.dlabnav .metismenu>li a>i {
  font-size: var(--dz-side-ico-size);
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: 0;
  height: var(--dz-side-ico-height);
  width: var(--dz-side-ico-width);
  text-align: center;
  margin-right: 1.25rem;
  border-radius: 0.125rem;
  line-height: 1;
  border-radius: 0.125rem;
}

@media only screen and (max-width: 35.9375rem) {
  .dlabnav .metismenu>li a>i {
    margin-right: 1rem;
  }
}

.dlabnav .metismenu>li>a {
  font-weight: 500;
  display: inline-block;
  padding: var(--dz-side-nav-padding);
  font-size: var(--dz-side-nav-size);
  color: var(--text-gray);
}

.dlabnav .metismenu>li>a i {
  color: #96A0AF;
}

.dlabnav .metismenu>li>a svg {
  max-width: 1.5rem;
  max-height: 1.5rem;
  height: 100%;
  margin-right: 0.3125rem;
  margin-top: -0.1875rem;
  color: var(--primary);
}

.dlabnav .metismenu>li>a g [fill] {
  fill: #8088a1;
}

.dlabnav .metismenu>li:hover>a, .dlabnav .metismenu>li:focus>a {
  color: var(--primary);
}

.dlabnav .metismenu>li:hover>a g [fill], .dlabnav .metismenu>li:focus>a g [fill] {
  fill: var(--primary);
}

.dlabnav .metismenu>li.mm-active>a {
  font-weight: 600;
  background: var(--rgba-primary-1);
  border-bottom-right-radius: 2.5rem;
  border-top-right-radius: 0.5rem;
}

.dlabnav .metismenu>li.mm-active>a i {
  font-weight: 900;
}

.dlabnav .metismenu>li.mm-active>a g [fill] {
  fill: var(--primary);
}

.dlabnav .metismenu>li.mm-active>a:after {
  border-top: 0.3125rem solid var(--primary);
  border-left: 0.3125rem solid var(--primary);
}

.dlabnav .metismenu li {
  position: relative;
}

.dlabnav .metismenu ul {

  position: relative;
  z-index: 1;
  padding: 0.5rem 0;
}

.dlabnav .metismenu ul a {
  position: relative;
  font-size: var(--dz-side-sub_nav-size);
  padding: var(--dz-side-sub_nav-padding);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.dlabnav .metismenu ul a:hover, .dlabnav .metismenu ul a:focus, .dlabnav .metismenu ul a.mm-active {
  text-decoration: none;
  color: var(--primary);
}

.dlabnav .metismenu ul a:before {
  position: absolute;
  content: none;
  height: 0.125rem;
  width: 0.5rem;
  top: 50%;
  left: 1.75rem;
  border-radius: 3.75rem;
  transform: translateY(-50%);
}

@media only screen and (max-width: 84.375rem) {
  .dlabnav .metismenu ul a:before {
    left: 2.8125rem;
  }
}

.dlabnav .metismenu a {
  position: relative;
  display: block;
  padding: 0.625rem 1.875rem;
  outline-width: 0;
  color: var(--text-gray);
  text-decoration: none;
}

@media only screen and (max-width: 47.9375rem) {
  .dlabnav .metismenu a {
    padding: 0.625rem 1.25rem;
  }
}

.dlabnav .metismenu .has-arrow:after {
  border-top: 0.3125rem solid #C8C8C8;
  border-left: 0.3125rem solid #C8C8C8;
  border-bottom: 0.3125rem solid transparent;
  border-right: 0.3125rem solid transparent;
  right: 1.875rem;
  top: 48%;
  -webkit-transform: rotate(-225deg) translateY(-50%);
  transform: rotate(-225deg) translateY(-50%);
}

.dlabnav .metismenu .has-arrow[aria-expanded=true]:after,
.dlabnav .metismenu .mm-active>.has-arrow:after {
  -webkit-transform: rotate(-135deg) translateY(-50%);
  transform: rotate(-135deg) translateY(-50%);
}

.dlabnav .main-menu {
  padding: 0.625rem 1.875rem;
}

.dlabnav .copyright {
  display: var(--dz-side-copyright);
  margin-bottom: 2rem;
}

.dlabnav .header-info2 {
  padding: 0.625rem 1.25rem;
  margin-top: 1rem;
  border-radius: 1.25rem;
}

.dlabnav .header-info2 img {
  height: 3.563rem;
  width: 3.563rem;
  border-radius: 0.875rem;
  margin-right: 0.8rem;
}

.dlabnav .header-info2 span {
  font-size: 1.25rem;
  color: #171B1E;
}

@media only screen and (max-width: 87.5rem) {
  .dlabnav .header-info2 span {
    font-size: 0.9rem;
  }
}

.dlabnav .header-info2 i {
  margin-left: 1rem;
  font-size: 1.2rem;
}

@media only screen and (max-width: 87.5rem) {
  .dlabnav .header-info2 {
    padding: 0;
  }
}

.copyright {
  padding: 0 1.875rem;
  color: #9fa4a6;
  margin-top: 1.875rem;
}

.copyright p {
  font-size: 0.875rem;
}

.copyright strong {
  display: block;
  font-size: 0.875rem;
}


.dropdown-menu {
  font-size: inherit;
  border: 0;
  z-index: 2;
  overflow: hidden;
  border-radius: 0.625rem;
  box-shadow: 0 0 3.125rem 0 rgba(82, 63, 105, 0.15);
  margin-top: 0;
}

.dropdown-menu .dropdown-item {
  font-size: 1rem;
  color: #9da1a5;
  padding: 0.5rem 1.75rem;
}

@media only screen and (max-width: 87.5rem) {
  .dropdown-menu .dropdown-item {
    padding: 0.375rem 1rem;
    font-size: 0.875rem;
  }
}

.dropdown-menu .dropdown-item:hover, .dropdown-menu .dropdown-item:focus, .dropdown-menu .dropdown-item:active, .dropdown-menu .dropdown-item.active {
  color: #514e5f;
}

.dropdown-menu .dropdown-item.active, .dropdown-menu .dropdown-item:active {
  color: var(--primary);
  background: var(--rgba-primary-1);
}

[direction="rtl"] .dropdown-menu {
  right: auto !important;
}

.dropdown-menu.show {
  right: 0;
}

.dropdown-toggle-split {
  padding: 0 0.625rem;
  opacity: 0.85;
}

.dropdown-toggle-split:after {
  margin-left: 0 !important;
}

.dropdown-toggle-split:active, .dropdown-toggle-split:focus, .dropdown-toggle-split:hover {
  opacity: 1;
}

.dropright .dropdown-toggle::after {
  content: none;
}

.custom-dropdown {
  display: inline-block;
  margin-bottom: 1rem;
}

.custom-dropdown .dropdown-menu {
  border: 0rem;
  min-width: 10rem;
}

.card-action .custom-dropdown {
  margin: 0rem;
  background: var(--rgba-primary-1);
}

.card-action .custom-dropdown.show, .card-action .custom-dropdown:focus, .card-action .custom-dropdown:hover {
  background: var(--primary);
  color: #fff;
}

.card-action .custom-dropdown i {
  display: inline-block;
  padding-top: 0.5625rem;
}

.dropdown .dropdown-dots {
  position: relative;
  height: 0.3125rem;
  width: 0.3125rem;
  background: rgba(49, 42, 42, 0.4);
  border-radius: 0.3125rem;
  display: block;
}

.dropdown .dropdown-dots:after, .dropdown .dropdown-dots:before {
  content: "";
  height: 0.3125rem;
  width: 0.3125rem;
  background: rgba(49, 42, 42, 0.4);
  position: absolute;
  border-radius: 0.3125rem;
}

.dropdown .dropdown-dots:after {
  right: -0.5rem;
}

.dropdown .dropdown-dots:before {
  left: -0.5rem;
}

.dropdown .dropdown-dots.text-white {
  background: rgba(255, 255, 255, 0.7);
}

.dropdown .dropdown-dots.text-white:after, .dropdown .dropdown-dots.text-white:before {
  background: rgba(255, 255, 255, 0.7);
}

.grid-col {
  padding: 0.5rem !important;
  background: #f2f4fa;
}

.row.grid {
  margin-bottom: 1.5rem;
  text-align: center;
}

.row.grid .grid-col:first-child {
  text-align: left;
}

.label {
  display: inline-block;
  text-align: center;
  font-size: 0.75rem;
  padding: .2rem .8rem;
}

.label-fixed {
  width: 7.5rem;
  padding: .6rem 0;
}

.label-fixed-lg {
  width: 9.5rem;
  padding: .6rem 0;
}

.label-big {
  width: 16.8rem;
  font-size: 1.4rem;
  padding: 1.1rem 0;
}

.label-xl {
  width: 10.5rem;
  padding: 1.1rem 0;
  font-size: 1.5rem;
}

.label-lg {
  width: 9.5rem;
  padding: 1.1rem 0;
}

.label-md {
  width: 8.5rem;
  padding: 1.1rem 0;
}

.label-sm {
  width: 7.5rem;
  padding: 1.1rem 0;
}

.label-default {
  background: #ADB6C7;
}

.label-primary {
  background: var(--primary);
  color: #fff;
}

.label-secondary {
  background: #FFA7D7;
  color: #fff;
}

.label-info {
  background: #D653C1;
  color: #fff;
}

.label-success {
  background: #09BD3C;
  color: #fff;
}

.label-warning {
  background: #FFBF00;
  color: #fff;
}

.label-danger {
  background: #FC2E53;
  color: #fff;
}

.label-light {
  background: #c8c8c8;
  color: #000;
}

.label-dark {
  background: #312a2a;
  color: #fff;
}

code {
  word-break: break-word;
  padding: 0.125rem 0.3125rem;
  border-radius: 0.1875rem;
  background: #fed4dc;
  color: #FC2E53;
}

.heading-labels {
  color: #333333;
}

.heading-labels>* {
  margin-bottom: .8rem;
}

.heading-labels h1 .label, .heading-labels .h1 .label {
  font-size: 1.125rem;
  font-weight: normal;
  padding: .4rem .9rem;
}

.heading-labels h2 .label, .heading-labels .h2 .label {
  font-size: 1rem;
  font-weight: normal;
  padding: 0.3rem .9rem;
}

.heading-labels h3 .label, .heading-labels .h3 .label {
  font-size: 0.875rem;
  font-weight: normal;
}

.heading-labels h4 .label, .heading-labels .h4 .label {
  font-size: 0.75rem;
  font-weight: normal;
}

.heading-labels h5 .label, .heading-labels .h5 .label {
  font-size: 0.75rem;
  font-weight: normal;
}

.heading-labels h6 .label, .heading-labels .h6 .label {
  font-size: 0.75rem;
  font-weight: normal;
}

.list-group-item {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid var(--border);
  padding: 1rem 1.5rem;
}

.list-group-item.active {
  background-color: var(--primary);
  border-color: var(--primary);
}



.list-group-item.disabled, .list-group-item:disabled {
  color: #fff;
  background-color: #FFA7D7;
  border-color: #FFA7D7;
}

[class*="bg-"] .list-group-item {
  border-color: rgba(255, 255, 255, 0.05);
}

.bg-warning .list-group-item {
  border-color: rgba(0, 0, 0, 0.05);
}

.list-style-1.block>li {
  display: block;
}

.list-style-1>li {
  border-bottom: 1px dashed var(--border);
  padding: .75rem 0;
  display: flex;
  align-items: center;
}

.media img {
  border-radius: 0.1875rem;
}

.vertical-card__menu:hover {
  box-shadow: none;
}

.vertical-card__menu--image {
  text-align: center;
}

.vertical-card__menu--image img {
  width: 100%;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.vertical-card__menu--status.closed {
  background: #FC2E53;
}

.vertical-card__menu--status.closed:after {
  border-top: 0.625rem solid #FC2E53;
}

.vertical-card__menu--status.closed .ribbon-curve {
  border-top: 0.6875rem solid #FC2E53;
  border-bottom: 0.625rem solid #FC2E53;
}

.vertical-card__menu--desc p {
  margin-bottom: 0.625rem;
}

.vertical-card__menu--price {
  font-size: 1.25rem;
  margin: 0;
  font-weight: 700;
}

.vertical-card__menu--rating {
  font-size: 0.75rem;
}

.vertical-card__menu--title {
  font-weight: 700;
}

.vertical-card__menu--button button {
  font-size: 0.75rem;
}

.vertical-card__menu .card-footer {
  padding: 0.9375rem 1.25rem;
  background-color: #e6e6e6;
  border-top: none;
}

@media only screen and (min-width: 75rem) and (max-width: 90rem) {
  .vertical-card__menu .card-footer {
    padding: 0.9375rem;
  }
}

.vertical-card__menu .card-footer span {
  color: #312a2a;
  margin-right: 0.3125rem;
}

.horizontal-card__menu {
  box-shadow: 0 0 0.4375rem rgba(173, 173, 173, 0.32);
  display: flex;
  flex-direction: row;
  display: block;
}

.horizontal-card__menu .card-body {
  padding: 0;
  padding: 1.25rem 1.875rem;
}

.horizontal-card__menu--image {
  flex-basis: 30%;
  max-width: 30%;
  max-width: unset;
  flex-basis: 100%;
}

.horizontal-card__menu--image img {
  height: 100%;
  width: 100%;
}

.horizontal-card__menu--title {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0.3125rem;
}

.horizontal-card__menu--fav {
  margin-right: 0.5rem;
}

.horizontal-card__menu--price {
  margin: 0;
  font-size: 1rem;
  font-weight: 700;
}

.horizontal-card__menu--rating {
  font-size: 0.625rem;
}

.horizontal-card__menu--footer {
  margin-top: 0.625rem;
}

.prev_price {
  text-decoration: line-through;
  font-size: 80%;
  opacity: 0.5;
}

.modal-header {
  padding: 1rem 1.875rem;
}

.modal-header .close {
  padding: 0.875rem 1.815rem;
  margin: 0;
  position: absolute;
  right: 0;
  float: none;
  top: 0;
  font-size: 1.875rem;
  font-weight: 100;
}

.modal-body {
  padding: 1.875rem;
}

.modal-footer {
  padding: 1rem 1.875rem;
}

.modal-content {
  border-radius: 0.625rem;
}

.modal-backdrop {
  z-index: 10 !important;
}

.pagination .page-item .page-link:hover {
  background: var(--primary);
  border-color: var(--primary);
  color: #fff;
}

.pagination .page-item.active .page-link {
  background: var(--primary);
  border-color: var(--primary);
  color: #fff;
}

.pagination {
  margin-bottom: 1.25rem;
}

.pagination .page-item.page-indicator .page-link {
  padding: .65rem .8rem;
  font-size: 0.875rem;
}

.pagination .page-item.page-indicator:hover .page-link {
  color: #312a2a;
}

.pagination .page-item .page-link {
  text-align: center;
  z-index: 1;
  padding: 0.55rem 1rem;
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.15);
  color: #312a2a;
  border: 0.0625rem solid #e6e6e6;
}



.pagination .page-item .page-link:hover i, .pagination .page-item .page-link span {
  color: #fff;
}

.pagination .page-item .page-link:focus {
  outline: 0;
  box-shadow: none;
}

.pagination .page-item .page-link:hover {
  background: var(--primary);
  color: #fff;
  border-color: var(--primary);
}

.pagination .page-item.active .page-link {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
  box-shadow: 0 0.625rem 1.25rem 0rem var(--rgba-primary-2);
}


.pagination .page-item .page-link {
  color: #312a2a;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.pagination .page-item:last-child .page-link {
  margin-right: 0;
}

[direction="rtl"] .pagination .page-item:first-child .page-link {
  margin-right: 0;
}

.pagination.no-bg li:not(.page-indicator):not(.active) .page-link {
  background: transparent;
  border-color: transparent;
}

.pagination.no-bg.pagination-primary li:not(.page-indicator):not(.active):hover .page-link {
  background: var(--primary);
  border-color: var(--primary);
}


.pagination-info .page-item:hover .page-link, .pagination-info .page-item.active .page-link {
  background: #D653C1;
  border-color: #D653C1;
  color: #fff;
  box-shadow: 0 0.625rem 1.25rem 0rem rgba(214, 83, 193, 0.2);
}

.pagination-warning .page-item .page-link {
  background: #fff9e6;
  border-color: #fff9e6;
  color: #FFBF00;
}

.pagination-warning .page-item:hover .page-link, .pagination-warning .page-item.active .page-link {
  background: #FFBF00;
  border-color: #FFBF00;
  color: #fff;
  box-shadow: 0 0.625rem 1.25rem 0rem rgba(255, 191, 0, 0.2);
}

.pagination-gutter .page-item {
  margin-right: 0.4375rem;
}

.pagination-gutter .page-item .page-link {
  border-radius: 0.625rem !important;
}

.pagination-circle .page-item {
  margin-right: 0.4375rem;
}

.pagination-circle .page-item .page-link, .pagination-circle .page-item .page-link {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  line-height: 2.5rem;
  border-radius: 50% !important;
  padding: 0;
}

.pagination-circle .page-item.page-indicator .page-link {
  width: 2.5rem;
  border: 1px solid var(--primary);
  border-radius: 22px !important;
  line-height: 1.3rem;
  height: 2.5rem;
  background: var(--rgba-primary-1);
  color: var(--primary);
}

.pagination-circle .page-item.page-indicator .page-link:hover {
  color: #fff;
  background: var(--primary);
}

.pagination.pagination-md .page-item .page-link {
  width: 1.875rem;
  height: 1.875rem;
  line-height: 1.875rem;
  font-size: 0.875rem;
}

.pagination.pagination-sm .page-item.page-indicator .page-link {
  font-size: 0.75rem;
}

.pagination.pagination-sm .page-item .page-link {
  padding: 0;
  width: 1.875rem;
  height: 1.875rem;
  line-height: 1.875rem;
  font-size: 0.875rem;
}

.pagination.pagination-xs .page-item.page-indicator .page-link {
  font-size: 0.625rem;
}

.pagination.pagination-xs .page-item .page-link {
  padding: 0;
  width: 1.5625rem;
  height: 1.5625rem;
  line-height: 1.5625rem;
  font-size: 0.75rem;
}

.popover {
  border: 0.125rem solid #886CC0;
  min-width: 13.125rem;
  box-shadow: 0 0 1.875rem 0 rgba(0, 0, 0, 0.1);
}



.popover-header {
  background: #886CC0;
  color: #fff;
  font-weight: 300;
  border-radius: 0;
}

.popover-header::before {
  border-bottom: 0 !important;
}

.popover-body {
  font-size: 0.75rem;
}



@media only screen and (max-width: 47.9375rem) {
  .popover {
    z-index: 1;
  }
}

.bootstrap-popover-wrapper .bootstrap-popover:not(:last-child) {
  margin-right: 0.5rem;
}

.bootstrap-popover-wrapper .bootstrap-popover {
  margin-bottom: .5rem;
}

.bootstrap-popover-wrapper .bootstrap-popover button:hover, .bootstrap-popover-wrapper .bootstrap-popover button:focus {
  background: var(--primary);
  color: #fff;
  box-shadow: none;
}

.bs-popover-top .arrow::after, .bs-popover-auto[data-popper-placement^="top"] .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
  bottom: 0rem;
  border-top-color: #FFA7D7;
}

.bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
  right: 0rem;
  border-left-color: #FFA7D7;
}

.bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
  left: 0rem;
  border-right-color: #FFA7D7;
}

.bs-popover-bottom .arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: 0rem;
  border-bottom-color: #FFA7D7;
}

.note-popover {
  display: none;
}

.progress-bar {
  background-color: var(--primary);
}

.progress-bar-primary {
  background-color: var(--primary);
}

.progress-bar-success {
  background-color: #09BD3C;
}

.progress-bar-info {
  background-color: #D653C1;
}

.progress-bar-danger {
  background-color: #FC2E53;
}

.progress-bar-warning {
  background-color: #FFBF00;
}

.progress-bar-pink {
  background-color: #e83e8c;
}

.progress {
  height: 8px;
  background-color: #f6f6f6;
  overflow: hidden;
}

.progress-bar {
  border-radius: 0.625rem;
}

.progress-vertical {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 2rem;
  min-height: 17rem;
  position: relative;
}

@media (max-width: 767.98px) {
  .progress-vertical {
    margin-right: 1rem;
  }
}

.progress-vertical-bottom {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 2rem;
  min-height: 17rem;
  position: relative;
  transform: rotate(180deg);
}

@media (max-width: 767.98px) {
  .progress-vertical-bottom {
    margin-right: 1rem;
  }
}

.progress-animated {
  animation-duration: 5s;
  animation-name: myanimation;
  transition: all 5s ease 0s;
}

@keyframes myanimation {
  0% {
    width: 0;
  }
}

@keyframes myanimation {
  0% {
    width: 0;
  }
}

.ribbon {
  position: absolute;
  z-index: 1;
  text-transform: uppercase;
}

.ribbon__one {
  top: 0.9375rem;
  left: -0.6875rem;
  min-height: 1.25rem;
  min-width: 3.25rem;
  text-align: center;
  padding: 0.1875rem 0.625rem;
  background: #3AB54B;
  color: #fff;
  font-size: 0.625rem;
}

.ribbon__one::after {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 0.625rem solid #239132;
  border-left: 0.6875rem solid transparent;
  left: 0;
  content: "";
  bottom: -0.625rem;
}

.ribbon__two {
  width: 3.125rem;
  height: 3.125rem;
  display: inline-block;
  background: #FFBF00;
  line-height: 3.125rem;
  text-align: center;
  font-size: 1rem;
  color: #fff;
  right: 0.9375rem;
  top: 0.9375rem;
  border-radius: 0.1875rem;
}

.ribbon__three {
  left: -1.875rem;
  top: 0.875rem;
  width: 6.875rem;
  height: 1.5625rem;
  background-color: #FC2E53;
  clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
  transform: rotate(-45deg);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
}

.ribbon__four {
  left: 0.5rem;
  top: -0.5rem;
  width: 6.875rem;
  height: 3.125rem;
  background-color: var(--primary);
  z-index: auto;
  color: #fff;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ribbon__four:after {
  right: -0.3125rem;
}

.ribbon__four:before {
  left: -0.3125rem;
}

.ribbon__four:after, .ribbon__four:before {
  z-index: -1;
  background-color: var(--rgba-primary-1);
  top: 0.1875rem;
  transform: rotate(45deg);
  content: "";
  height: 0.625rem;
  width: 0.625rem;
  position: absolute;
}

.ribbon__five {
  left: -1.875rem;
  top: 0.625rem;
  width: 6.875rem;
  height: 1.875rem;
  background-color: var(--primary);
  transform: rotate(-45deg);
  font-size: 0.75rem;
  color: #fff;
  padding-bottom: 0.3125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.ribbon__five::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 3.125rem 3.125rem 3.125rem;
  border-color: transparent transparent var(--primary) transparent;
  left: 0.25rem;
  top: -2.8125rem;
}

.ribbon__six {
  left: 0;
  top: 1.125rem;
  width: 6.875rem;
  height: 2.375rem;
  background-color: var(--primary);
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 0, 85% 50%, 100% 100%, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0%, 100% 0, 85% 50%, 100% 100%, 100% 100%, 0 100%);
  display: flex;
  font-size: 1.25rem;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.ribbon-curve {
  position: absolute;
  top: 0;
  right: -0.375rem;
  width: 0.625rem;
  height: 0.6875rem;
  border-top: 0.6875rem solid #3AB54B;
  border-bottom: 0.625rem solid #3AB54B;
  border-right: 0.3125rem solid transparent;
}

.jsgrid-grid-body::-webkit-scrollbar,
.dataTables_scrollBody::-webkit-scrollbar,
.table-responsive::-webkit-scrollbar {
  background-color: #f5f5f5;
  width: 0.5rem;
  height: 0.5rem;
}

.jsgrid-grid-body::-webkit-scrollbar-track,
.dataTables_scrollBody::-webkit-scrollbar-track,
.table-responsive::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
  border-radius: 0.625rem;
  background-color: #f5f5f5;
}

.jsgrid-grid-body::-webkit-scrollbar-thumb,
.dataTables_scrollBody::-webkit-scrollbar-thumb,
.table-responsive::-webkit-scrollbar-thumb {
  border-radius: 0.625rem;
  -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
  background-color: var(--primary);
}

.multi-steps>li.is-active:before, .multi-steps>li.is-active~li:before {
  content: counter(stepNum);
  font-family: inherit;
  font-weight: 400;
}

.multi-steps>li.is-active:after, .multi-steps>li.is-active~li:after {
  background-color: #e6e6e6;
}

.multi-steps {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.multi-steps>li {
  counter-increment: stepNum;
  text-align: center;
  display: table-cell;
  position: relative;
  color: var(--primary);
  font-size: 0.75rem;
}

.multi-steps>li:before {
  content: '\2713';
  display: block;
  margin: 0 auto 0.25rem;
  background-color: #fff;
  width: 1.5625rem;
  height: 1.5625rem;
  line-height: 1.375rem;
  text-align: center;
  font-weight: bold;
  position: relative;
  z-index: 1;
  width: 1.5625rem;
  height: 1.5625rem;
  line-height: 1.3125rem;
  border-width: 0.125rem;
  border-style: solid;
  border-color: var(--primary);
  border-radius: 0.3125rem;
}

.multi-steps>li:after {
  content: '';
  height: 0.125rem;
  width: 100%;
  background-color: var(--primary);
  position: absolute;
  top: 0.75rem;
  left: 50%;
  top: 0.75rem;
}

[direction="rtl"] .multi-steps>li:after {
  left: auto;
  right: 50%;
}

.multi-steps>li:last-child:after {
  display: none;
}

.multi-steps>li.is-active:before {
  background-color: #fff;
  border-color: var(--primary);
}

.multi-steps>li.is-active~li {
  color: #9da1a5;
}

.multi-steps>li.is-active~li:before {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
}

.nav-pills .nav-link {
  border-radius: 0.625rem;
  padding: 0.75rem 1.25rem;
}

.default-tab .nav-link {
  background: transparent;
  border-radius: 0rem;
  font-weight: 500;
}

.default-tab .nav-link i {
  display: inline-block;
  transform: scale(1.5);
}

.default-tab .nav-link:focus, .default-tab .nav-link:hover, .default-tab .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff #ebeef6;
  border-radius: 0.625rem 0.625rem 0 0;
  color: var(--primary);
}


.custom-tab-1 .nav-link {
  background: transparent;
  border-radius: 0rem;
  font-weight: 500;
  border-bottom: 0.1875rem solid transparent;
  border-width: 0 0rem 0.1875rem 0;
}

.custom-tab-1 .nav-link i {
  display: inline-block;
  transform: scale(1.5);
  color: var(--primary);
}

.custom-tab-1 .nav-link:focus, .custom-tab-1 .nav-link:hover, .custom-tab-1 .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: var(--primary);
  border-radius: 0;
  color: var(--primary);
  border-width: 0 0rem 0.1875rem 0;
}



.nav-pills.light .nav-link.active,
.nav-pills.light .show>.nav-link {
  background: var(--rgba-primary-1);
  color: var(--primary);
  box-shadow: none;
}


.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: var(--primary);
  box-shadow: 0 0 1.25rem 0 var(--rgba-primary-2);
}

.tooltip-wrapper button:not(:last-child) {
  margin-right: 0.5rem;
}

.tooltip-wrapper button:hover {
  background: var(--primary);
  color: #fff;
}

.tooltip-wrapper button {
  margin-bottom: .5rem;
}

.tooltip-wrapper button:focus {
  box-shadow: none;
}

.tooltip-inner {
  border-radius: 0;
  background: #333333;
  font-size: 0.75rem;
  font-weight: 300;
  padding: 0.35rem 0.7rem;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #333333;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #333333;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #333333;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #333333;
}

.widget-timeline .timeline {
  list-style: none;
  position: relative;
}

.widget-timeline .timeline:before {
  top: 1.25rem;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 0.1875rem;
  left: 0.5625rem;
  margin-right: -0.0938rem;
  background: #c8c8c8;
}



[direction="rtl"] .widget-timeline .timeline:before {
  left: auto;
  right: 0.5625rem;
  margin-right: auto;
  margin-left: -0.0938rem;
}

.widget-timeline .timeline>li {
  margin-bottom: 0.9375rem;
  position: relative;
}

.widget-timeline .timeline>li:before {
  content: " ";
  display: table;
}

.widget-timeline .timeline>li:after {
  content: " ";
  display: table;
  clear: both;
}

.widget-timeline .timeline>li>.timeline-panel {
  border-radius: 0.625rem;
  padding: 0.9375rem 1.25rem;
  position: relative;
  display: block;
  margin-left: 2.5rem;
  border-width: 0.0625rem;
  border-style: solid;
}

.widget-timeline .timeline>li>.timeline-panel span {
  font-size: 0.75rem;
  display: block;
  margin-bottom: 0.3125rem;
  opacity: 0.8;
  letter-spacing: 0.0625rem;
}

.widget-timeline .timeline>li>.timeline-panel p {
  font-size: 0.875rem;
}

.widget-timeline .timeline>li>.timeline-panel:after {
  content: "";
  width: 0.625rem;
  height: 0.625rem;
  background: inherit;
  border-color: inherit;
  border-style: solid;
  border-width: 0rem 0rem 0.0625rem 0.0625rem;
  display: block;
  position: absolute;
  left: -0.3125rem;
  transform: rotate(45deg);
  top: 0.9375rem;
}

.widget-timeline .timeline>li>.timeline-badge {
  border-radius: 50%;
  height: 1.375rem;
  left: 0;
  position: absolute;
  top: 0.625rem;
  width: 1.375rem;
  border-width: 0.125rem;
  border-style: solid;
  background: #fff;
  padding: 0.25rem;
}

.widget-timeline .timeline>li>.timeline-badge:after {
  content: "";
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 100%;
  display: block;
}

[direction="rtl"] .widget-timeline .timeline>li>.timeline-badge {
  left: auto;
  right: 1.1875rem;
}

.widget-timeline .timeline-body>p {
  font-size: 0.75rem;
}

.widget-timeline .timeline-badge.primary {
  border-color: var(--rgba-primary-1);
}



.widget-timeline .timeline-badge.primary:after {
  background-color: var(--primary);
  box-shadow: 0 0.3125rem 0.625rem 0 var(--rgba-primary-2);
}

.widget-timeline .timeline-badge.primary+.timeline-panel {
  background: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
}



.widget-timeline .timeline-badge.success {
  border-color: #d7fde2;
}

.widget-timeline .timeline-badge.success:after {
  background-color: #09BD3C !important;
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(9, 189, 60, 0.2);
}

.widget-timeline .timeline-badge.success+.timeline-panel {
  background: #d7fde2;
  border-color: #d7fde2;
}


.widget-timeline.style-1 .timeline-panel {
  background: transparent;
}

.widget-timeline.style-1 .timeline .timeline-badge.timeline-badge+.timeline-panel {
  background: transparent !important;
  border-style: solid;
  border-width: 0 0 0 0.3125rem;
  border-radius: 0;
  padding: 0.3125rem 0.625rem 0.3125rem 0.9375rem;
}

.widget-timeline.style-1 .timeline .timeline-badge.timeline-badge+.timeline-panel:after {
  border: 0;
  left: -0.5625rem;
  width: 0.4375rem;
  height: 0.4375rem;
}

.widget-timeline.style-1 .timeline .timeline-badge.primary+.timeline-panel {
  border-color: var(--primary);
}

.widget-timeline.style-1 .timeline .timeline-badge.primary+.timeline-panel:after {
  background: var(--primary);
}

.widget-timeline.style-1 .timeline .timeline-badge.success+.timeline-panel {
  border-color: #09BD3C;
}

.widget-timeline.style-1 .timeline .timeline-badge.success+.timeline-panel:after {
  background: #09BD3C;
}

.widget-timeline.style-1 .timeline .timeline-badge.warning+.timeline-panel {
  border-color: #FFBF00;
}

.widget-timeline.style-1 .timeline .timeline-badge.warning+.timeline-panel:after {
  background: #FFBF00;
}

.widget-timeline.style-1 .timeline .timeline-badge.danger+.timeline-panel {
  border-color: #FC2E53;
}

.widget-timeline.style-1 .timeline .timeline-badge.danger+.timeline-panel:after {
  background: #FC2E53;
}

.widget-timeline.style-1 .timeline .timeline-badge.info+.timeline-panel {
  border-color: #D653C1;
}

.widget-timeline.style-1 .timeline .timeline-badge.info+.timeline-panel:after {
  background: #D653C1;
}

.widget-timeline.style-1 .timeline .timeline-badge.dark+.timeline-panel {
  border-color: #312a2a;
}

.widget-timeline.style-1 .timeline .timeline-badge.dark+.timeline-panel:after {
  background: #312a2a;
}

#chart_widget_4 {
  height: 15.9375rem !important;
}

#chart_widget_5 .ct-series-a .ct-line,
#chart_widget_5 .ct-series-a .ct-point {
  stroke: #46ffc8;
}

#chart_widget_5 .ct-line {
  stroke-width: 0.0625rem;
}

#chart_widget_5 .ct-point {
  stroke-width: 0.125rem;
}

#chart_widget_5 .ct-series-a .ct-area {
  fill: #20dea6;
}

#chart_widget_5 .ct-area {
  fill-opacity: 1;
}

#chart_widget_6 .ct-series-a .ct-line,
#chart_widget_6 .ct-series-a .ct-point {
  stroke: #D653C1;
}

#chart_widget_6 .ct-line {
  stroke-width: 0.125rem;
}

#chart_widget_6 .ct-point {
  stroke-width: 0.3125rem;
}

#chart_widget_6 .ct-series-a .ct-area {
  fill: #D653C1;
}

#chart_widget_6 .ct-area {
  fill-opacity: .5;
}

#chart_widget_8 {
  height: 15.9375rem;
}

#chart_widget_8 .ct-series-a .ct-line,
#chart_widget_8 .ct-series-a .ct-point {
  stroke: #D653C1;
}

#chart_widget_8 .ct-line {
  stroke-width: 0.125rem;
}

#chart_widget_8 .ct-point {
  stroke-width: 0.3125rem;
}

#chart_widget_8 .ct-series-a .ct-area {
  fill: #D653C1;
}

#chart_widget_8 .ct-area {
  fill-opacity: .5;
}

#chart_widget_9, #chart_widget_10 {
  height: 15.625rem !important;
}

#chart_widget_11 .ct-slice-donut, #chart_widget_12 .ct-slice-donut, #chart_widget_13 .ct-slice-donut {
  stroke-width: 1.5625rem !important;
}

#chart_widget_11 {
  height: 16.875rem !important;
}

#chart_widget_17 {
  height: 9.375rem !important;
}

.chart_widget_tab_one .nav-link {
  border: 0.0625rem solid #ddd;
}

.chart_widget_tab_one .nav-link.active {
  background-color: var(--primary);
  border: 0.0625rem solid var(--primary);
  color: #fff;
}

.chart_widget_tab_one .nav-link.active:hover {
  border: 0.0625rem solid var(--primary);
}

.chart_widget_tab_one .nav-link:hover {
  border: 0.0625rem solid #ddd;
}



.social-icon {
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  border-radius: 0.25rem;
  text-align: center;
  background: #fff;
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
}

.social-icon i {
  color: #fff;
}

.social-icon.youtube {
  background: #FF0000;
}

.social-icon.facebook {
  background: #3b5998;
}

.social-icon.twitter {
  background: #1da1f2;
}

.social-graph-wrapper {
  text-align: center;
  padding: 1.25rem;
  position: relative;
  color: #fff;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.social-graph-wrapper.widget-facebook {
  background-color: #3b5998;
}

.social-graph-wrapper.widget-twitter {
  background-color: #1da1f2;
}

.social-graph-wrapper.widget-linkedin {
  background-color: #007bb6;
}

.social-graph-wrapper.widget-googleplus {
  background-color: #db4439;
}

.social-graph-wrapper .s-icon {
  font-size: 1.5rem;
  position: relative;
  padding: 0 0.625rem;
}

.social-icon2 a {
  border: 0.1rem solid var(--primary);
  display: block;
  padding: 1rem;
  margin-top: 1.5rem;
  margin-bottom: .5rem;
  border-radius: .3rem;
  font-size: 1.6rem;
}

.social-icon2 i {
  font-size: 0.75rem;
  color: var(--primary);
}

.social-icon3 ul li {
  display: inline-block;
}

.social-icon3 ul li a {
  display: block;
}

.social-icon3 ul li a i {
  font-size: 1.4rem;
  padding: 1rem .7rem;
  color: #464a53;
}

.social-icon3 ul li a:hover i {
  color: var(--primary);
}

.social-icons-muted ul li a i {
  color: #89879f;
}

.social-links a {
  padding: .5rem;
}

.widget-stat .media {
  padding: 0rem 0;
  align-items: center;
}

.widget-stat .media>span {
  height: 5.3125rem;
  width: 5.3125rem;
  border-radius: 3.125rem;
  padding: 0.625rem 0.75rem;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #464a53;
  min-width: 5.3125rem;
}

.widget-stat .media .media-body p {

  font-weight: 500;
  font-size: 14px;
  color: #252627;
}


.widget-stat .media .media-body h3, .widget-stat .media .media-body .h3 {
  font-size: 2.5rem;
  font-weight: 600;
  margin: 0;
  line-height: 1.2;
}

.widget-stat .media .media-body h4, .widget-stat .media .media-body .h4 {
  font-size: 1.5rem;
  display: inline-block;
  vertical-align: middle;
  color: #000;
  font-weight: bolder;
}

.widget-stat .media .media-body span {
  margin-left: 0.3125rem;
}

.widget-stat[class*="bg-"] .media>span {
  background-color: rgba(255, 255, 255, 0.25);
  color: #fff;
}

.widget-stat[class*="bg-"] .progress {
  background-color: rgba(255, 255, 255, 0.25) !important;
}

[direction="rtl"] .widget-stat .media .media-body span {
  margin-left: 0;
  margin-right: 0.625rem;
}

.dez-widget-1 .card {
  background: #FFE7DB;
}

.dez-widget-1 .card .card-body p {
  color: #f87533;
}

.dez-widget-1 .icon {
  float: right;
  width: 3.125rem;
  height: 3.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  font-size: 1.75rem;
}

.bgl-primary {
  background: var(--rgba-primary-1) !important;
  border-color: var(--rgba-primary-1) !important;
}

.bgl-danger {
  background: #ffedf0;
  border-color: #ffedf0;
}

.bgl-dangerz {
  background: #ffedf0;
  border-color: #ffedf0;
}

.bgl-sec-tz {
  background: #ede9fa;
  border-color: #ede9fa
}




.bgl-sec-t {
  background: #ede9fa;
  border-color: #ede9fa
}





.bgl-dark {
  background: #e2dede;
  border-color: #e2dede;
}

.bgl-light {
  background: #c8c8c8;
  border-color: #c8c8c8;
}

.overlay-box {
  position: relative;
  z-index: 1;
}

.overlay-box:after {
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  opacity: 0.85;
  background: var(--primary);
  z-index: -1;
}

.rating-bar {
  font-size: 0.8125rem;
}

.tdl-holder {
  margin: 0 auto;
}

.tdl-holder ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.tdl-holder li {
  background-color: #fff;
  border-bottom: 0.0625rem solid #e6e6e6;
  list-style: none none;
  margin: 0;
  padding: 0;
}

.tdl-holder li span {
  margin-left: 2.1875rem;
  font-size: 1rem;
  vertical-align: middle;
  transition: all 0.2s linear;
}

[direction="rtl"] .tdl-holder li span {
  margin-left: auto;
  margin-right: 2.1875rem;
}

.tdl-holder label {
  cursor: pointer;
  display: block;
  line-height: 3.125rem;
  padding-left: 1.5rem;
  position: relative;
  margin: 0 !important;
}

[direction="rtl"] .tdl-holder label {
  padding-left: 0;
  padding-right: 1.5rem;
}

.tdl-holder label:hover {
  background-color: #eef1f6;
  color: #312a2a;
}

.tdl-holder label:hover a {
  color: #FC2E53;
}

.tdl-holder label a {
  color: #fff;
  display: inline-block;
  line-height: normal;
  height: 100%;
  text-align: center;
  text-decoration: none;
  width: 3.125rem;
  -webkit-transition: all .2s linear;
  -moz-transition: all .2s linear;
  -o-transition: all .2s linear;
  transition: all 0.2s linear;
  padding: 1.125rem 0rem;
  font-size: 1.125rem;
  position: absolute;
  right: 0;
}

[direction="rtl"] .tdl-holder label a {
  right: auto;
  left: 0;
}

.tdl-holder input[type=checkbox] {
  cursor: pointer;
  opacity: 0;
  position: absolute;
}

.tdl-holder input[type=checkbox]+i {
  background-color: #fff;
  border: 0.0625rem solid #e7e7e7;
  display: block;
  height: 1.25rem;
  position: absolute;
  top: 0.9375rem;
  width: 1.25rem;
  z-index: 1;
  border-radius: 0rem;
  font-size: 0.6875rem;
  border-radius: 0.25rem;
}

.tdl-holder input[type=checkbox]:checked+i {
  background: var(--primary);
  border-color: transparent;
}

.tdl-holder input[type=checkbox]:checked+i::after {
  content: "\f00c";
  font-family: 'fontAwesome';
  display: block;
  left: 0.125rem;
  position: absolute;
  top: -0.875rem;
  z-index: 2;
  color: #fff;
}

.tdl-holder input[type=checkbox]:checked~span {
  text-decoration: line-through;
  position: relative;
}

.tdl-holder input[type=text] {
  background-color: #fff;
  height: 3.125rem;
  margin-top: 1.8125rem;
  border-radius: 0.1875rem;
}

.tdl-holder input[type=text]::placeholder {
  color: #312a2a;
}

.widget-message p {
  font-size: 0.875rem;
  line-height: 1.5;
}

.picker .picker__frame {
  min-width: 28.125rem;
  max-width: 28.125rem;
  height: 100%;
  top: 0;
  max-height: 100%;
  align-items: center;
  display: flex;
}

.picker .picker__frame .picker__box {
  padding: 1.25rem;
  border: 0;
  box-shadow: 0 0.3125rem 0.625rem rgba(0, 0, 0, 0.1);
}

.picker .picker__frame .picker__box .picker__header {
  margin-top: 0;
}

.picker .picker__frame .picker__box .picker__header .picker__year {
  color: var(--primary);
  font-style: inherit;
  font-size: 1.25rem;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev,
.picker .picker__frame .picker__box .picker__header .picker__nav--next {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  line-height: 2.5rem;
  border-radius: 0.125rem;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev:before,
.picker .picker__frame .picker__box .picker__header .picker__nav--next:before {
  content: none !important;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev:after,
.picker .picker__frame .picker__box .picker__header .picker__nav--next:after {
  font-family: 'Font Awesome 5 Free';
  border: 0;
  margin: 0 auto;
  font-size: 1.375rem;
  font-weight: 900;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev:hover,
.picker .picker__frame .picker__box .picker__header .picker__nav--next:hover {
  background-color: var(--primary);
  color: #fff;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev {
  left: 0;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev:after {
  content: "\f0d9";
}

.picker .picker__frame .picker__box .picker__header .picker__nav--next {
  right: 0;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--next:after {
  content: "\f0da";
}

.picker .picker__frame .picker__box .picker__table .picker__weekday {
  padding: 0.625rem 0;
  font-size: 1rem;
}

.picker .picker__frame .picker__box .picker__table .picker__day {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 3.125rem;
  padding: 0 !important;
  font-size: 1rem;
  line-height: 2.5rem;
  margin: auto;
  border: 0 !important;
}

.picker .picker__frame .picker__box .picker__table .picker__day.picker__day--today:before {
  content: none !important;
}

.picker .picker__frame .picker__box .picker__table .picker__day.picker__day--highlighted {
  border: 0 !important;
  padding: 0;
  background-color: var(--primary);
  color: #fff !important;
}

.picker .picker__frame .picker__box .picker__table .picker__day:hover {
  background-color: var(--primary);
  color: #fff !important;
}

.picker .picker__frame .picker__box .picker__footer .picker__button--today {
  background-color: var(--primary);
  color: #fff !important;
}

.picker .picker__frame .picker__box .picker__footer .picker__button--today:before {
  content: none !important;
}

.picker .picker__frame .picker__box .picker__footer .picker__button--clear,
.picker .picker__frame .picker__box .picker__footer .picker__button--close,
.picker .picker__frame .picker__box .picker__footer .picker__button--today {
  border: 0;
  border-radius: 0.125rem;
  font-size: 1rem;
}

.picker .picker__frame .picker__box .picker__footer .picker__button--clear:hover,
.picker .picker__frame .picker__box .picker__footer .picker__button--close:hover,
.picker .picker__frame .picker__box .picker__footer .picker__button--today:hover {
  background-color: var(--primary);
  color: #fff !important;
}

@media only screen and (max-width: 35.9375rem) {
  .picker .picker__frame {
    min-width: 100% !important;
    max-width: 100% !important;
  }

  .picker .picker__frame .picker__box {
    padding: 0.9375rem;
    margin: 0 0.625rem;
  }
}

.picker .picker__frame .picker__wrap {
  height: auto;
}

.card-list {
  overflow: unset;
  height: calc(100% - 50px);
}

.card-list .card-header {
  padding-top: 0;
  padding-bottom: 0;
}

.card-list .card-header .photo {
  overflow: hidden;
  border-radius: 0.3125rem;
  width: 100%;
}

@media only screen and (max-width: 74.9375rem) {
  .card-list {
    height: calc(100% - 40px);
  }
}

.card-list.list-left {
  margin-top: 0.9375rem;
  margin-left: 0.9375rem;
}

.card-list.list-left .card-header {
  padding-left: 0;
}

.card-list.list-left .card-header .photo {
  margin: -0.9375rem 0.9375rem 0 -0.9375rem;
}

.card-list.list-right {
  margin-top: 0.9375rem;
  margin-right: 0.9375rem;
  text-align: right;
}

.card-list.list-right .card-header {
  padding-right: 0;
}

.card-list.list-right .card-header .photo {
  margin: -0.9375rem -0.9375rem 0 0.9375rem;
}

.card-list.list-right .card-header .photo img {
  width: 100%;
}

.card-list.list-center {
  margin-top: 0.9375rem;
}

.card-list.list-center .card-header .photo {
  margin: -0.9375rem 0 0 0;
}

.card-list .photo img {
  width: 100%;
}

.card-profile .profile-photo {
  margin-top: -1.25rem;
}

.widget-media .timeline .timeline-panel {
  display: flex;
  align-items: center;
  border-bottom: 0.0625rem solid #eaeaea;
  padding-bottom: 0.9375rem;
  margin-bottom: 0.9375rem;
}

.widget-media .timeline .timeline-panel .media {
  width: 3.125rem;
  height: 3.125rem;
  /* background: #eee; */
  border-radius: 0.75rem;
  overflow: hidden;
  font-size: 1.25rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  align-self: start;
}

.widget-media .timeline .timeline-panel .media-primary {
  background: var(--rgba-primary-1);
  color: var(--primary);
}

.widget-media .timeline .timeline-panel .media-body p {
  font-size: 0.875rem;
  line-height: 1.5;
}

.widget-media .timeline .timeline-panel .dropdown {
  align-self: self-end;
  margin-top: 0.3125rem;
}

.widget-media .timeline li:last-child .timeline-panel {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0;
}

.card[class*="bg-"] .timeline .timeline-panel {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.table {
  color: strong;
  color-color: #312a2a;
}

.table th, .table td {
  border-color: var(--border);
  padding: 0.9375rem 0.625rem;
  vertical-align: middle;
}

.table.tr-rounded tr td:first-child, .table.tr-rounded tr th:first-child {
  border-radius: 2.8125rem 0 0 2.8125rem;
}

.table.tr-rounded tr td:last-child, .table.tr-rounded tr th:last-child {
  border-radius: 0 2.8125rem 2.8125rem 0;
}

.table.border-hover tr td {
  border-width: 0.0625rem 0 0.0625rem 0;
  border-bottom: 0.0625rem solid transparent;
  border-color: transparent;
}

.table.border-hover tr td:first-child {
  border-width: 0.0625rem 0 0.0625rem 0.0625rem;
}

.table.border-hover tr td:last-child {
  border-width: 0.0625rem 0.0625rem 0.0625rem 0;
}

.table.border-hover tr:hover td {
  border-color: #e6e6e6;
}

.table.bg-primary-hover th, .table.bg-primary-hover td {
  border: none;
  font-weight: 500;
}

.table.bg-primary-hover td {
  color: #000;
}

.table.bg-primary-hover th {
  color: #6C6C6C;
}

.table.bg-primary-hover tr:hover td, .table.bg-primary-hover tr:hover th {
  background: var(--primary);
  color: #fff;
}

.table.bg-secondary-hover th, .table.bg-secondary-hover td {
  border: none;
  font-weight: 500;
}

.table.bg-secondary-hover td {
  color: #000;
}

.table.bg-secondary-hover th {
  color: #6C6C6C;
}

.table.bg-secondary-hover tr:hover td, .table.bg-secondary-hover tr:hover th {
  background: #FFA7D7;
  color: #fff !important;
}

.table.bg-info-hover th, .table.bg-info-hover td {
  border: none;
  font-weight: 500;
}

.table.bg-info-hover td {
  color: #000;
}

.table.bg-info-hover th {
  color: #6C6C6C;
}

.table.bg-info-hover tr:hover td, .table.bg-info-hover tr:hover th {
  background: #D653C1;
  color: #fff !important;
}

.table.bg-warning-hover th, .table.bg-warning-hover td {
  border: none;
  font-weight: 500;
}

.table.bg-warning-hover td {
  color: #000;
}

.table.bg-warning-hover th {
  color: #6C6C6C;
}

.table.bg-warning-hover tr:hover td, .table.bg-warning-hover tr:hover th {
  background: #FFBF00;
  color: #fff !important;
}

.table.border-no td {
  border: 0;
}

.table.short-one tr td:first-child, .table.short-one tr th:first-child {
  width: 3.75rem;
}

.table thead th {
  border-bottom: 0.0625rem solid var(--border);
  text-transform: capitalize;
  font-size: 1rem;
  color: #000;
  white-space: nowrap;
  font-weight: 500;
  letter-spacing: 0.0313rem;
}


.table tbody tr td {
  vertical-align: middle;
  border-color: #e6e6e6;
  font-size: 14px;
}

.table:not(.table-bordered) thead th {
  border-top: none;
}

.table .thead-primary th {
  background-color: var(--primary);
  color: #fff;
}

.table .thead-info th {
  background-color: #D653C1;
  color: #fff;
}

.table .thead-warning th {
  background-color: #FFBF00;
  color: #fff;
}

.table .thead-danger th {
  background-color: #FC2E53;
  color: #fff;
}

.table .thead-success th {
  background-color: #09BD3C;
  color: #fff;
}

.table.primary-table-bordered {
  border: 0.0625rem solid #e6e6e6;
}



.table.primary-table-bg-hover thead th {
  background-color: #7a5ab8;
  color: #fff;
  border-bottom: none;
}

.table.primary-table-bg-hover tbody tr {
  background-color: #886CC0;
  color: #fff;
  transition: all .1s ease;
}

.table.primary-table-bg-hover tbody tr td, .table.primary-table-bg-hover tbody tr th {
  color: #fff;
}

.table.primary-table-bg-hover tbody tr:hover {
  background-color: #967ec8;
}

.table.primary-table-bg-hover tbody tr:not(:last-child) td, .table.primary-table-bg-hover tbody tr:not(:last-child) th {
  border-bottom: 1px solid #7a5ab8;
}

.table-responsive-tiny {
  min-width: 18.75rem;
}

.table-responsive-sm {
  min-width: 30rem !important;
}

.table-responsive-md {
  min-width: 36rem !important;
}

.table-responsive-lg {
  min-width: 60.9375rem !important;
}

.table-responsive-xl {
  min-width: 70.9375rem !important;
}

.table-primary,
.table-primary>th,
.table-primary>td {
  background-color: var(--rgba-primary-1);
  color: var(--primary);
}

.table.primary-table-bg-hover tbody tr td, .table.primary-table-bg-hover tbody tr th {
  background-color: transparent;
}

.table tbody tr td:last-child, .table thead tr th:last-child {
  text-align: right;
}

.bootgrid-header {
  padding: 0 !important;
  margin: 0;
}

@media only screen and (max-width: 35.9375rem) {
  .bootgrid-header .actionBar {
    padding: 0;
  }

  .bootgrid-header .search {
    margin: 0 0 0.625rem 0;
  }
}

table#example {
  padding: 0rem 0 2rem 0;
}

table.dataTable {
  font-size: 14px;
  border-radius: 0.625rem;
  overflow: hidden;
}

#example2_wrapper .dataTables_scrollBody {
  max-height: 33.25rem !important;
}

#employees, #custommers {
  padding: .5rem 0 1rem 0;
}

.dataTables_wrapper .dataTables_paginate {
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}

table.dataTable thead th, table.dataTable thead td {
  border-bottom: 2px solid #e6e6e6;
  border-top: 0;
}

table.dataTable tfoot th, table.dataTable tfoot td {
  border-top: 0;
}

table.dataTable tbody tr, table.dataTable tbody td {
  background: transparent !important;
}

table.dataTable thead th {
  color: var(--text-dark);
  white-space: nowrap;
  font-size: 1rem;
  font-weight: 600;
  text-transform: capitalize;
  padding: 20px 15px;
}

table.dataTable tbody td {
  padding: 20px 15px;
  border-bottom: 0;
}

@media only screen and (max-width: 35.9375rem) {
  table.dataTable tbody td {
    padding: 8px 5px;
  }
}

@media only screen and (max-width: 87.5rem) {
  table.dataTable tbody td {
    padding: 8px 15px;
  }
}

table.dataTable tr.selected {
  color: var(--primary);
}

table.dataTable tfoot th {
  color: #312a2a;
  font-weight: 600;
}


.dataTables_wrapper .dataTables_paginate {
  align-items: center;
  flex-flow: wrap;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous, .dataTables_wrapper .dataTables_paginate .paginate_button.next {
  margin-left: 0px;
  font-size: 18px;
  margin: 0 2px;
  border: 0 !important;
  height: 50px;
  width: 50px;
  padding: 0;
  border-radius: 0.625rem;
  line-height: 54px;
  display: inline-block;
  color: var(--primary) !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous i, .dataTables_wrapper .dataTables_paginate .paginate_button.next i {
  font-size: 20px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous.current:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.previous.previous:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.previous.next:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.next.current:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.next.previous:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.next.next:hover {
  color: #fff !important;
  background: var(--primary) !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.next.disabled {
  color: var(--primary) !important;
}

@media only screen and (max-width: 35.9375rem) {
  .dataTables_wrapper .dataTables_paginate .paginate_button.previous, .dataTables_wrapper .dataTables_paginate .paginate_button.next {
    height: 32px;
    width: 32px;
    line-height: 32px;
  }
}

.dataTables_wrapper .dataTables_paginate span .paginate_button {
  height: 50px;
  width: 50px;
  padding: 0;
  margin-left: 0px;
  margin: 0 2px;
  line-height: 50px;
  text-align: center;
  font-size: 18px;
  border-radius: 0.625rem;
  color: var(--primary) !important;
  border: 0 !important;
}

@media only screen and (max-width: 35.9375rem) {
  .dataTables_wrapper .dataTables_paginate span .paginate_button {
    height: 32px;
    width: 32px;
    line-height: 32px;
  }
}

.dataTables_wrapper .dataTables_paginate span .paginate_button.current, .dataTables_wrapper .dataTables_paginate span .paginate_button:hover {
  color: var(--primary) !important;
  background: #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.dataTables_wrapper .dataTables_paginate span .paginate_button.current:hover, .dataTables_wrapper .dataTables_paginate span .paginate_button:hover:hover {
  color: #fff !important;
  background: var(--primary) !important;
}

.dataTables_wrapper input[type="search"], .dataTables_wrapper input[type="text"], .dataTables_wrapper select {
  border: 1px solid #e2e2e2;
  padding: .3rem 0.5rem;
  color: #715d5d;
  border-radius: 5px;
}


.dataTables_wrapper .dataTables_length {
  margin-bottom: 15px;
}

.dataTables_wrapper .dataTables_length .bootstrap-select {
  width: 80px !important;
  margin: 0 5px;
}

table.dataTable.no-footer {
  border-bottom: 0;
}

.rounded-lg {
  min-width: 30px;
}

.dataTables_scroll {
  padding: 1rem 0;
}

.dataTables_scrollFoot {
  padding-top: 1rem;
}

.dataTablesCard {
  background-color: #fff;
  border-radius: 0.625rem;
}

.dataTablesCard.border-no td {
  border-top: 0 !important;
}

@media (max-width: 767.98px) {
  .dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter {
    text-align: left;
  }
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  color: #9da1a5 !important;
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
  color: #9da1a5;
  border-radius: 0.625rem;
  padding: 2px 0;
  margin-bottom: 20px;
}

.paging_simple_numbers.dataTables_paginate {
  background: #f0f0f0;
  padding: 5px 5px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  color: #9da1a5 !important;
}

table.dataTable.row-border tbody th,
table.dataTable.row-border tbody td,
table.dataTable.display tbody th,
table.dataTable.display tbody td {
  border-color: #e6e6e6;
}



.dataTables_wrapper .dataTables_length .bootstrap-select .dropdown-toggle {
  font-size: 0.813rem !important;
  padding: 0.625rem 1rem;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc:after {
  content: "";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  background-image: none;
  margin-left: 5px;
  font-size: calc(100% - 2px);
  opacity: 0.5;
}

table.dataTable thead .sorting:after {
  content: "\f0dc";
}

table.dataTable thead .sorting_asc:after {
  content: "\f0de";
  opacity: 1;
}

table.dataTable thead .sorting_desc:after {
  content: "\f0dd";
  opacity: 1;
}

table.dataTable thead .sorting {
  background-image: none;
}

table.dataTable thead .sorting_desc {
  background-image: none;
}

table.dataTable thead .sorting_asc {
  background-image: none;
}

#example2_wrapper #example2_filter {
  float: left;
}

.dataTables_scrollBody thead {
  display: none;
}

#example5_wrapper table.dataTable thead .sorting_asc .custom-checkbox .form-check-input:checked {
  border: 2px solid #fff;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  color: #9da1a5 !important;
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
  color: #9da1a5;
  border-radius: 0.625rem;
  padding: 2px 0;
  margin-bottom: 20px;
}

@media only screen and (max-width: 35.9375rem) {

  .dataTables_wrapper .dataTables_length,
  .dataTables_wrapper .dataTables_filter,
  .dataTables_wrapper .dataTables_processing,
  .dataTables_wrapper .dataTables_paginate {
    padding: 8px 0;
  }
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  color: #9da1a5 !important;
}

table.dataTable.row-border tbody th,
table.dataTable.row-border tbody td,
table.dataTable.display tbody th,
table.dataTable.display tbody td {
  border-color: #e6e6e6;
}



.dataTables_wrapper .dataTables_length .bootstrap-select .dropdown-toggle {
  font-size: 0.813rem !important;
  padding: 0.625rem 1rem;
}

table.dataTable thead>tr>th.sorting:after,
table.dataTable thead>tr>th.sorting_desc:after,
table.dataTable thead>tr>th.sorting_asc:after {
  content: "";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  background-image: none;
  margin-left: 5px;
  font-size: calc(100% - 2px);
  opacity: 0.5;
}

table.dataTable thead>tr>th.sorting:after {
  content: "\f0dc";
}

table.dataTable thead>tr>th.sorting_asc:after {
  content: "\f0de";
  opacity: 0.5;
}

table.dataTable thead>tr>th.sorting_desc:after {
  content: "\f0dd";
  opacity: 0.5;
}

table.dataTable.display>tbody>tr.odd>.sorting_1,
table.dataTable.display>tbody>tr.even>.sorting_1,
table.dataTable.display>tbody>tr.even>.sorting_1,
table.dataTable.display>tbody>tr.odd>* {
  box-shadow: unset;
}

table.dataTable.hover>tbody>tr:hover>*, table.dataTable.display>tbody>tr:hover>* {
  box-shadow: unset !important;
}

table.dataTable thead>tr>th.sorting:before, table.dataTable thead>tr>th.sorting_asc:before, table.dataTable thead>tr>th.sorting_desc:before, table.dataTable thead>tr>th.sorting_asc_disabled:before, table.dataTable thead>tr>th.sorting_desc_disabled:before, table.dataTable thead>tr>td.sorting:before, table.dataTable thead>tr>td.sorting_asc:before, table.dataTable thead>tr>td.sorting_desc:before, table.dataTable thead>tr>td.sorting_asc_disabled:before, table.dataTable thead>tr>td.sorting_desc_disabled:before {
  content: "";
}

table.dataTable thead>tr>th.sorting:before, table.dataTable thead>tr>th.sorting:after, table.dataTable thead>tr>th.sorting_asc:before, table.dataTable thead>tr>th.sorting_asc:after, table.dataTable thead>tr>th.sorting_desc:before, table.dataTable thead>tr>th.sorting_desc:after, table.dataTable thead>tr>th.sorting_asc_disabled:before, table.dataTable thead>tr>th.sorting_asc_disabled:after, table.dataTable thead>tr>th.sorting_desc_disabled:before, table.dataTable thead>tr>th.sorting_desc_disabled:after, table.dataTable thead>tr>td.sorting:before, table.dataTable thead>tr>td.sorting:after, table.dataTable thead>tr>td.sorting_asc:before, table.dataTable thead>tr>td.sorting_asc:after, table.dataTable thead>tr>td.sorting_desc:before, table.dataTable thead>tr>td.sorting_desc:after, table.dataTable thead>tr>td.sorting_asc_disabled:before, table.dataTable thead>tr>td.sorting_asc_disabled:after, table.dataTable thead>tr>td.sorting_desc_disabled:before, table.dataTable thead>tr>td.sorting_desc_disabled:after {
  display: inline-block;
  right: 0;
  position: relative;
}

table.dataTable>tbody>tr.child span.dtr-title {
  font-weight: 600;
}

.fooicon {
  font-size: 1.25rem;
  color: #312a2a;
}

.jsgrid-table .jsgrid-header-row>.jsgrid-header-cell {
  color: #312a2a;
}

.jsgrid-table>tbody>tr>td {
  padding: 1.2em;
}

.jsgrid-table .jsgrid-insert-row input, .jsgrid-table .jsgrid-insert-row select, .jsgrid-table .jsgrid-edit-row input, .jsgrid-table .jsgrid-edit-row select {
  border: 0.0625rem solid #DDDFE1;
}

.jsgrid .jsgrid-button {
  border: 0 !important;
  margin-left: 0.625rem;
}

.error-page .error-text {
  font-size: 9.375rem;
  line-height: 1;
}

@media only screen and (max-width: 35.9375rem) {
  .error-page .error-text {
    font-size: 5rem;
  }
}

.error-page h4, .error-page .h4 {
  font-size: 2.5rem;
  margin-bottom: 0.3125rem;
}

@media only screen and (max-width: 35.9375rem) {
  .error-page h4, .error-page .h4 {
    font-size: 1.25rem;
  }
}

.error-page p {
  font-size: 1rem;
}

@media only screen and (max-width: 35.9375rem) {
  .error-page p {
    font-size: 0.875rem;
  }
}

.flex-row-fluid {
  -webkit-box-flex: 1;
  flex: 1 auto;
  -ms-flex: 1 0 0rem;
  min-width: 0;
}

.authincation {
  display: flex;
  min-height: 100vh;
}

.authincation .login-aside {
  background: #fff;
  padding-top: 5rem;
  max-width: 35rem;
  width: 100%;
  z-index: 1;
  position: relative;
}

.authincation .login-aside:after {
  content: "";
  clip-path: polygon(0% 100%, 100% 0%, 0% 0%);
  width: 8.75rem;
  height: 100%;
  position: absolute;
  right: -8.75rem;
  z-index: -1;
  top: 0;
  background: #fff;
  box-shadow: 0.125rem 0rem 1.875rem rgba(0, 0, 0, 0.15);
}

.authincation .login-aside .aside-image {
  min-height: 28.125rem;
  margin: auto 0;
  min-width: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@media only screen and (max-width: 87.5rem) {
  .authincation .login-aside {
    max-width: 22.5rem;
  }
}

@media only screen and (max-width: 61.9375rem) {
  .authincation .login-aside {
    max-width: 100%;
    padding-top: 0;
  }

  .authincation .login-aside:after {
    content: none;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .authincation .login-aside .aside-image {
    min-height: 18.75rem;
  }
}

.authincation-content {
  background: #fff;
  box-shadow: 0 0 2.1875rem 0 rgba(154, 161, 171, 0.15);
  border-radius: 0.3125rem;
  margin: 29px 0;
}



.authincation-content.style-1 {
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(20px);
}

.authincation-content.style-1 .form-control {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 0.3125rem;
}

.authincation-content.style-1 .user-icon {
  height: 6.25rem;
  background: var(--primary);
  width: 6.25rem;
  text-align: center;
  border-radius: 6.25rem;
  line-height: 6.25rem;
  margin-left: auto;
  font-size: 3.75rem;
  text-align: center;
  color: white;
  margin-right: auto;
  margin-top: -6.25rem;
  margin-bottom: 1.25rem;
}

.authincation-content.style-2 {
  background: transparent;
  box-shadow: none;
  max-width: 33.125rem;
  width: 100%;
}

.authincation-content.style-2 .form-control {
  border: 0;
  border-radius: 0.3125rem;
  box-shadow: 0rem 0rem 0.9375rem rgba(0, 0, 0, 0.08);
}

@media only screen and (max-width: 35.9375rem) {
  .authincation-content.style-2 .auth-form {
    padding: 1.875rem 0rem;
  }
}

.welcome-content {
  /* background: url("./../images/1.jpg"); */
  background-size: cover;
  background-position: center;
  height: 100%;
  padding: 4.6875rem 3.125rem;
  position: relative;
  z-index: 1;
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.welcome-content::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: var(--primary);
  opacity: 0.75;
  z-index: -1;
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.welcome-content .welcome-title {
  color: #fff;
  font-weight: 500;
  font-size: 1.25rem;
}

.welcome-content p {
  color: #fff;
}

.welcome-content .brand-logo a {
  display: inline-block;
  margin-bottom: 1.25rem;
  font-weight: 700;
  color: #fff;
  font-size: 1.25rem;
}

.welcome-content .brand-logo a img {
  width: 6.25rem;
}

.welcome-content .intro-social {
  position: absolute;
  bottom: 4.6875rem;
}

.welcome-content .intro-social ul {
  margin-bottom: 0;
}

.welcome-content .intro-social ul li {
  display: inline-block;
}

.welcome-content .intro-social ul li a {
  color: #fff;
  font-size: 0.875rem;
  padding: 0rem 0.4375rem;
}

.auth-form {
  padding: 3.125rem 3.125rem;
}

@media only screen and (max-width: 35.9375rem) {
  .auth-form {
    padding: 1.875rem;
  }
}

.auth-form .btn {
  height: 3.125rem;
  font-weight: 700;
}

.auth-form .page-back {
  display: inline-block;
  margin-bottom: 0.9375rem;
}

.eye {
  position: absolute;
  right: 20px;
  bottom: 11px;
}

.form-row a:hover {
  color: var(--primary);
}

.fix-wrapper {
  min-height: 100vh;
  display: flex;
  padding: 30px 0;
  align-items: center;
}

.content-body.default-height {
  min-height: calc(100vh - 5.3rem);
}

/* .heart {
  width: 60px;
  height: 60px;
  display: inline-block;
  background: url("../images/like.png");
  cursor: pointer;
  margin: -25px -15px;
} */


@keyframes heartbeat {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(1.2);
  }

  40% {
    transform: scale(1);
  }

  60% {
    transform: scale(1.2);
  }

  80% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

.heartbeat {
  animation: heartbeat 1.5s ease-in-out infinite;
  color: red;

  font-size: 14px;

}


.heart-blast {
  background-position: -1680px 0 !important;
  transition: background 1s steps(28);
}

.header-profile {
  position: relative;
  padding-left: 1.875rem;
}

.header-profile .nav-link {
  position: relative;
  background: #fff;
  border-radius: 3rem;
  padding: 0.1875rem;
}

.header-profile .nav-link img {
  height: 3rem;
  width: 3rem;
  border-radius: 4.25rem;
}

@media only screen and (max-width: 87.5rem) {
  .header-profile .nav-link img {
    height: 2.1875rem;
    width: 2.1875rem;
  }
}

.light.badge-info-sec {
  background-color: #4a1ece34;
  color: #8d14ff;
}

.header-profile .nav-link:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -0.1875rem;
  border-radius: inherit;
  background: linear-gradient(to right, #CEA7FF, #ffa7d7);
}

.header-profile .nav-link {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.tryal-gradient {
  background: var(--primary);
  position: relative;
}

.tryal-gradient-meeting {
  background: linear-gradient(to right, #ce2b7c, #8d14ff);
  position: relative;
}

.progress-bar {
  background-color: #8d14ff !important;
}







.tryal {
  padding: 3rem;
}

@media only screen and (max-width: 35.9375rem) {
  .tryal {
    padding: 1.5rem;
  }
}

.tryal h2, .tryal .h2 {
  font-size: 2rem;
  font-weight: 700;
  color: #fff;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 35.9375rem) {
  .tryal h2, .tryal .h2 {
    font-size: 1.6rem;
  }
}

.tryal span {
  font-size: 1rem;
  font-weight: 400;
  color: #fff;
  display: block;
  margin: 1.875rem 0;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 35.9375rem) {
  .tryal span {
    margin-top: 5px;
  }
}

.tryal a {
  font-weight: 500 !important;
  background: #fff;
  color: #000;
  position: relative;
  z-index: 1;
}

.tryal a:hover {
  background-color: var(--primary);
  color: #fff;
}

.on-going {
  background: linear-gradient(180deg, #FFA26D 0%, #FFCF6D 100%);
}

.sd-shape {
  animation: movedelement 10s linear infinite;
  width: 100%;
  transform: scale(1.1);
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 35.9375rem) {
  .sd-shape {
    display: none;
  }
}

@keyframes movedelement {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  25% {
    -webkit-transform: translate(10px, 10px);
    transform: translate(10px, 10px);
  }

  50% {
    -webkit-transform: translate(5px, 5px);
    transform: translate(5px, 5px);
  }

  75% {
    -webkit-transform: translate(10px, -5px);
    transform: translate(10px, -5px);
  }

  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

.profile-k span {
  height: 3rem;
  width: 3rem;
  line-height: 3rem;
  display: block;
  font-size: 1.5rem;
  color: white;
  border-radius: 50%;
  text-align: center;
}

.profile-k img {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  object-fit: cover;
}

.email-check .like-btn {
  display: inline-block;
  padding-left: 0;
  margin-bottom: 0.75rem;
  cursor: pointer;
  font-size: 1.375rem;
  font-family: 'Font Awesome 5 Free';
}

.email-check .like-btn input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  display: block;
}

.email-check .like-btn input:checked~.checkmark {
  background-color: var(--primary);
}

.email-check .like-btn input:checked~.checkmark:before {
  color: #fff;
}

.email-check .checkmark {
  height: 3rem;
  text-align: center;
  line-height: 3rem;
  width: 3rem;
  display: block;
  border: 0.0625rem solid var(--primary);
  border-radius: 0.635rem;
}

.email-check .checkmark:before {
  width: 100%;
  height: 100%;
  color: var(--primary);
  left: 0;
  top: 0;
  border-radius: 0.875rem;
  padding: 0.25rem 0.375rem;
}

.email-check .like-btn .checkmark:before {
  content: "\f08d";
  font-size: 1.25rem;
  font-weight: 900;
}

.final-badge .badge {
  padding: 0.75rem 1.625rem;
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--primary) !important;
  border-radius: 3.125rem;
  margin-right: 0.5rem;
  margin-top: 0.6rem;
  cursor: pointer;
}

.final-badge .badge i {
  transform: scale(1.5);
}

.recent-emails {
  border-bottom: 0.0625rem solid var(--rgba-primary-1);
  padding: 0.9375rem 1.8625rem;
}

.recent-emails:last-child {
  border-bottom: 0;
}

.project-details .default-progress {
  height: 0.325rem;
  background-color: var(--rgba-primary-1);
}

.card-slider .owl-nav {
  display: flex;
  margin-top: 1.875rem;
}

.card-slider .owl-nav .owl-prev,
.card-slider .owl-nav .owl-next {
  height: 2.3125rem;
  width: 2.3125rem;
  background: #D7D7D7 !important;
  text-align: center;
  line-height: 2.3125rem !important;
  color: white !important;
  border-radius: 3.125rem;
  cursor: pointer;
}

.card-slider .owl-nav .owl-prev:hover,
.card-slider .owl-nav .owl-next:hover {
  background: #717579 !important;
}

.card-slider .owl-nav .owl-next {
  margin-left: 1.25rem;
}

@media only screen and (max-width: 35.9375rem) {
  .apexcharts-text {
    font-size: 13px;
  }
}

.redial {
  margin-top: -1.5rem;
}

.projects {
  margin: 1rem 0;
}

.project-details {
  margin: 0.625rem 0;
}

.project-details .big-wind {
  height: 3rem;
  width: 3rem;
  border: 1px solid var(--border);
  border-radius: 0.625rem;
  line-height: 2.8rem;
  text-align: center;
}

.project-details .big-wind img {
  width: 25px;
}

.msg-bx {
  border-bottom: 0.0625rem solid var(--rgba-primary-1);
  padding: 0.950rem 1.85rem;
  position: relative;
}

.msg-bx:last-child {
  border-bottom: 0;
}

.msg-bx .msg .image-box {
  height: 3rem;
  width: 3rem;
  position: relative;
}

.msg-bx .msg .image-box img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.msg-bx .msg .image-box.active:after {
  height: 0.9rem;
  width: 0.9rem;
  border-radius: 50%;
  background: #09BD3C;
  border: 0.0625rem solid #fff;
  bottom: 0;
  right: -0.3125rem;
  position: absolute;
  content: "";
}

.side-bar-profile {
  padding: 0 28.5px;
  margin: 21px 0px;
}

.side-bar-profile .side-bar-profile-img {
  position: relative;
}

.side-bar-profile .side-bar-profile-img:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -0.1875rem;
  border-radius: inherit;
  background: linear-gradient(to right, #CEA7FF, #ffa7d7);
  height: 46px;
  width: 46px;
  border-radius: 50px;
}

.side-bar-profile .side-bar-profile-img img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  margin-right: -18px;
}

@media only screen and (max-width: 100rem) {
  .side-bar-profile {
    padding: 0 24px;
  }

  .side-bar-profile .side-bar-profile-img img {
    margin-right: 13px;
  }
}

@media only screen and (max-width: 87.5rem) {
  .side-bar-profile {
    display: none;
  }
}

.bg-gradientf {
  background: linear-gradient(90deg, #FFA26D 50%, #FFDF6D 102.88%);
}

.logo-abbr path {
  fill: var(--primary);
}

.ck.ck-editor__editable_inline> :first-child {
  height: 150px;
}

.textarea_editor {
  height: 120px;
}

#wallet-bar {
  background-color: #fff;
  border-top-left-radius: 2.5rem;
  border-bottom-left-radius: 2.5rem;
  border: 1px solid #CEDDE5;
}

#wallet-bar.dlab-scroll {
  overflow-y: scroll;
}

@media only screen and (max-width: 105rem) {
  #wallet-bar {
    display: none;
  }
}

.nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
  background-color: #f3f0f9;
}

.select2-container .select2-search--inline .select2-search__field {
  margin-top: 8px;
  margin-left: 10px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  display: none;
}

.border-right {
  border-right: 1px solid var(--border);
}

.card-link:hover {
  color: var(--primary);
}

.email-left-box .list-group-item+.list-group-item {
  border-top-width: 1px;
}

.border,
.border-top,
.border-bottom {
  border-color: var(--border) !important;
}

.brand-title .logo-dark {
  display: none;
}

.brand-title .logo-light {
  display: inline-block;
}

.profile-info1 {
  margin-left: 20px;
}

.card-tabs .nav-tabs {
  border: 0;
  border-radius: 0.625rem;
  background-color: var(--rgba-primary-1);
  padding: 4px;
  white-space: nowrap;
}

.card-tabs .nav-tabs .nav-item {
  flex: 1;
}

.rbc-button-link, .rbc-time-slot {
  color: #363739 !important;
}



.card-tabs .nav-tabs .nav-item .nav-link {
  border: 0;
  border-radius: 0.625rem;
  margin: 0;
  color: #373839;
  padding: 0.5rem 1.125rem;
  font-weight: 500;
  text-align: center;
}

.card-tabs .nav-tabs .nav-item .nav-link.active {
  background: var(--primary);
  color: #fff;
}

.card-tabs .nav-tabs .nav-item .nav-link.active:hover {
  color: #fff;
}

.card-tabs .nav-tabs .nav-item .nav-link:hover {
  color: #8d14ff;
}

@media only screen and (max-width: 35.9375rem) {
  .card-tabs .nav-tabs .nav-item .nav-link {
    padding: 0.5rem 0.825rem;
  }
}

.card-tabs .nav-tabs.style-1 {
  background-color: var(--card);
}

.card-padding {
  padding: 10px 22px;
}

.form-label {
  color: var(--text-dark);
}

.border-end {
  border-right-color: var(--border) !important;
}

.apexcharts-tooltip.apexcharts-theme-light {
  border-radius: 0.325rem;
  background-color: var(--card) !important;
  border: 0 !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

@media only screen and (max-width: 100rem) {
  .email-lagend .spam {
    margin-bottom: 15px !important;
  }
}

@media only screen and (max-width: 74.9375rem) {
  .chat-left-body {
    width: 33.33%;
    position: fixed;
    height: 100vh;
    z-index: 2;
    transition: all 0.5s;
    left: -25rem;
    top: 0px;
    z-index: 999;
  }

  .chat-left-body.active {
    left: 0;
    background: #fff;
    transition: all 0.5s;
    overflow-y: scroll;
  }
}

@media only screen and (max-width: 74.9375rem) and (max-width: 61.9375rem) {
  .chat-left-body {
    left: -29rem;
    width: 50%;
  }
}

@media only screen and (max-width: 74.9375rem) and (max-width: 47.9375rem) {
  .chat-left-body {
    width: 60%;
  }
}

@media only screen and (max-width: 74.9375rem) and (max-width: 35.9375rem) {
  .chat-left-body {
    width: 88%;
  }
}

.chat-toogle {
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  background-color: var(--rgba-primary-1);
  border-radius: 0.625rem;
  color: var(--primary);
  display: none !important;
}

.chat-toogle.active {
  background-color: var(--primary);
  color: #fff;
}

@media only screen and (max-width: 74.9375rem) {
  .chat-toogle {
    display: inline-block !important;
    margin-left: auto;
  }
}

.chat-toogle.active:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #000;
  opacity: 0.2;
  border-radius: 0;
  z-index: 4;
}

.tooltip-inner {
  background-color: var(--rgba-primary-1);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  color: var(--primary);
  font-weight: 400;
  width: auto;
  border-radius: 0.625rem;
}

.tooltip-inner:before {
  background-color: #fff !important;
}

.tooltip .arrow {
  border-top-color: #008ec3 !important;
  background-color: #fff !important;
}

.tooltip.bs-tooltip-top .tooltip-arrow::before, .tooltip.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  border-top-color: var(--primary);
  /* Set arrow color to red */
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.tooltip.bs-tooltip-bottom .tooltip-arrow::before, .tooltip.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  border-bottom-color: var(--primary);
  /* Set arrow color to red */
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.tooltip.bs-tooltip-start .tooltip-arrow::before, .tooltip.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  border-left-color: var(--primary);
  /* Set arrow color to red */
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.tooltip.bs-tooltip-end .tooltip-arrow::before, .tooltip.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  border-right-color: var(--primary);
  /* Set arrow color to red */
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.angular-svg {
  width: 50px;
  height: 50px;
  display: inline-block;
  text-align: center;
  line-height: 50px;
  border-radius: 0.625rem;
  background-color: var(--body-bg);
}

.angular-svg img {
  width: 24px;
  height: 24px;
}

.angular-svg .content {
  line-height: 2;
}

.project-image img {
  height: 2.813rem;
  width: 2.813rem;
  border-radius: 50%;
  margin-right: 10px;
}



.project-image-int img {
  height: 2.813rem;
  width: 2.813rem;
  border-radius: 50%;
  margin-right: 10px;
}


.project-image-int img{
  width: 62px !important;
  height: 62px !important;
  margin-right: 16px !important;
  border-radius: 8px !important;
}

@media only screen and (max-width: 100rem) {
  .customers h3, .customers .h3 {
    font-size: 1rem !important;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .customers {
    margin-bottom: 20px;
  }
}

.bg-progress {
  background: #E4F5FF;
  color: #2DB3FF !important;
}

.card.project-card {
  margin-bottom: 0.975rem;
}

.contacts-search {
  width: 19.75rem;
}

@media only screen and (max-width: 35.9375rem) {
  .contacts-search {
    width: 100%;
  }
}

.contacts-search .form-control {
  border-right: 0;
  border: 0;
  background: #fff;
  border-top-left-radius: 3.125rem;
  border-bottom-left-radius: 3.125rem;
  height: 48px;
}

.contacts-search .input-group-text {
  background: #fff;
  border-left: 0;
  border: 0;
  height: 48px;
  border-radius: 3.125rem;
}

.contacts-search .input-group-text i {
  font-size: 1.2rem;
}

.contact-bx {
  border-width: 2px;
  text-align: center;
}

.contact-bx .card-header {
  position: absolute;
  right: 0;
  top: 0;
}

.contact-bx .image-bx {
  display: inline-block;
  position: relative;
  margin-bottom: 10px;
}

.contact-bx .image-bx img {
  width: 5rem;
  height: 5rem;
}

.contact-bx .image-bx span.active {
  height: 1.125rem;
  width: 1.125rem;
  position: absolute;
  bottom: 3px;
  right: 3px;
  border-radius: 2rem;
  background: #09BD3C;
  border: 3px solid #fff;
}

.contact-bx:hover {
  box-shadow: rgba(242, 4, 135, 0.2) 0px 7px 29px 0px;
}

.user-meta-info ul {
  display: flex;
  justify-content: center;
}

.user-meta-info ul li a {
  margin: 0 10px;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  background: var(--rgba-primary-1);
  color: var(--primary);
  line-height: 2.5rem;
  display: block;
  font-size: 15px;
  transition: all 0.5s;
  text-align: center;
}

.user-meta-info ul li a:hover {
  background: var(--primary);
  color: #fff;
}

.kanbanimg {
  display: flex;
}

.kanbanimg img {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #fff;
}

.kanbanimg li {
  margin-right: -10px;
  cursor: pointer;
  transition: all 0.5s;
}

.kanbanimg li:hover {
  transform: translateY(-0.188rem);
}

.kanbanimg li span {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50px;
  background: var(--primary);
  color: white;
  line-height: 2.5rem;
  display: block;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  border: 1px solid #fff;
}

.invite a {
  font-size: 1rem !important;
}

.facebook-icon {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 0.625rem;
  width: 55px;
  background: #FFFFFF;
  height: 55px;
  position: relative;
}

@media only screen and (max-width: 35.9375rem) {
  .facebook-icon {
    width: 50px;
    height: 50px;
  }
}

.facebook-icon a {
  height: 27px;
  width: 27px;
  display: block;
  border-radius: 47px;
  line-height: 27px;
  text-align: center;
  background: linear-gradient(209.74deg, #886CC0 4.05%, #AA5DE8 95.37%);
  color: #fff;
  font-size: 14px;
  position: absolute;
  top: 14px;
  left: 14px;
}

@media only screen and (max-width: 35.9375rem) {
  .facebook-icon a {
    height: 37px;
    width: 37px;
    line-height: 37px;
    font-size: 16px;
    top: 7px;
    left: 7PX;
  }
}

.flex-1 {
  flex: 1;
}

.plus-bx a {
  width: 2.5rem;
  height: 2.5rem;
  background: var(--primary);
  line-height: 2.688rem;
  text-align: center;
  display: block;
  color: white;
  border-radius: 50%;
  font-size: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

@media only screen and (max-width: 35.9375rem) {
  .plus-bx a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 20px;
  }
}

.sub-title {
  color: #FFA7D7;
}

.kanban-user .users {
  display: flex;
}

.kanban-user .users li {
  margin-right: -10px;
}

.kanban-user .users li img {
  border-radius: 32px;
  height: 32px;
  width: 32px;
  border: 2px solid #fff;
}

.bg-design {
  background: #FFA7D7;
}

.kanban-bx {
  display: flex;
  width: 100%;
  overflow-x: auto;
  flex-wrap: nowrap;
}

.kanban-bx .col {
  width: 360px;
  min-width: 360px;
  flex-grow: unset;
  flex-basis: unset;
}

.kanban-bx .col .card {
  height: auto;
  cursor: all-scroll;
}

.kanban-bx::-webkit-scrollbar {
  background-color: #ECECEC;
  width: 8px;
  height: 8px;
}

.kanban-bx::-webkit-scrollbar-thumb {
  background-color: #7e7e7e;
  border-radius: 10px;
}

.draggable.card {
  transition: none;
}

.chat-bx {
  padding: 12px 20px;
}

@media only screen and (max-width: 35.9375rem) {
  .chat-bx {
    padding: 12px;
  }
}

.chat-bx.active {
  background: var(--rgba-primary-1);
  position: relative;
}

.chat-bx.active:before {
  position: absolute;
  background: var(--primary);
  height: 100%;
  width: 7px;
  top: 0;
  left: 0;
  content: "";
}

.chat-img img {
  height: 2.813rem;
  width: 2.813rem;
  border-radius: 50%;
  margin-right: 10px;
}

.fillow-design a {
  height: 3.125rem;
  width: 3.125rem;
  border-radius: 50%;
  text-align: center;
  background: var(--primary);
  display: block;
  color: white;
  font-size: 1.275rem;
  line-height: 3.125rem;
}

.activity ul li a {
  padding: 0 8px;
  font-size: 1.25rem;
  color: var(--primary);
}

.chat-box-area {
  height: 630px;
}

.image-box {
  position: relative;
  display: inline-block;
}

.image-box .img-1 {
  width: 3rem;
  height: 3rem;
}

.image-box span.active1 {
  position: absolute;
  bottom: -5px;
  right: -5px;
  height: 1.25rem;
  width: 1.25rem;
  background: #09BD3C;
  border: 3px solid #fff;
  border-radius: 50%;
}

.message-received {
  width: 62%;
  text-align: left;
}

.message-received p {
  background: rgba(110, 110, 110, 0.14);
  text-align: left;
  border-radius: 0 0.625rem 0.625rem 0.625rem;
  padding: 10px 20px;
  color: #000;
  font-weight: 400;
}

@media only screen and (max-width: 35.9375rem) {
  .message-received {
    width: 100%;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.message-sent {
  width: 62%;
  text-align: right;
}

@media only screen and (max-width: 35.9375rem) {
  .message-sent {
    width: 100%;
  }
}

.message-sent p {
  background: #717579;
  text-align: left;
  border-radius: 20px 20px 0 20px;
  padding: 10px 20px;
  color: #fff;
}

.group-pic {
  margin-right: 15px;
}

.group-pic img {
  height: 25px;
  width: 25px;
  border-radius: 25px;
  object-fit: cover;
  margin: 2px 2px;
}

.type-massage .input-group textarea {
  height: 5rem !important;
  border: 0;
  background: var(--rgba-primary-1);
  border-radius: 0.625rem 0.625rem 0px 0px;
  padding: 15px;
}

.type-massage .input-group-append button {
  padding: 10px 15px;
  margin: 15px 0;
}

@media only screen and (max-width: 35.9375rem) {
  .type-massage .input-group-append button {
    padding: 0px 9px;
  }
}

.chat-box-area {
  height: 630px;
  padding: 0 15px;
}

@media only screen and (max-width: 35.9375rem) {
  .chat-box-area {
    padding: 0 11px;
  }
}

.chat-sidebar {
  height: 700px;
}

.message-tab .card-tabs .nav-tabs .nav-link {
  padding: 12px 15px;
}

.kanbanPreview-bx {
  padding: 1rem 1.3rem 1rem;
  background: linear-gradient(to right, rgba(102, 102, 102, 0.2) 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(rgba(102, 102, 102, 0.2) 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(to right, rgba(102, 102, 102, 0.2) 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(rgba(102, 102, 102, 0.2) 50%, rgba(255, 255, 255, 0) 0%);
  background-position: top, right, bottom, left;
  background-repeat: repeat-x, repeat-y;
  background-size: 10px 1px, 1px 10px;
}

.meassge-left-side {
  padding: 30px 16px;
}

.fullcalendar .fc-toolbar-chunk div:first-child {
  display: flex;
}

@media only screen and (max-width: 35.9375rem) {
  .fullcalendar .fc-toolbar-chunk div:first-child {
    margin-bottom: 1.5rem;
  }
}

.fullcalendar .fc-toolbar-chunk .fc-toolbar-title {
  margin-right: 15px;
}

.fullcalendar .fc-toolbar-chunk button {
  background: transparent;
  color: black;
  border: 0;
}

.fullcalendar .fc-button-group {
  border: 0;
  border-radius: 0.625rem !important;
  background-color: var(--rgba-primary-1);
  padding: 4px;
}

.fullcalendar .fc-button-group .fc-dayGridMonth-button,
.fullcalendar .fc-button-group .fc-timeGridWeek-button,
.fullcalendar .fc-button-group .fc-timeGridDay-button {
  border: 0;
  border-radius: 0.625rem !important;
  margin: 0;
  color: var(--text-gray);
  padding: 0.5rem 1.125rem;
  font-weight: 500;
}

.fullcalendar .fc-button-group .fc-dayGridMonth-button.fc-button-active,
.fullcalendar .fc-button-group .fc-timeGridWeek-button.fc-button-active,
.fullcalendar .fc-button-group .fc-timeGridDay-button.fc-button-active {
  background: var(--primary);
  color: #fff;
  border-radius: 0.625rem !important;
}

.fullcalendar .fc-today-button {
  background: transparent !important;
  border: 1px solid var(--primary) !important;
  color: var(--primary) !important;
  border-radius: 50px;
  padding: 8px 25px;
}

.photo-content {
  position: relative;
}

.photo-content .cover-photo {
  /* background: url(../images/profile/cover.jpg); */
  background-size: cover;
  background-position: center;
  min-height: 15.625rem;
  width: 100%;
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
}

.user .user-info {
  display: flex;
  padding: 0.9375rem 1.25rem 1.5rem;
}

@media only screen and (max-width: 47.9375rem) {
  .user .user-info {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .user .user-info {
    display: block;
  }
}

.user .user-info .user-details {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media only screen and (max-width: 35.9375rem) {
  .user .user-info .user-details {
    display: block;
  }
}

.user .user-info .user-details .user-contact {
  display: flex;
  align-items: center;
  padding-top: 1.125rem;
  flex-wrap: wrap;
}

.user .user-info .user-details .user-contact .user-number {
  display: flex;
  align-items: center;
  margin-right: 1.5rem;
  margin-bottom: 0.625rem;
}

@media only screen and (max-width: 35.9375rem) {
  .user .user-info .user-details .user-contact .user-number {
    margin-bottom: 0.875rem;
  }
}

.user .user-info .user-details .user-contact .user-email {
  display: flex;
  align-items: center;
  margin-bottom: 0.625rem;
}

@media only screen and (max-width: 35.9375rem) {
  .user .user-info .user-details .user-contact .user-email {
    margin-bottom: 0.875rem;
  }
}

.user .user-info .user-details .user-contact h4, .user .user-info .user-details .user-contact .h4 {
  margin: 0;
}

.user .user-info .user-details .user-contact .dz-media {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
  margin-right: 0.625rem;
}

.user-photo {
  min-width: 150px;
  height: 150px;
  position: relative;
  z-index: 1;
  margin-top: -4.5rem;
  margin-right: 0.625rem;
}

.user-photo img {
  border: 4px solid #fff;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 35.9375rem) {
  .user-photo {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.25rem;
    margin-top: -6.5rem;
    width: 150px;
  }
}

[direction="rtl"] .user-photo {
  left: auto;
  right: 0;
  margin-right: 0;
  margin-left: 0.9375rem;
}

@media only screen and (max-width: 74.9375rem) {
  [direction="rtl"] .user-photo {
    right: 0.9375rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  [direction="rtl"] .user-photo {
    width: 5rem;
    right: calc(50% - 2.5rem);
    top: -6.25rem;
  }
}

.profile-interest .row {
  margin: 0 -0.0437rem;
}

.profile-interest .row .int-col {
  padding: 0 0.0437rem;
}

.profile-interest .row .int-col .interest-cat {
  margin-bottom: 0.0875rem;
  position: relative;
  display: block;
}

.profile-interest .row .int-col .interest-cat:after {
  background: #000;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}

.profile-interest .row .int-col .interest-cat p {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0.3125rem;
  left: 0;
  margin: 0;
  z-index: 1;
  color: white;
  font-size: 0.075rem;
}

.profile-interest .row a img {
  border-radius: 0.625rem;
}

.profile-tab .nav-item .nav-link {
  font-size: 1rem;
  margin-right: 1.875rem;
  transition: all 0.5s ease-in-out;
  border: none;
  border-bottom: 0.0125rem solid transparent;
  color: #828690;
}

.profile-tab .nav-item .nav-link:hover, .profile-tab .nav-item .nav-link.active {
  border: 0;
  background: transparent;
  background: transparent;
  border-bottom: 0.0125rem solid var(--primary);
  color: var(--primary);
}

@media only screen and (max-width: 35.9375rem) {
  .profile-tab .nav-item .nav-link {
    margin-right: 0rem;
  }
}

.profile-blog img {
  border-radius: 0.625rem;
}

.side-detail {
  display: flex;
}

.side-detail .edit-profiil {
  margin-right: 0.625rem;
}

.profile-uoloaded-post img {
  margin-bottom: 1.25rem;
}

.profile-uoloaded-post a h4, .profile-uoloaded-post a .h4 {
  margin-bottom: 0.625rem;
  color: #464a53;
}

.prot-blog {
  padding: 30px 20px;
  background-color: var(--primary);
  position: relative;
  overflow: hidden;
  z-index: 0;
  border-radius: 0.625rem;
}

.prot-blog .post .text {
  font-size: 18px;
  font-weight: 400;
  color: white;
}

.prot-blog .fill .text {
  font-size: 20px;
  font-weight: 600;
  color: white;
}

.prot-blog .fill a {
  background-color: white;
  padding: 8px 15px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 0.625rem;
  color: #2A353A;
}

@media only screen and (max-width: 78.125rem) {
  .prot-blog .fill a {
    padding: 5px 5px;
  }
}

.prot-blog .text-bla {
  color: white !important;
  font-size: 14px;
  font-weight: 500;
}

.prot-blog p {
  color: white;
  font-weight: 400;
}

.prot-blog .shape {
  position: absolute;
  bottom: -70px;
  right: -50px;
  z-index: -1;
}

.prot-blog .shape svg path {
  fill: #3ab5ff;
}

.messages .input-group.search-area {
  flex-direction: row-reverse;
  margin-right: 0.625rem;
}

.messages .input-group.search-area input.form-control {
  border-left: none;
  background-color: #f3f3f3;
  border: 0;
  overflow: hidden;
  height: 3rem;
  padding-left: 0;
}

.messages .input-group.search-area .input-group-text {
  border-right: none;
  background-color: #f3f3f3;
  border: 0;
  height: 3rem;
  padding: 0;
}

.messages .add {
  background-color: var(--primary);
  display: block;
  width: 3rem;
  height: 3rem;
  border-radius: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pie-chart2 .chart-group {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 74.9375rem) {
  .pie-chart2 .chart-group .radius-bar {
    flex: 0 0 50%;
    margin-bottom: 0.875rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .pie-chart2 .chart-group .radius-bar {
    flex: 0 0 100%;
  }
}

.pie-chart2 .chart-group .donut-chart-sale {
  margin-bottom: 0.625rem;
}

.pie-chart2 .chart-group .donut-chart-sale small, .pie-chart2 .chart-group .donut-chart-sale .small {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.125rem;
  font-weight: 600;
}

.pie-chart2 .chart-info {
  margin-top: 1rem;
  padding-top: 0.625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 3px dashed #6666663d;
}

@media only screen and (max-width: 35.9375rem) {
  .pie-chart2 .chart-info {
    flex-wrap: wrap;
  }

  .pie-chart2 .chart-info .btn {
    margin-top: 0.625rem;
  }
}

.pie-chart2 .chart-info p {
  margin-bottom: 0;
}

.profile-card {
  border: 1px solid #e6e6e6;
  border-radius: 0.625rem;
}

.profile-card .card-header {
  border-color: #e6e6e6;
  padding: 30px;
}

.profile-card .card-header .title {
  position: relative;
  margin-bottom: 0;
  color: var(--text-dark);
}

.profile-card .card-header .title:after {
  content: "";
  height: 3px;
  width: 100%;
  background-color: var(--primary);
  position: absolute;
  bottom: -31px;
  left: 0;
}

.profile-card .m-b30 {
  margin-bottom: 30px;
}

.profile-card .card-body {
  padding: 40px 40px 10px;
}

.profile-card .card-footer {
  padding: 35px 40px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-color: #e6e6e6;
}

.profile-card .card-footer .btn-lg, .profile-card .card-footer .btn-group-lg>.btn {
  padding: 18px 50px;
  font-size: 15px;
}

.profile-card .card-footer .btn-link {
  font-size: 15px;
}

@media only screen and (max-width: 35.9375rem) {
  .profile-card .card-header {
    padding: 25px;
  }

  .profile-card .card-header .title:after {
    bottom: -26px;
  }

  .profile-card .card-body {
    padding: 25px 25px 0;
  }

  .profile-card .card-footer {
    padding: 25px 25px 25px;
  }

  .profile-card .card-footer .btn-lg, .profile-card .card-footer .btn-group-lg>.btn {
    padding: 18px 30px;
  }
}

.author-profile {
  text-align: center;
}

.author-profile .card-body {
  padding: 0;
}

.author-profile .author-media {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  display: inline-block;
}

.author-profile .author-media img {
  width: 130px;
  border-radius: 100%;
  border: 1px solid var(--border);
}

.author-profile .author-info .title {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0;
}

.author-profile .author-info span {
  display: block;
  color: #9da1a5;
}

.author-profile .info-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  border-top: 1px solid #e6e6e6;
  padding: 18px 30px;
  color: var(--text-dark);
}

.author-profile .info-list li a {
  color: var(--text-dark);
}

.author-profile .info-list li span {
  color: #9da1a5;
  font-weight: 500;
}

.author-profile .card-footer {
  padding: 30px;
  display: block;
}

.author-profile .card-footer .form-control {
  height: 45px;
  border-color: #e6e6e6;
  overflow: hidden;
  line-height: 2;
}

.author-profile .upload-link {
  position: absolute;
  width: 35px;
  height: 35px;
  line-height: 32px;
  background: #8d14ff;
  bottom: 0;
  right: 0px;
  box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1);
  border-radius: 100%;
  color: #fff;
  overflow: hidden;
  border: 2px solid #fff;
}

.author-profile .upload-link .update-flie {
  position: absolute;
  opacity: 0;
  z-index: 0;
  width: 100%;
  cursor: pointer;
  left: 0;
  height: 100%;
}

/*
===================================
    Product detail
===================================*/
.produtct-detail-tag {
  display: inline-block;
}

.produtct-detail-tag a {
  font-style: 0.8125rem;
  color: #312a2a;
}

.product-detail-content .item-tag {
  background: #828690;
  border-radius: 0.375rem;
  display: inline-block;
  font-size: 0.75rem;
  margin-right: 0.25rem;
  padding: 0.125rem 0.75rem;
  color: #fff;
}

.product-detail-content input.form-control.input-btn.input-number {
  height: 3rem;
}

@media only screen and (max-width: 87.5rem) {
  .product-detail-content input.form-control.input-btn.input-number {
    height: 2.5rem;
  }
}

.filtaring-area h4, .filtaring-area .h4 {
  color: #1d1d1d;
  font-size: 1rem;
  font-weight: 400;
  text-transform: lowercase;
}

.plus-minus-input .input-icon {
  font-size: 0.8125rem;
  color: #312a2a;
}

.plus-minus-input {
  display: flex;
  width: 7.5rem;
}

.plus-minus-input .custom-btn {
  border-radius: 0;
  height: 2.5rem;
  padding: 0.75rem 0.5rem;
  background: #fff;
  border: 0.0625rem solid #e6e6e6;
}

.plus-minus-input .form-control:hover, .plus-minus-input .form-control:focus, .plus-minus-input .form-control:active {
  border: 0.0625rem solid #e6e6e6;
}

.btn-reveal-trigger .avatar-xl {
  min-width: 1.875rem;
}

.share-view {
  display: inline-block;
}

.share-view ul li {
  display: inline-block;
}

.share-view .share-icon {
  width: 2.5rem;
  height: 2.5rem;
  display: inline-block;
  border: 0.0625rem solid #e6e6e6;
  text-align: center;
  line-height: 2.5rem;
  font-style: 1rem;
  color: #e6e6e6;
  margin-right: 0.5rem;
}

/*--------tab----------*/
.veritical-line {
  padding: 1.25rem 1.875rem;
  border-top: 0.0625rem solid #e6e6e6;
  border-right: 0.0625rem solid #e6e6e6;
  border-bottom: 0.0625rem solid #e6e6e6;
  position: relative;
}

.veritical-line:before {
  background: #e6e6e6;
  bottom: 0;
  content: "";
  height: 100%;
  left: -0.0625rem;
  max-height: 40%;
  position: absolute;
  width: 0.0625rem;
}

.tab-content-text p {
  color: #312a2a;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5rem;
  margin-bottom: 1.5625rem;
}

.tab-item-list li a {
  background: #fff;
  border-top: 0.0625rem solid #e6e6e6;
  border-left: 0.0625rem solid #e6e6e6;
  border-right: 0.0625rem solid #e6e6e6;
  color: #312a2a;
  display: block;
  font-size: 1rem;
  padding: 1rem;
  text-transform: uppercase;
}

.tab-item-list li a:hover, .tab-item-list li a:focus {
  background: #fff;
  color: #312a2a;
  border-right: 0rem;
}

.tab-item-list li:last-child {
  border-bottom: 0.0625rem solid #e6e6e6;
}

.tab-list li {
  margin-bottom: 0.4375rem;
  font-size: 0.8125rem;
}

.tab-list li i {
  font-size: 0.8125rem;
  margin-right: 0.875rem;
}

.slide-item-list {
  text-align: center;
  margin: 0 -0.3125rem;
  border: 0;
}

.slide-item-list li {
  display: inline-block;
  flex: 0 0 25%;
  width: 25%;
  padding: 0 0.3125rem;
}

.slide-item-list li a {
  display: inline-block;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  background: transparent;
  padding-bottom: 0rem;
}

.slide-item-list li a:hover, .slide-item-list li a:focus {
  background: transparent;
}

.slide-item-list li a img {
  width: 100%;
}

.slide-item-list .nav-link {
  border: 0;
}

.slide-item-list .nav-link.active {
  background-color: transparent;
}

.product-detail-text {
  padding-top: 1.75rem;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  padding-bottom: 4.375rem;
}

.star-rating .product-review {
  font-style: 0.8125rem;
  color: #312a2a;
  font-weight: 400;
  text-decoration: underline !important;
}

.product-detail .tab-content img {
  display: inline-block;
  width: 100%;
}

.popular-tag ul {
  margin: 0rem;
  padding: 0rem;
}

.popular-tag ul li {
  display: inline-block;
  padding: 0.5rem 0.9375rem;
  background: #f8f8f8;
  font-size: 0.8125rem;
  color: #fff;
  margin-right: 0.625rem;
  margin-bottom: 0.625rem;
}

.size-filter ul li {
  display: inline-block;
}

.intro {
  border: 0.0625rem solid red;
  color: #1d1d1d;
}

#listResults .slider {
  margin: 1.5625rem 0;
}

#listResults .slider-box {
  width: 90%;
  margin: 1.5625rem auto;
}

#listResults input {
  width: 10%;
}

#listResults label {
  border: none;
  display: inline-block;
  margin-right: -0.25rem;
  vertical-align: top;
  width: 30%;
}

.plus-minus-input .input-icon {
  font-size: 0.8125rem;
  color: #aaaaaa;
}

.plus-minus-input .custom-btn {
  border-radius: 0;
  height: 2.5rem;
  padding: 0.5rem 0.75rem;
  background: #ffffff;
  border: 0.0625rem solid #c8c8c8;
}

.plus-minus-input .custom-btn:hover, .plus-minus-input .custom-btn:focus, .plus-minus-input .custom-btn.active {
  box-shadow: none;
  outline: none;
}

.plus-minus-input .form-control {
  height: 2.5rem;
  border-top: 0.0625rem solid #c8c8c8;
  border-bottom: 0.0625rem solid #c8c8c8;
  border-left: 0rem solid #c8c8c8;
  border-right: 0.0625rem solid #c8c8c8;
}

.plus-minus-input .form-control:hover, .plus-minus-input .form-control:focus, .plus-minus-input .form-control:active {
  border-top: 0.0625rem solid #c8c8c8;
  border-bottom: 0.0625rem solid #c8c8c8;
  border-left: 0rem solid #c8c8c8;
  border-right: 0rem solid #c8c8c8;
}

.new-arrival-product .new-arrivals-img-contnent {
  overflow: hidden;
}

.new-arrival-product .new-arrivals-img-contnent img {
  width: 100%;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.new-arrival-product:hover .new-arrivals-img-contnent img {
  transform: scale(1.5) translateY(12%);
  -moz-transform: scale(1.5) translateY(12%);
  -webkit-transform: scale(1.5) translateY(12%);
  -ms-transform: scale(1.5) translateY(12%);
  -o-transform: scale(1.5) translateY(12%);
}

/*
===================================
    list view
===================================*/
.new-arrival-content .item {
  font-size: 0.75rem;
  color: #312a2a;
}

.new-arrival-content h4, .new-arrival-content .h4 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.625rem;
}

.new-arrival-content h4 a, .new-arrival-content .h4 a {
  color: #000;
}

.new-arrival-content .price {
  font-weight: 600;
  color: var(--primary);
  font-size: 1.5rem;
  margin-bottom: 0;
  float: right;
}

@media only screen and (max-width: 35.9375rem) {
  .new-arrival-content .price {
    float: none;
    margin-top: 0.625rem;
  }
}

.new-arrival-content p {
  font-size: 0.875rem;
  color: #828690;
  margin-bottom: 0.375rem;
  line-height: 1.5rem;
}

.new-arrival-content .text-content {
  margin-top: 1.125rem;
}

.new-arrival-content.text-center .price {
  float: unset !important;
}

.success-icon {
  color: #09BD3C;
  font-size: 1rem;
}

.comment-review {
  margin-bottom: 0.9375rem;
  display: table;
  width: 100%;
}

.comment-review .client-review {
  color: #828690;
  padding-right: 1.25rem;
  text-decoration: underline !important;
  font-size: 0.875rem;
}

.comment-review .span {
  color: #828690;
  font-size: 0.875rem;
}

@media only screen and (max-width: 35.9375rem) {
  .comment-review {
    margin-bottom: 0;
  }
}

.star-rating li {
  display: inline-block;
}

.star-rating li i {
  color: gold;
}

.new-arrival-content .discount {
  font-size: 0.875rem;
  margin-right: 8px;
  opacity: 0.8;
  color: #FC2E53;
}

.cm-content-box {
  background: #fff;
  border-radius: 0.6rem;
  margin-bottom: 1rem;
}

.cm-content-box.filter {
  border-top: 0;
}

.cm-content-box .content-title {
  padding: 0.5rem 1.875rem;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cm-content-box .content-title .cpa {
  color: #ce2b7c;
  padding: 11px 0 9px 0;
  font-weight: 600;
  font-size: 1rem;
}

@media only screen and (max-width: 35.9375rem) {
  .cm-content-box .content-title {
    padding: 0.5rem 0.938rem;
  }
}

.cm-content-box .content-title {
  border-top-left-radius: 0.425rem;
  border-top-right-radius: 0.425rem;
}

.cm-content-box.style-1 .content-title {
  border-radius: 0.625rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 1px solid #e6e6e6;
}

.cm-content-box .tools a {
  display: inline-block;
}

.cm-content-box .tools a:hover i {
  opacity: 0.5;
}

.cm-content-box .tools a i {
  font-family: 'Font Awesome 6 Free';
  font-weight: 600;
  color: #c2c2c2;
  font-size: 1.25rem;
}

.cm-content-box .tools a.expand {
  transform: rotate(-180deg);
}

.cm-content-box .cm-content-body {
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  border-top: 1px solid #e6e6e6;
}

.cm-content-box .cm-content-body .table .btn {
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0.313rem;
}

.cm-content-box .cm-content-body.excerpt .textarea {
  padding: 0.438rem 0.25rem;
}

.cm-content-box .cm-content-body.custom-fields .name-value {
  line-height: 2.375rem;
  font-size: 1rem;
  text-align: center;
}

.cm-content-box .cm-content-body .contact {
  border-radius: 0.625rem !important;
}

.contents-list .btn-icon-only {
  height: 2.25rem;
  width: 2.25rem;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  line-height: 1;
}

.contents-list .btn-icon-only i {
  font-size: 1rem;
}

.contents-list .table tbody tr td {
  padding: 0.4375rem 0.625rem;
}

.fatch-date {
  display: block;
}

.new-scroll {
  background-color: var(--body-bg);
  padding: 0.625rem;
  height: 9.375rem;
  overflow-y: auto;
  border-radius: 0.625rem;
}

.content-icon {
  width: 2.188rem;
  height: 2.188rem;
  display: inline-block;
  text-align: center;
  line-height: 2.125rem;
  font-size: 0.625rem;
  padding: 0px;
}

.dd-handle.move-media {
  padding: 0.5rem 1.125rem;
  display: flex;
  margin: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 100%;
  border: 0;
  z-index: 2;
  background: none;
  position: absolute;
  background-color: #886CC0;
  top: 0;
  font-size: 1.25rem;
  left: 0;
  color: #fff;
  align-items: center;
}

.dd-handle.move-media+.accordion-button {
  padding-left: 4.063rem;
  background: #fff;
  position: relative;
  z-index: 1;
}

.dd-list {
  margin-top: 20px;
}

.menu-ac-item {
  position: relative;
}

.menu-ac-item .dd-collapse {
  height: 3.338rem;
  width: 3.338rem;
  border: 1px solid #e6e6e6;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  font-size: 1.75rem;
  color: black;
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
}

.menu-ac-item .dd-collapse+.accordion {
  margin-left: 3.25rem;
}

@media only screen and (max-width: 1600px) {
  .menu-ac-item .dd-collapse {
    height: 3rem;
    width: 3rem;
  }
}

.menu-ac-item .dd-expand {
  display: none;
}

.menu-ac-item a:hover {
  color: #FC2E53;
}

.warning:hover {
  color: #FFBF00;
}

.danger:hover {
  color: #FC2E53;
}

.info:hover {
  color: #D653C1;
}

.secondary:hover {
  color: var(--secondary);
}

.dz-menu-btn {
  margin-top: 0.625rem;
}

@media only screen and (max-width: 1600px) {
  .dz-menu-btn {
    margin-top: 0;
    margin-left: 0.313rem;
  }
}

@media only screen and (max-width: 1480px) {
  .dz-menu-btn {
    margin-top: 0.625rem;
    margin-left: 0;
  }
}

.dz-terms {
  border: 1px solid #e6e6e6;
  border-radius: 0.625rem;
  padding: 0.625rem;
}

/* menu-scss */
.tab-my.nav-tabs {
  border-bottom: unset;
}

.tab-my.nav-tabs .nav-link {
  background-color: #FFA7D7;
  border-radius: 0.625rem;
  border: 1px solid transparent;
  margin: 8px 2px;
  color: #fff;
}

.tab-my.nav-tabs .nav-link.active {
  background-color: #886CC0;
  color: #fff;
}

.menu-tabs {
  border: 1px solid #e6e6e6;
  margin-bottom: 1rem;
  padding: 0.625rem;
  border-radius: 0.625rem;
}

.avatar-upload .avatar-edit {
  position: absolute;
  right: 0.75rem;
  z-index: 1;
  top: 0.625rem;
}

.avatar-upload .avatar-preview {
  border: 1px solid #e6e6e6;
  padding: 0.5rem;
  margin-bottom: 0.625rem;
  border-radius: 0.625rem;
}

.avatar-upload .avatar-preview>div {
  display: block;
  width: 100%;
  height: 7.5rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.main-check {
  border: 1px solid #886CC0;
  padding: 2.125rem;
  margin-bottom: 1rem;
  border-radius: 0.625rem;
}

/* view-content */
.view-bottom {
  flex-direction: column;
  justify-content: end;
  height: 88%;
  display: flex;
}

/* add-email */
.publish-content ul {
  padding: 0.625rem;
}

.dd .accordion-body {
  border: 1px solid #e6e6e6;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-top: 0;
  margin-top: -5px;
}

@media only screen and (max-width: 35.9375rem) {
  .dd .dd-list {
    padding-left: 0;
  }
}

.dd .accordion-button:not(.collapsed) {
  box-shadow: none;
}

input.form-control {
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.right-sidebar-sticky {
  position: sticky;
  top: 7.5rem;
}

.content-title.box-primary, .content-title.box-secondary, .content-title.box-success, .content-title.box-danger, .content-title.box-warning, .content-title.box-info, .content-title.box-dark, .content-title.box-light {
  background-color: #886CC0;
}

.content-title.box-primary .cpa,
.content-title.box-primary i, .content-title.box-secondary .cpa,
.content-title.box-secondary i, .content-title.box-success .cpa,
.content-title.box-success i, .content-title.box-danger .cpa,
.content-title.box-danger i, .content-title.box-warning .cpa,
.content-title.box-warning i, .content-title.box-info .cpa,
.content-title.box-info i, .content-title.box-dark .cpa,
.content-title.box-dark i, .content-title.box-light .cpa,
.content-title.box-light i {
  color: #fff !important;
}

.content-title.box-secondary {
  background-color: #FFA7D7;
}

.content-title.box-success {
  background-color: #09BD3C;
}

.content-title.box-danger {
  background-color: #FC2E53;
}

.content-title.box-warning {
  background-color: #FFBF00;
}

.content-title.box-info {
  background-color: #D653C1;
}

.content-title.box-dark {
  background-color: #312a2a;
}

.content-title.box-light {
  background-color: #c8c8c8;
}

/* data-picker */
.datepicker {
  padding: 10px;
}

.datepicker.datepicker-dropdown {
  background-color: #fff;
  border-radius: 0.625rem;
}

.datepicker.datepicker-dropdown th {
  font-size: 16px;
  font-weight: 500 !important;
}

.datepicker table tr td.today {
  color: #fff;
  background-image: none;
  background-color: #886CC0;
}

.datepicker table tr td.today:hover:hover {
  color: #fff;
  background-color: #886CC0;
  background-image: none;
}

.datepicker table tr td.selected, .datepicker table tr td.active.active,
.datepicker.datepicker-dropdown td.day:hover, .datepicker.datepicker-dropdown th.next:hover, .datepicker.datepicker-dropdown th.prev:hover {
  box-shadow: unset;
  background-image: none !important;
  background-color: #886CC0 !important;
  border-radius: 0.625rem;
  color: #fff;
}

.datepicker.datepicker-dropdown td.day, .datepicker.datepicker-dropdown th.next, .datepicker.datepicker-dropdown th.prev {
  font-size: 15px;
  font-weight: 500;
}

.overflow-unset {
  overflow: unset !important;
}

.datepicker table tr td.today {
  background-image: none !important;
  box-shadow: unset;
  background-color: #886CC0 !important;
  border-radius: 0.625rem !important;
}

.right-sidebar-sticky .accordion-body-text {
  padding: 13px;
}

.checkAllInput:hover,
.unCheckAllInput:hover {
  color: #FC2E53;
}

.chat-left-area .chat-sidebar {
  height: calc(87vh - 82px);
  border-top: none;
  overflow-y: scroll;
}

@media only screen and (max-width: 74.9375rem) {
  .chat-left-area .chat-sidebar {
    height: calc(100vh - 5.9rem);
  }
}

@media only screen and (max-width: 47.9375rem) {
  .chat-left-area .chat-sidebar {
    box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.05);
  }
}

.message-body {
  min-height: auto !important;
}

.message-box {
  background: white;
  padding-top: 1rem;
  padding-bottom: 1rem;
}


.message-box .input-group.search-area {
  width: 75%;
}

.message-box .input-group.search-area .input-group-text {
  padding: 0.32rem 0.75rem;
}

.message-box img {
  height: 5rem;
  width: 5rem;
  border-radius: 0.625rem;
}

.message-box span {
  font-size: 1.125rem;
}

.message-box .chat-hamburger {
  display: flex;
  align-items: center;
}

.message-box .chat-hamburger .videos-btn {
  background-color: #FFA7D7;
  margin-right: 0.625rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.message-box.style-1 {
  padding: 1rem;
  border: 0;
  height: 5rem;
  border-radius: 0.625rem;
}

@media only screen and (max-width: 35.9375rem) {
  .message-box.style-1 {
    height: auto;
  }
}

.message-box.style-1 h4, .message-box.style-1 .h4 {
  margin-bottom: .25rem;
}

@media only screen and (max-width: 35.9375rem) {
  .message-box.style-1 h4, .message-box.style-1 .h4 {
    margin-bottom: 0;
    font-size: 0.938rem;
  }
}

.message-box.style-1 span {
  font-size: 14px;
}

.message-box.style-1 img {
  height: 3rem;
  width: 3rem;
}

@media only screen and (max-width: 35.9375rem) {
  .message-box.style-1 img {
    height: 3rem;
    width: 3rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .message-box img {
    height: 3rem;
    width: 3rem;
  }

  .message-box h3, .message-box .h3 {
    font-size: 1rem;
  }

  .message-box span {
    font-size: .85rem;
  }
}

.message-box .add {
  width: 3rem;
  height: 3rem;
  border-radius: 0.625rem;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 87.5rem) {
  .message-box .add {
    width: 2.5rem;
    height: 2.5rem;
  }
}

.chat-people {
  padding: 1.25rem 0;
}

.chat-tabs>h4, .chat-tabs>.h4 {
  font-size: 1.125rem;
  padding-top: 1.125rem;
  margin: 0;
}

.chat-tabs ul {
  padding: 1rem 0;
}

.chat-tabs .chat-bx.chats {
  position: relative;
}

.chat-tabs .chat-bx.chats .chat-img {
  position: relative;
}

.chat-tabs .chat-bx.chats .chat-img::before {
  content: '';
  display: block;
  background-color: #1EBA62;
  border: 2px solid #fff;
  position: absolute;
  bottom: -2px;
  right: -2px;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 50%;
}

.chat-tabs .chat-bx {
  padding: 0.625rem 0;
  position: relative;
  z-index: 1;
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-bottom: 0.5rem;
}

.chat-tabs .chat-bx .right-info {
  width: 4.375rem;
  text-align: right;
}

.chat-tabs .chat-bx .right-info p {
  font-size: .8rem;
  margin-top: 0;
  margin-bottom: .5rem;
}

@media only screen and (max-width: 87.5rem) {
  .chat-tabs .chat-bx .right-info {
    width: 4rem;
  }
}

.chat-tabs .chat-bx .mid-info {
  width: calc(100% - 9rem);
}

.chat-tabs .chat-bx .mid-info .name {
  font-size: 1rem;
  margin-bottom: .25rem;
}

.chat-tabs .chat-bx .mid-info span {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  font-size: 0.875rem;
}

@media only screen and (max-width: 87.5rem) {
  .chat-tabs .chat-bx .mid-info {
    width: calc(100% - 8rem);
  }
}

.chat-tabs .chat-bx .chat-img {
  position: relative;
  margin-right: 1rem;
}

.chat-tabs .chat-bx .chat-img img {
  height: 3rem;
  width: 3rem;
  border-radius: 0.625rem;
  margin-right: 0rem;
  object-fit: cover;
}

@media only screen and (max-width: 87.5rem) {
  .chat-tabs .chat-bx .chat-img img {
    height: 3rem;
    width: 3rem;
  }
}

.chat-tabs .chat-bx .chat-img .active {
  height: 1rem;
  width: 1rem;
  border: 0.125rem solid #fff;
  border-radius: 0.25rem;
  background: #FFA7D7;
  position: absolute;
  bottom: -0.0625rem;
  right: 1rem;
}

.chat-tabs .chat-bx .badge {
  border-radius: 0.375rem;
}

.chat-tabs .chat-bx:last-child {
  border-bottom: 0;
}

@media only screen and (max-width: 87.5rem) {
  .chat-tabs .chat-bx {
    padding: .75rem 0;
  }
}

.chat-tabs .chat-bx:after {
  content: none;
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% + 4px);
  width: calc(100% + 30px);
  z-index: -1;
  background-color: #f0f0f0;
  border-radius: 0.625rem;
  margin: -2px -15px;
}



.chat-tabs .chat-bx:hover::before, .chat-tabs .chat-bx.active::before {
  content: '';
  display: none;
}

.chat-tabs .chat-bx:hover:after, .chat-tabs .chat-bx.active:after {
  content: "";
}

.chat-tabs .chat-bx:hover .mid-info h4, .chat-tabs .chat-bx:hover .mid-info .h4, .chat-tabs .chat-bx.active .mid-info h4, .chat-tabs .chat-bx.active .mid-info .h4 {
  color: var(--primary);
}

.chat-box-area {
  height: calc(87vh - 12.925rem);
}

.chat-box-area .media .message-received {
  width: 62%;
  text-align: left;
}

.chat-box-area .media .message-received p {
  background: var(--body-bg);
  text-align: left;
  border-radius: 0.625rem;
  border-top-left-radius: 0;
  padding: 0.625rem 0.625rem;
  display: table;
  color: var(--text-dark);
}

.chat-box-area .media .message-received span {
  color: var(--primary);
}

.chat-box-area .media .message-received .dz-media {
  width: 22.75rem;
  height: 15.375rem;
  padding: 0.625rem;
  background-color: #fff;
}

@media only screen and (max-width: 47.9375rem) {
  .chat-box-area .media .message-received .dz-media {
    width: 14.75rem;
    height: 12.75rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .chat-box-area .media .message-received {
    width: 100%;
  }
}

.chat-box-area .message-sent {
  width: 62%;
  text-align: right;
  max-width: 83%;
}

@media only screen and (max-width: 61.9375rem) {
  .chat-box-area .message-sent {
    width: 84%;
  }
}

.chat-box-area .message-sent span {
  display: block;
}

.chat-box-area .message-sent p {
  background: var(--rgba-primary-1);
  text-align: left;
  border-radius: 0.625rem;
  border-top-right-radius: 0;
  padding: 0.625rem 1.25rem;
  color: var(--primary);
  display: inline-block;
}

.chat-box-area.style-1 {
  height: 21.5rem;
}

.chat-box-area.style-1 .message-received p {
  background: #eeee;
  font-size: 0.875rem;
}

.chat-box-area.style-1 .message-sent p {
  background: var(--primary);
  font-size: 0.875rem;
  color: #fff;
}

@media only screen and (max-width: 74.9375rem) {
  .chat-box-area {
    height: calc(100vh - 16.8rem);
  }
}

@media only screen and (max-width: 35.9375rem) {
  .chat-box-area {
    height: calc(100vh - 12.8rem);
  }
}

.type-massage {
  background: #fff;
  padding: 0.625rem;
  border-radius: 0.625rem;
  border: 1px solid #e6e6e6;
}



.type-massage .input-group textarea {
  resize: none;
  background: var(--body-bg);
}

@media only screen and (max-width: 35.9375rem) {
  .type-massage .input-group textarea {
    height: 3.5rem !important;
    line-height: 1.5;
    padding: 0.4375rem 0.5rem;
  }
}

.type-massage .input-group {
  align-items: center;
}

.type-massage.style-1 {
  border: 0;
  margin: 0;
  padding: 0.375rem;
  background: rgba(219, 219, 219, 0.25);
}

.type-massage.style-1 textarea {
  border: 0;
  height: 2.5rem !important;
  resize: none;
  padding: 0.0625rem 0.9375rem;
  background: transparent;
}

.type-massage .input-group-append {
  display: flex;
  align-items: center;
}

.type-massage .input-group-append .btn {
  text-align: center;
  margin: 0 0.5rem;
}

@media only screen and (max-width: 35.9375rem) {
  .type-massage .input-group-append .btn {
    height: 2.5rem;
    padding: 0.5rem 0.425rem;
  }

  .type-massage .input-group-append .btn svg {
    width: 12px;
    height: 12px;
  }
}

.type-massage .share-btn {
  height: 2.5rem;
  width: 2.5rem;
  padding: 0;
  background: var(--rgba-primary-1);
  margin-right: 1.0625rem;
  border-radius: 0.625rem;
}

.type-massage .share-btn svg path {
  fill: var(--primary);
}

.chat-icon {
  border-bottom: 0.0625rem solid #F0F0F0;
  padding-bottom: 1.5625rem;
}

.chat-icon ul {
  display: flex;
  justify-content: space-around;
}

.chat-icon ul li a {
  height: 3rem;
  width: 3rem;
  background: #F0F0F0;
  line-height: 3rem;
  display: block;
  border-radius: 0.625rem;
  text-align: center;
}

.chat-icon ul li a:hover {
  background: var(--primary);
}

.chat-icon ul li a:hover svg path {
  fill: #fff;
}

.share-files {
  border-bottom: 0.0625rem solid #F0F0F0;
  padding-bottom: 1.125rem;
}

.share-files:last-child {
  border-bottom: 0;
}

.share-files ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5625rem 0;
  cursor: pointer;
}

.share-files ul li:first-child {
  padding-top: 0;
}

.share-files ul li h4, .share-files ul li .h4 {
  white-space: nowrap;
}

.share-files ul li>div {
  flex: 1;
}

.file {
  height: 2.5rem;
  width: 2.5rem;
  min-width: 2.5rem;
  line-height: 2.4rem;
  text-align: center;
  background: #F0F0F0;
  display: block;
  border-radius: 0.625rem;
}

.file svg {
  height: 1.5rem;
  width: 1.5rem;
}

.share-widget {
  height: calc(100vh - 11.25rem);
  overflow: hidden;
}

@media only screen and (max-width: 87.5rem) {
  .share-widget {
    box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.05);
  }
}

@media only screen and (max-width: 74.9375rem) {
  .share-widget {
    height: calc(100vh - 9.25rem);
  }
}

.file-list li>div {
  width: calc(100% - 3.5rem);
}

.file-list li>div>div {
  width: calc(100% - 3rem);
}

.file-list li h4, .file-list li .h4 {
  width: 3.5rem;
  text-align: right;
}

.file-list li:hover .file {
  background: var(--primary);
}

.file-list li:hover .file svg path {
  fill: #fff;
}

.file-list .fl-info {
  flex: 1;
}

.file-list .fl-info span {
  font-size: 0.8rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

.contact-list li {
  width: 17%;
  padding: 0 0.2rem;
}

.contact-list li img {
  width: 100%;
  border-radius: 0.625rem;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

@media only screen and (max-width: 87.5rem) {
  .contact-list li {
    padding: 0 .15rem;
  }
}

.contact-list li:hover img {
  filter: blur(1px);
}

@media only screen and (max-width: 74.9375rem) {
  .chat-left-area {
    width: 22rem;
    position: absolute;
    left: -22rem;
    z-index: 2;
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
  }

  .chat-left-area.active {
    left: 16.5rem;
  }
}

@media only screen and (max-width: 1022px) {
  .chat-left-area.active {
    left: 5.5rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .chat-left-area.active {
    left: 0rem;
  }
}

.chat-right-area .chat-rightarea-btn {
  display: none;
}

@media only screen and (max-width: 87.5rem) {
  .chat-right-area {
    width: 23rem;
    position: absolute;
    right: -16.5rem;
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
  }

  .chat-right-area.active {
    right: 4.2rem;
  }

  .chat-right-area .chat-rightarea-btn {
    position: absolute;
    top: 7rem;
    left: -1.1rem;
    width: 2.2rem;
    height: 3rem;
    background: var(--primary);
    display: inline-flex;
    color: #fff;
    font-size: 1.2rem;
    align-items: center;
    justify-content: center;
    border-radius: .5rem 0 0 .5rem;
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
  }

  .chat-right-area .chat-rightarea-btn.active i {
    transform: rotateY(180deg);
  }
}

@media only screen and (max-width: 47.9375rem) {
  .chat-right-area {
    right: -22rem;
  }

  .chat-right-area.active {
    right: -1.5rem;
  }
}

@media only screen and (max-width: 87.5rem) {
  .chat-mid-area {
    flex: 1;
    overflow: hidden;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .chat-mid-area .chat-hamburger {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    line-height: 45px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    position: relative;
    margin-top: 0.625rem;
  }

  .chat-mid-area .chat-hamburger span {
    display: block;
    height: 2px;
    margin: 2px auto;
    width: 20px;
    border-radius: 3px;
    background-color: #000;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .chat-mid-area {
    margin-top: 0.625rem;
  }
}

.chart-content {
  padding: 1rem;
}

@media only screen and (max-width: 61.9375rem) {
  .chart-content {
    padding-bottom: 0;
  }
}

.chart-content .media .dz-media {
  width: 3rem;
  height: 3rem;
  border-radius: 0.625rem;
  overflow: hidden;
  margin-right: 0.625rem;
}

.chart-content .media .dz-media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.chat-toggle {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
  line-height: 2.2rem;
  text-align: center;
  color: #fff;
  border-radius: 0.625rem;
  position: relative;
  display: none;
  margin-right: 0.625rem;
  background-color: var(--rgba-primary-1);
}

@media only screen and (max-width: 74.9375rem) {
  .chat-toggle {
    display: flex;
  }
}

.chat-toggle svg {
  width: 25px;
  height: 25px;
}

.chat-toggle svg g path {
  fill: var(--primary);
}

.chat-toggle::before {
  content: '';
  display: block;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 0vw;
  height: 0vh;
}

.chat-toggle span {
  display: block;
  height: 2px;
  margin: 2px auto;
  width: 20px;
  border-radius: 3px;
  background-color: #000;
}

.chat-toggle.active::before {
  z-index: 1;
  width: 100vw;
  height: 100vh;
}

.chat-sidebar::-webkit-scrollbar {
  width: 5px;
}

.chat-sidebar::-webkit-scrollbar-track {
  border-radius: 10px;
}

.chat-sidebar::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

.chat-box-area::-webkit-scrollbar {
  width: 5px;
}

.chat-box-area::-webkit-scrollbar-track {
  border-radius: 10px;
}

.chat-box-area::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

.form-switch .form-check-input {
  width: 3rem;
  height: 1.5rem;
}




.dlabnav .metismenu>li:hover {
  font-weight: 600;
  background: var(--rgba-primary-1);
  border-bottom-right-radius: 2.5rem;
  border-top-right-radius: 0.5rem;

}


.dlabnav .metismenu>li.active {
  font-weight: 600;
  background: var(--rgba-primary-1);
  border-bottom-right-radius: 2.5rem;
  border-top-right-radius: 0.5rem;
}

.dlabnav .metismenu>li.active::before {
  content: "";
  position: absolute;
  background: #ce2b7c;
  width: 0.25rem;
  height: 100%;
  top: 0;
  left: 0;
  border-top-right-radius: 3.563rem;
  border-bottom-right-radius: 3.563rem;
  transition: all 0.5s;
}


/* .profile-menu .dropdown-menu{
  display: block !important;
} */



.sw {
  position: relative
}

.sw *, .sw ::after, .sw ::before {
  box-sizing: border-box
}

.sw>.tab-content {
  position: relative;
  overflow: hidden
}

.sw .toolbar {
  padding: .8rem
}

.sw .toolbar>.btn {
  display: inline-block;
  text-decoration: none;
  text-align: center;
  text-transform: none;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: .2rem;
  margin-right: .2rem;
  cursor: pointer
}

.sw .toolbar>.btn.disabled, .sw .toolbar>.btn:disabled {
  opacity: .65
}

.sw>.nav {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0
}

@media screen and (max-width:640px) {
  .sw>.nav {
    flex-direction: column !important;
    flex: 1 auto
  }
}

.sw>.nav .nav-link {
  display: block;
  padding: .5rem 1rem;
  text-decoration: none
}

.sw>.nav .nav-link:active, .sw>.nav .nav-link:focus, .sw>.nav .nav-link:hover {
  text-decoration: none
}

.sw>.nav .nav-link::-moz-focus-inner {
  border: 0 !important
}

.sw>.nav .nav-link.disabled {
  color: #ccc !important;
  pointer-events: none;
  cursor: default
}

.sw>.nav .nav-link.hidden {
  display: none !important
}

.sw.sw-justified>.nav .nav-link, .sw.sw-justified>.nav>li {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center
}

.sw.sw-dark {
  color: rgba(255, 255, 255, .95);
  background: #000
}

.sw.sw-loading {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.sw.sw-loading::after {
  content: "";
  display: block;
  position: absolute;
  opacity: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, .7);
  z-index: 2;
  transition: all .2s ease
}

.sw.sw-loading::before {
  content: '';
  display: inline-block;
  position: absolute;
  top: 45%;
  left: 45%;
  width: 2rem;
  height: 2rem;
  border: 10px solid #f3f3f3;
  border-top: 10px solid #3498db;
  border-radius: 50%;
  z-index: 10;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(0)
  }

  100% {
    transform: rotate(360deg)
  }
}

@keyframes spin {
  0% {
    transform: rotate(0)
  }

  100% {
    transform: rotate(360deg)
  }
}



.sw-theme-default>.tab-content>.tab-pane {
  padding: 10px
}

.sw-theme-default .toolbar>.btn {
  color: #fff;
  /* background-color: #17a2b8; */
  border: none !important;
  padding: .375rem .75rem;
  border-radius: .25rem;
  font-weight: 400
}

.sw-theme-default>.nav {
  /* box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .1) !important */
}

.sw-theme-default>.nav .nav-link {
  position: relative;
  height: 100%;
  min-height: 100%
}

.sw-theme-default>.nav .nav-link::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 0;
  left: 0;
  bottom: -1px;
  background: #999;
  transition: all .35s ease .15s
}

.sw-theme-default>.nav .nav-link.inactive {
  color: #999;
  cursor: not-allowed
}

.sw-theme-default>.nav .nav-link.active {
  /* color: #17a2b8 !important; */
  cursor: pointer
}

.sw-theme-default>.nav .nav-link.active::after {
  /* background: #17a2b8 !important; */
  width: 100%
}

.sw-theme-default>.nav .nav-link.done {
  color: #5cb85c !important;
  cursor: pointer
}

.sw-theme-default>.nav .nav-link.done::after {
  background: #5cb85c;
  width: 100%
}

.sw-theme-default>.nav .nav-link.disabled {
  color: #ddd !important;
  cursor: not-allowed
}

.sw-theme-default>.nav .nav-link.disabled::after {
  background: #ddd;
  width: 100%
}

.sw-theme-default>.nav .nav-link.danger {
  color: #d9534f !important;
  cursor: pointer
}

.sw-theme-default>.nav .nav-link.danger::after {
  background: #d9534f;
  width: 100%
}