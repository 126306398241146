.tablez {
  background-color: #333;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  color: #000;
  margin: 10px;
}

.table-icon img {
  width: 50px;
  height: 50px;
}

.table-name {
  margin-top: 10px;
  font-size: 1.2em;
}

.join-button {
  margin-top: 10px;
  padding: 8px 16px;
  border: none;
  background-color: #007bff;
  color: #000;
  border-radius: 4px;
  cursor: pointer;
}

.join-button:hover {
  background-color: #0056b3;
}

.dashboardz {
  text-align: center;
  color: #000;
  padding: 20px;
  background-color: #1a1a1a;
}

.tables-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #000;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ce2b7c !important;
  border-radius: 10%;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  border: none;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
}

.close-button:hover {
  cursor: pointer;
}

.webrtc-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.controls {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.controls button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #000;
  cursor: pointer;
}

.controls button:hover {
  background-color: #0056b3;
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #000;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.meeting-room {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  margin: 20px;
  text-align: center;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.room-header {
  margin-bottom: 20px;
}

.room-content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.desk {
  width: 200px;
  height: 100px;
  background-color: #ddd;
  border: 2px solid #bbb;
  border-radius: 8px;
  position: relative;
  z-index: 1;
}

.chairs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 300px;
  position: absolute;
}

.chair {
  width: 60px;
  height: 60px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #007bff;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.participant-name {
  font-size: 0.8em;
}

.room-actions {
  margin-top: 20px;
}

.room-actions button {
  margin: 5px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #000;
  cursor: pointer;
}

.room-actions button:hover {
  background-color: #0056b3;
}

.dashboard {
  text-align: center;
  padding: 20px;
  background-color: #f4f4f4;
}

.tables-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.table-card {
  margin: 10px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #000;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}


